import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { useReactToPrint } from 'react-to-print';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import LoadingBar from 'react-top-loading-bar'
const cookies = new Cookies();
const InvoicePdf = () => {
    const axiosPrivate = useAxiosPrivate();
    let { invoice_id } = useParams();
    const [data, setData] = useState();
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        axiosPrivate.post(`generate_invoice/`, { invoice_id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setData(res.data)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [invoice_id, axiosPrivate])
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage && <>
                <div className="cont mt-5" id="printSection" ref={componentRef}>
                    <div className="top row d-flex">
                        <div className="top-left col-lg-7 col-md-7 col-12">
                            <h3>Bill to</h3>
                            <div className="split mt-4">
                                <div className="split-right">UB GENESIS PTE LTD</div>

                            </div>
                            {/* <div className="split mt-1">
                                <div className="split-left">Email : {data?.buyer_email}</div>

                            </div> */}
                        </div>

                        <div className="top-right col-lg-5 col-md-5 col-12 mt-lg-0 mt-md-0 mt-4">
                            <h2>Invoice  {data?.invoice_number} </h2>

                            <div className="split">
                                <div className="split-left">Invoice date :</div>
                                <div className="split-right"> {data?.invoice_date} </div>

                            </div>

                            <div className="split">
                                <div className="split-left">Name :</div>
                                <div className="split-right"> {data?.buyer_name} </div>
                            </div>

                            {/* <div className="split">
                                <div className="split-left">Email :</div>
                                <div className="split-right"> {data?.buyer_email} </div>

                            </div>

                            <div className="split">
                                <div className="split-left">Phone # :</div>
                                <div className="split-right"> {data?.buyer_phone_no} </div>

                            </div> */}
                        </div>
                    </div>

                    <div className="items table-responsive">
                        <table className="items-row table">
                            <thead>
                                <tr>
                                    <th className="items-row-cell header title">Order No</th>

                                    <th className="items-row-cell header quantity">Tracking No</th>

                                    <th className="items-row-cell header unit-price">Item Description</th>

                                    <th className="items-row-cell header vat-rate">Quantity</th>

                                    <th className="items-row-cell header sum">Item Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <div className="items-row">
                                        <td className="items-row-cell title">1</td>

                                        <td className="items-row-cell quantity"> {data?.seller_tracking_no} </td>

                                        <td className="items-row-cell title"> {data?.product_description} </td>

                                        <td className="items-row-cell quantity"> {data?.quantity} </td>

                                        <td className="items-row-cell unit-price"> {data?.product_purchased_price?.toFixed(2)} </td>

                                    </div>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="summary row">
                        <div className="summary-title">
                            <h2>Summary</h2>
                        </div>
                        <div className="row">
                            <div className="summary-left col-lg-8 col-md-7 col-12">
                                <p><strong>Invoice number: </strong> {data?.invoice_number} </p>
                                <p><strong>Date: </strong> {data?.invoice_date} </p>
                            </div>

                            <div className="summary-right col-lg-4 col-md-5 col-12 me-auto">

                                <div className="split mb-1">
                                    <div className="split-left"><strong>TOTAL&nbsp;AMOUNT</strong></div>
                                    <div className="split-right"><strong>SGD {data?.total?.toFixed(2)} </strong></div>
                                </div>
                                <div className="split mb-1">
                                    <div className="split-left">ADMIN&nbsp;CHARGES</div>
                                    <div className="split-right">SGD {data?.admin_charge?.toFixed(2)} </div>
                                </div>
                                <div className="split mb-1">
                                    <div className="split-left">SUBTOTAL</div>
                                    <div className="split-right">SGD {data?.sub_total?.toFixed(2)} </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="btn btn-primary m-5 float-end" onClick={handlePrint}>print</button>
                </div>
            </>}
        </>
    )
}
export default InvoicePdf;