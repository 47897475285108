const HowItWorksSellerData = {
    "steps": [
        "Setup your store in less than 3 minutes.",
        "Share your shop with the community and connect with our extensive network of buyers.",
        "Earn money by selling lovingly used items and content."
    ],
    "buttonLabel": "START SELLING"
};

const HowItWorksBuyerData = {
    "steps": [
        "Explore our sexy sellers to discover the perfect match for you.",
        "Learn more about them through their personal profiles, free galleries and items available for sale.",
        "Send a message and purchase those lovely items."
    ],
    "buttonLabel": "START BUYING"
}


const collectionImages = [

    'https://images.unsplash.com/photo-1525672716948-1f0bb9c49883?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzB8fHNleHl8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1617922001439-4a2e6562f328?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1611601322175-ef8ec8c85f01?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    'https://images.unsplash.com/photo-1682045441000-80ff87892f9a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OTJ8fHNleHl8ZW58MHx8MHx8fDA%3D',
    'https://images.unsplash.com/photo-1645994743130-1bcea3ac41dd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE2fHxzZXh5fGVufDB8fDB8fHww'
]

export { HowItWorksSellerData, HowItWorksBuyerData, collectionImages };
