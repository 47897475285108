import React, { useState, useEffect } from "react";
// import { MDBDataTable } from "mdbreact";
import "../Dashboard/Dashboard.css"
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Button from 'react-bootstrap/Button';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DashboardHeader from "../Dashboard/subcomponents/DashboardHeader";
import LoadingBar from 'react-top-loading-bar'
import MyAlert from "../../../components/Alerts/Alert/alert";
import Dropdown from 'react-bootstrap/Dropdown';
import OrderSearchBar from "../../../components/Searchbar/OrderSearchBar";
const cookies = new Cookies();
export default function Sellerorder() {
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const [text, setText] = useState();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [orderList, setOrderList] = useState();
    const [orderState, setOrderState] = useState(true);
    const [searchQuery, setSearchQuery] = useState('')
    useEffect(() => {
        axiosPrivate.get("seller/orders_listing/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            console.log(res.data, 'fasdf')
            setOrderList(res.data)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
            setText(err.code)
        })
    }, [orderState, axiosPrivate])
    const isDispatched = (id) => {
        for (let i = 0; i < orderList.length; i++) {
            if ('DISPATCHED' === orderList[id].order_status) {
                return true;
            }
        }
        return false;
    }
    const Initiated = (id) => {
        for (let i = 0; i < orderList.length; i++) {
            if ('INITIATED' === orderList[id].order_status) {
                return true;
            }
        }
        return false;
    }
    const HandleStatus = (id, index) => {
        axiosPrivate.patch(`seller/update_order_status/${id}/`, { orderStatus: Initiated(index) ? 'READY_TO_DISPATCH' : 'DISPATCHED' },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setOrderState(!orderState)

        }).catch(err => {
            setText(err.code)
        })
    }
    const CancelOrder = (id, index) => {
        axiosPrivate.patch(`seller/update_order_status/${id}/`, { orderStatus: "CANCELLED" },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setOrderState(!orderState)

        }).catch(err => {
            setText(err.code)
        })
    }
    const userAttributes = []
    const mobileViewData = orderList?.filter((order) =>
        order?.seller_tracking_no.toLowerCase().includes(searchQuery.toLowerCase())
    ).map((elem, index) => {
        return (
            <>
                <div className="col-12 mb-4" style={{ border: "0.15rem solid #17a2b8" }}>
                    {/* <div className=" col-12 "></div> */}
                    <div className='Myorders-pkg-1 shadow card' style={{ backgroundColor: "black", borderRadius: '0' }}>
                        <div className='Track-order-pkg d-flex justify-content-between p-3' style={{ borderBottom: "1px solid white", background: "white" }}>
                            <div className='d-flex flex-column'>
                                <span style={{ color: "black", cursor: 'pointer', fontWeight: '700' }}>Tracking Number : {elem?.seller_tracking_no}</span>
                                <small></small>
                            </div>
                        </div>

                        <div style={{ padding: "20px 0px" }} className='p-3'>
                            <div className="col-12">
                                <p>Order # : {elem?.order_id}</p>
                                <p>Order Date: {elem?.date_created}</p>
                                <p>{elem?.buyer_name}</p>
                            </div>
                            <div className='col-12 d-flex  p-lg-3 p-md-3 p-4 ps-lg-3 ps-md-3 ps-0 '>
                                <div className="col-8 d-flex">
                                    <div className=" img-wrap" >
                                        <Link to={"/"}><img src={`https://server.fantasysenses.com/media/${elem?.product_image}`} alt='not found' /></Link>
                                    </div>
                                    &nbsp;
                                    <div className="d-flex flex-column">
                                        <Link to="" className="txt-txt">{elem?.product_name}
                                        </Link>
                                        <p>{elem?.product_purchased_price}</p>
                                        <span style={{ color: elem.order_status === "INITIATED" ? "black" : "white" }} className={elem?.order_status === "COMPLETED" ? "badge btn_status btn_Completed" : elem?.order_status === "CANCELLED" ? "badge btn_status btn-danger" : elem?.order_status === "INITIATED" ? "badge btn_status btn-warning" : "badge btn_status btn-dark"}>{elem?.order_status}</span>
                                        <small className='warranty'><Link to={"/"}></Link></small>
                                    </div>
                                </div>


                                <div className="col-4">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-button-basic" style={{ cursor: 'pointer', backgroundColor: "#E86669", fontSize: "1rem" }}>
                                            Select Action
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='dropdown-menu' style={{ backgroundColor: "black" }}>
                                            <Dropdown.Item className='myWishlist mb-1'
                                                style={{
                                                    color: "white",
                                                    display: elem.order_status !== "READY_TO_DISPATCH" ? elem.order_status !== "INITIATED" ? "none" : "block" : "block"
                                                }}
                                            ><Button
                                                variant="success"
                                                style={{ width: "100%", display: elem.order_status !== "READY_TO_DISPATCH" ? elem.order_status !== "INITIATED" ? "none" : "block" : "block" }}
                                                onClick={() => HandleStatus(elem.order_id, index)}
                                                disabled={elem.order_status !== "READY_TO_DISPATCH" && elem.order_status !== "INITIATED"}
                                            >{elem.order_status === "INITIATED" ? "Ready to dispatch" : isDispatched(index) ? "Dispatched" : "Dispatch"}
                                                </Button>
                                            </Dropdown.Item>
                                            <Dropdown.Item className='myWishlist mb-1'
                                                style={{
                                                    color: "white",
                                                    display: elem.order_status !== "READY_TO_DISPATCH" ? elem.order_status !== "INITIATED" ? "none" : "block" : "block"
                                                }}>
                                                <Button
                                                    variant="danger"
                                                    style={{ width: "100%", display: elem.order_status !== "READY_TO_DISPATCH" ? elem.order_status !== "INITIATED" ? "none" : "block" : "block" }}
                                                    onClick={() => CancelOrder(elem.order_id, index)}
                                                    disabled={elem.order_status !== "READY_TO_DISPATCH" && elem.order_status !== "INITIATED"}
                                                >Cancel
                                                </Button>
                                            </Dropdown.Item>
                                            <Dropdown.Item className='myWishlist mb-1'>
                                                {elem?.invoice_id && <center><button className='btn btn-success' onClick={() => window.open(`/seller/invoice/${elem?.invoice_id}`)}>View Invoice</button></center>}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    })
    orderList?.map((el, index) => {
        userAttributes.push({
            order: <span as={Link} searchvalue={'tourist'} style={{ cursor: 'pointer' }} onClick={() => {
                navigate('/orders-info', { state: { id: el.order_id } })
            }}><strong>{el.order_id}</strong></span>,
            stn: el?.seller_tracking_no,
            productName: el.product_name,
            ordertotal: `SGD${parseFloat(el.order_total).toFixed(2)}`,
            status: <div className="text-center">
                <span style={{ color: el.order_status === "INITIATED" ? "black" : "white" }} className={el.order_status === "COMPLETED" ? "badge btn_status btn_Completed" : el.order_status === "CANCELLED" ? "badge btn_status btn-danger" : el.order_status === "INITIATED" ? "badge btn_status btn-warning" : "badge btn_status btn-dark"}> {el.order_status}</span>
            </div>,
            date: el.date_created,
            username: el.buyer_name,
            action: <div>
                <center>
                    {/* <Button
                    variant="success"
                    style={{ display: el.order_status !== "READY_TO_DISPATCH" ? el.order_status !== "INITIATED" ? "none" : "block" : "block" }}
                    onClick={() => HandleStatus(el.order_id, index)}
                    disabled={el.order_status !== "READY_TO_DISPATCH" && el.order_status !== "INITIATED"}
                >{el.order_status === "INITIATED" ? "Ready to dispatch" : isDispatched(index) ? "Dispatched" : "Dispatch"}
                </Button> */}


                    {/* <Dropdown */}
                    {/* // controlClassName="btn btn-success" options={options} onChange={''} value={defaultOption} placeholder="Select an option" /> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-button-basic" style={{ cursor: 'pointer', backgroundColor: "#E86669", fontSize: "1rem" }}>
                            Select Action
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu' style={{ backgroundColor: "black" }}>
                            <Dropdown.Item className='myWishlist mb-1'
                                style={{
                                    color: "white",
                                    display: el.order_status !== "READY_TO_DISPATCH" ? el.order_status !== "INITIATED" ? "none" : "block" : "block"
                                }}
                            ><Button
                                variant="success"
                                style={{ width: "100%", display: el.order_status !== "READY_TO_DISPATCH" ? el.order_status !== "INITIATED" ? "none" : "block" : "block" }}
                                onClick={() => HandleStatus(el.order_id, index)}
                                disabled={el.order_status !== "READY_TO_DISPATCH" && el.order_status !== "INITIATED"}
                            >{el.order_status === "INITIATED" ? "Ready to dispatch" : isDispatched(index) ? "Dispatched" : "Dispatch"}
                                </Button>
                            </Dropdown.Item>
                            <Dropdown.Item className='myWishlist mb-1'
                                style={{
                                    color: "white",
                                    display: el.order_status !== "READY_TO_DISPATCH" ? el.order_status !== "INITIATED" ? "none" : "block" : "block"
                                }}>
                                <Button
                                    variant="danger"
                                    style={{ width: "100%", display: el.order_status !== "READY_TO_DISPATCH" ? el.order_status !== "INITIATED" ? "none" : "block" : "block" }}
                                    onClick={() => CancelOrder(el.order_id, index)}
                                    disabled={el.order_status !== "READY_TO_DISPATCH" && el.order_status !== "INITIATED"}
                                >Cancel
                                </Button>
                            </Dropdown.Item>
                            <Dropdown.Item className='myWishlist mb-1'>
                                {el?.invoice_id && <center><button className='btn btn-success' onClick={() => window.open(`/seller/invoice/${el?.invoice_id}`)}>View Invoice</button></center>}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </center>
            </div>,
            // invoiceLink: <>{el?.invoice_id && <center><button className='btn btn-danger' style={{ cursor: 'pointer', backgroundColor: "#E86669" }} onClick={() => window.open(`/seller/invoice/${el?.invoice_id}`)}>View Invoice</button></center>}</>,
        })
    });
    const data = {
        columns: [
            {
                label: "Order#",
                field: "order",
                sort: "asc",
                width: 100
            },
            {
                label: "Seller tracking #",
                field: "stn",
                sort: "asc",
                width: 100
            },
            {
                label: "Product Name",
                field: "productName",
                sort: "asc",
                width: 100
            },
            {
                label: "Order Total",
                field: "ordertotal",
                sort: "asc",
                width: 150
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 150
            },
            {
                label: "Username",
                field: "username",
                sort: "asc",
                width: 270
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 150
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 100
            },
            // {
            //     label: "Invoice Link",
            //     field: "invoiceLink",
            //     // sort: "asc",
            //     width: 100
            // }
        ],
        rows: userAttributes
    };
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage === true && <>
                <DashboardHeader />
                <div className="container dash-table" id="Seller_product">
                    <MyAlert text={text} setText={setText} />
                    <div className="card shadow p-3" >
                        {/* <div className="card-header d-flex justify-content-between"> */}
                        <div className="px-3">
                            <h3>Orders</h3>
                            <span className="note">Sellers use the search bar to locate the matching Seller tracking number for each order.</span>
                        </div>
                        {/* </div> */}
                        {/* <div className="container mt-3">
                        <div className="row">
                            <div className="col-lg-12 px-3">
                                <Link to="#" className="me-3 text-truncate">
                                    All (4)
                                </Link>
                                <Link to="#" className="me-3 text-truncate">
                                    Completed (1)
                                </Link>
                                <Link to="#" className="me-3 text-truncate">
                                    Processing (0)
                                </Link>
                                <Link to="#" className="me-3 text-truncate">
                                    On-hold (2)
                                </Link>
                                <Link to="#" className="me-3 text-truncate">
                                    Refunded (1)
                                </Link>
                            </div>
                        </div>

                    </div> */}

                        <div className="card-body px-3">
                            {/* <MDBDataTable
                                responsive
                                bordered

                                data={data}
                                sortRows={[' order, status']}
                            /> */}
                            <p style={{ lineHeight: "30px"}} className="mt-4">
                                <b>INITIATED</b>: This status indicates that the order has been initiated.<br />
                                <b>READY_TO_DISPATCH:</b> This status serves as an indicator for us to book a locker for the Seller. Sellers will then receive a PIN to send the parcel.<br />
                                <b>DISPATCHED:</b> This status signifies that the item has been deposited into the locker.<br />
                                <b>PROCESSING:</b> This status indicates that we have received the parcel and will repack it for shipping.<br />
                                <b>SHIPPED:</b> The parcel has been shipped to Buyer.<br />
                                <b>COMPLETED:</b> This status confirms that the order has been completed.<br />
                                <b>CANCELLED:</b> This status means that the order has been cancelled due to unavailability of stock.
                            </p>
                        </div>

                    </div>
                </div>
            </>
            }

            <div className="container mobile-view-track mt-5">
                <div className="px-1 pb-2">
                    <h3>Orders</h3>
                    <span className="note">Sellers use the search bar to locate the matching Seller tracking number for each order.</span>
                </div>

                <OrderSearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
                {mobileViewData?.length > 0 ? mobileViewData :
                    <div className="alert alert-warning" role="alert">
                        No results found for {searchQuery}
                    </div>}
            </div>
        </>
    )
}
