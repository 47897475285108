import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import "../ProductPage/subcomponents/Product/product.css";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useLocation } from 'react-router-dom'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import LoadingBar from 'react-top-loading-bar'
import Header from '../../../layout/Header/Header';
import Footer from '../../../layout/Footer/Footer';
import SearchNavbar from '../../../components/SearchNavbar/NewSearchNavbar';
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection';
const steps = ['INITIATED', 'PROCESSING', 'SHIPPED', 'COMPLETED']


const TrackMyOrders = () => {
    const [showPage, setShowPage] = useState(false)
    const [progress, setProgress] = useState(30)
    const { trackingId } = useParams();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([])
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        axiosPrivate.get('product/orders_list_by_tracking_no/', {
            params: {
                trackingNo: trackingId
            }
        }

        ).then(res => {
            setOrders(res?.data)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [trackingId, axiosPrivate])
    const grouped = orders.reduce((h, obj) => Object.assign(h, {
        [obj?.buyer_tracking_no]: (h[obj?.buyer_tracking_no] || []).concat(obj)
    }), {})
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage && <>
                {/* <Header /> */}
                <SearchAndGenderSection />
                <section id='tracking'>
                    <div className='row justify-content-between px-5 mx-3 mb-5'>
                        <div className='card'>
                            <div className='p-4'>
                                <h2 className='text-uppercase m-0'>TRACKING NO. {trackingId}</h2>
                            </div>
                            {Object.values(grouped)?.map((obj, i) =>
                                obj.map((elem, index) =>
                                    <div key={index}>
                                        <div className='card-header'>
                                            <div className='row p-2'>
                                                <div className='col-lg-6'>
                                                    <h5>Package {index + 1}</h5>
                                                    <p className='m-0'>Sold by: <span onClick={() => navigate(`/seller/${elem?.product_seller}`)}
                                                        style={{ color: "#f47274", cursor: "pointer" }}>{elem?.product_seller}</span></p>
                                                    <p className='m-0'>Order created at {elem?.date_created} </p>
                                                </div>
                                                <div className='col-lg-6 d-flex align-items-center'>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Stepper activeStep={steps?.indexOf(elem?.order_status)} alternativeLabel>
                                                            {steps.map((label) => (
                                                                <Step key={label}>
                                                                    <StepLabel>{label}</StepLabel>
                                                                </Step>
                                                            ))}
                                                        </Stepper>
                                                    </Box>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div className='row'>
                                                <table className='table table-borderless px-3'>
                                                    <tr>
                                                        <td>
                                                            <div className='d-flex'>
                                                                <img
                                                                    onClick={() => navigate(`/${elem?.gender === "MALE" ? "product-display-page-men" : "product-display-page-women"}/${elem.product_url_link}`)}
                                                                    className='img-fluid me-3' src={elem?.product_image} alt='' style={{ width: "80px", height: "100px", borderRadius: "10px", cursor: 'pointer' }} />
                                                                <div className='d-flex flex-column justify-content-center'>
                                                                    <p className='mb-0'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => navigate(`/${elem?.gender === "MALE" ? "product-display-page-men" : "product-display-page-women"}/${elem.product_url_link}`)}
                                                                    >{elem?.product_name}</p>
                                                                    <p className='mb-0' onClick={() => navigate(`/seller/${elem?.product_seller}`)}
                                                                        style={{ color: "#f47274", cursor: "pointer" }}>{elem?.product_seller}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{elem?.quantity}</td>
                                                        <td className='text-center'>S${elem?.product_price.toFixed(2)}</td>
                                                        <td className='text-center'>{elem?.delivery_method}</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <th className='text-center'>TOTAL AMOUNT</th>
                                                        <th className='text-center'>S${elem?.order_total.toFixed(2)}</th>
                                                        <td></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </section>
                {/* <Footer /> */}
            </>}
            
        </>
    )
}

export default TrackMyOrders