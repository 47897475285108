import React from 'react'
import Styles from './howItWorks.module.scss'
import HowItWorksItem from './HowItWorksItem'
import classNames from 'classnames';
import { HowItWorksSellerData, HowItWorksBuyerData, collectionImages } from './data.js'
import { Carousel } from 'react-bootstrap';
import SectionContainer from '../index.jsx';

const HowItWorks = () => {
  return (
    <SectionContainer className={Styles.container} >
        <div className={Styles.howItWorksContainer}>
            <HowItWorksItem
            title={<h3 className={classNames(Styles.sectionTitle,Styles.title)}>How it works for <span>Seller</span></h3>}
            data={HowItWorksSellerData}/>
        </div>
        <div className={Styles.carouselContainer}>
            <Carousel className={classNames(Styles.customCarousel, 'howItWorksCarousel')}>
                { collectionImages.map((image, index) => 
                   <Carousel.Item key={index}>
                        <img src={image} className="d-block w-100" alt="..."/>
                    </Carousel.Item>
                )}
            </Carousel>
        </div>
        <div className={Styles.howItWorksContainer}>
            <HowItWorksItem
            title={<h3 className={classNames(Styles.sectionTitle,Styles.title)}>How it works for <span>Seller</span></h3>}
            data={HowItWorksBuyerData}/>
        </div>
    </SectionContainer>
  )
}

export default HowItWorks