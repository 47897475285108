import React, { useEffect, useState } from 'react'
import FeaturedSellerItem from './FeaturedSellerItem'
import Styles from './featuredSeller.module.scss'
import SectionContainer from '..';
import axios from '../../../services/api/axios';

const FeaturedSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [limit, setLimit] = useState(8);
  const [filteredSellers, setFilteredSellers] = useState([]);

  useEffect(() => {
    axios.get('buyer/landing-page/featured-sellers/')
      .then(res => {
        setSellers(res?.data?.sellers)
      }).catch(err => {
          console.log(err)
      })
  },[])

  useEffect(() => {
    if(limit === 0)
      //nolimt
      setFilteredSellers(sellers)
    else
      setFilteredSellers(sellers.slice(0, limit))

  }, [sellers, limit])

  const handleSeeMore = () => {
    //set limit = 0 to see all
    setLimit(0)
  }
  return (
    <SectionContainer >
      <div className="row mb-5">
        <h3 className={Styles.sectionTitle}>Featured <span>Sellers</span></h3> 
        <span className={Styles.sectionSubTitle}>Explore the profiles of some of our top sellers and get to know the individuals behind the allure.</span>
      </div>
      <div className={Styles.sellerItemContainer}>
        {
            filteredSellers.map((seller, index) => 
                <FeaturedSellerItem key={index} className="col-sm-6 col-md-4 col-lg-3" seller={seller}></FeaturedSellerItem>
            )
        }

      </div>
      {
        (limit !== 0 && limit > 8) &&
        <div className="row align-items-center justify-content-center mb-2 mt-5">
          <button className="btn btn-dark " style={{ width: '180px', height: '70px', fontSize: '1.5rem' }} onClick={() => handleSeeMore()}>See More</button>
        </div>
      }
    </SectionContainer>
  )
}

export default FeaturedSellers