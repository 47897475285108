import React, { useState } from "react"
import Styles from './contactDetails.module.scss';
import axios from "../../../services/api/axios";
import Form from 'react-bootstrap/Form';
import MyAlert from "../../Alerts/Alert/alert";

const ContactForm = () => {

    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
    const [text, setText] = useState();
    function handleSubmit(event) {
      event.preventDefault();
      axios.post("contact_us/", {
        email: email,
        address: address,
        phoneNumber: number,
        message: message,
      })
        .then((response) => {
          setEmail('');
          setAddress('')
          setMessage('')
          setNumber('')
          setText('Your request has been placed successfully. We will contact you shortly on your email.')
        });
    }

  return (




        <div>

        <iframe name="dummyframe" id="dummyframe" title="dummyframe" style={{ display: "none" }}></iframe>
        <div className="form">
            <form id="safi" target="dummyframe" onSubmit={handleSubmit} className="php-email-form">
            <div>
                <div className="form-group  mt-3">
                <input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    value={email}
                    id="email" placeholder="Your Email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                </div>
                <div className="form-group  mt-3">
                <input
                    type="text"
                    name="address"
                    className="form-control form-control-lg"
                    value={address}
                    id="Address"
                    placeholder="Address Line 1, Address Line 2, Country, and Postal Code"
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
                </div>
                <div className="form-group  mt-3">
                <input
                    type="text"
                    name="number"
                    className="form-control form-control-lg"
                    value={number}
                    id="phoneNumber"
                    placeholder="Phone Number"
                    onChange={(e) => setNumber(e.target.value.replace(/[^+0-9]/g, ""))}
                    required
                />
                </div>
                <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1"
                id="message"
                required>

                <Form.Control onChange={(e) => setMessage(e.target.value)} value={message} as="textarea" rows={3} placeholder="Write your message here..." />
                </Form.Group>
            </div>
            <div className="text-center mt-5">
                <button type="submit">Send Message</button>
            </div>
            </form>
        </div>
        <MyAlert text={text} setText={setText} />
        </div>


  )
}

export default ContactForm




