import React, { useContext } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../store/GlobalContext';
import './cartUtils.css'   
import { useNavigate } from 'react-router-dom';
const cookies = new Cookies();

export const AddToCart = ({ id }) => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation('landingpage');
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate();
    const handleAddToCart = async () => {
        try {
            const response = await axiosPrivate.post(`/product/add_to_cart/${id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({ type: "REFRESH_CART", payload: !state.refreshCart })
            }
            // Handle success if needed
        } catch (err) {
            console.error(err, 'error');
            // Handle error if needed
        }
    };
    function isProductInCart() {
        const array = state.cartItems
        for (let i = 0; i < array?.length; i++) {
            const seller = array[i];
            for (let j = 0; j < seller?.items?.length; j++) {
                const item = seller.items[j];
                if (item.product_id === id) {
                    return true;
                }
            }
        }
        return false;
    }
    return (
        <button className='addToCart' onClick={(state.isLoggedIn==="true" || state.isLoggedIn===true) ? handleAddToCart : ()=>navigate('/login')} disabled={isProductInCart()}>{t('text7')}</button>
    );
};


