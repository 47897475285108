import SVG from 'react-inlinesvg';
import IconGlobe from '../../shared/assets/icons/globe.svg';
import BaseIcon from './BaseIcon';

const Globe = ({ color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconGlobe} {...iconProps} style={{ color: color}}/>
        </BaseIcon>
    )
}

export default Globe;
