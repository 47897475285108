const categoriesData  = [
    {
        id: 1,
        name: 'Swimwear'
    },
    {
        id: 2,
        name: 'Nightwear'
    },
    {
        id: 3,
        name: 'Lingerie'
    },
    {
        id: 4,
        name: 'Bikini'
    },
    {
        id: 5,
        name: 'Skirts'
    }
]
const productsData = [
    {
        "id": 1,
        "uuid": "06c2042b-3fa9-4485-aeec-079890106266",
        "name": "Flared-skirt dress",
        "price": "513.00",
        "category": "Tops",
        "seller": {
            "first": "Delphine",
            "last": "Schoen",
            "name": "Delphine Schoen",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        "image": 'https://images.unsplash.com/photo-1571118027171-d2e2c56cc926?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2VkdWN0aXZlfGVufDB8fDB8fHww'
    },
    {
        "id": 2,
        "uuid": "297c61b7-3ee4-4e38-8d49-2a4c0408239b",
        "name": "Oversized printed T-shirt",
        "price": "461.00",
        "category": "Tops",
        "seller": {
            "first": "Kristofer",
            "last": "Nader",
            "name": "Kristofer Nader",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        "image": "https://images.unsplash.com/photo-1571114333203-877428f88ad9?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHNlZHVjdGl2ZXxlbnwwfHwwfHx8MA%3D%3D"
    },
    {
        "id": 3,
        "uuid": "7ec96164-fbfb-4278-8c3b-0e1bf53ed5af",
        "name": "Pleated one-shoulder dress",
        "price": "173.00",
        "category": "Dresses",
        "seller": {
            "first": "Libby",
            "last": "Muller",
            "name": "Libby Muller",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: "https://plus.unsplash.com/premium_photo-1661295698045-ee1b87b53075?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjF8fHNlZHVjdGl2ZXxlbnwwfHwwfHx8MA%3D%3D"
    },
    {
        "id": 4,
        "uuid": "6c836cfd-b41c-4e34-b48b-3511d2ab6126",
        "name": "Lace Lingerie Set",
        "price": "745.00",
        "category":  "Lingerie",
        "seller": {
            "first": "Abbey",
            "last": "Marvin",
            "name": "Abbey Marvin",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: "https://plus.unsplash.com/premium_photo-1703341026087-88ddf1348ad2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDV8fHNlZHVjdGl2ZXxlbnwwfHwwfHx8MA%3D%3D"
    },
    {
        "id": 5,
        "uuid": "fd7e8eb7-b50d-4e67-a354-45c43b6c9f10",
        "name": "Pure Stretch Underwear",
        "price": "376.00",
        "category":  "Lingerie",
        "seller": {
            "first": "Esmeralda",
            "last": "Graham",
            "name": "Esmeralda Graham",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://plus.unsplash.com/premium_photo-1682097669470-90d61a46e501?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTM3fHxzZWR1Y3RpdmV8ZW58MHx8MHx8fDA%3D'
    },
    {
        "id": 6,
        "uuid": "81873935-c2a1-4c1e-b3da-dc81d5910d42",
        "name": "Recycled Workout Crop",
        "price": "799.00",
        "category": "Bra",
        "seller": {
            "first": "Neoma",
            "last": "Koepp",
            "name": "Neoma Koepp",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1643848950305-c6284b768d3d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzAzfHxzd2ltc3VpdHxlbnwwfHwwfHx8MA%3D%3D'
    },
    {
        "id": 7,
        "uuid": "029f1424-60c4-4bc2-9cc3-da50900b336c",
        "name": "Diana Lifting Bra",
        "price": "384.00",
        "category": "Bra",
        "seller": {
            "first": "Christina",
            "last": "Bayer",
            "name": "Christina Bayer",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1715436958843-fc1698420265?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY2fHxzZWR1Y3RpdmV8ZW58MHx8MHx8fDA%3D'
    },
    {
        "id": 8,
        "uuid": "136ba88b-3838-4d35-bcd4-0be953cf6436",
        "name": "Mesh Frill Hem Slip Dress",
        "price": "285.00",
        "category": "Nighties",
        "seller": {
            "first": "Shanon",
            "last": "Walker",
            "name": "Shanon Walker",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://plus.unsplash.com/premium_photo-1664474545304-da81893a15e5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTg4fHxzZWR1Y3RpdmV8ZW58MHx8MHx8fDA%3D'
    },
    {
        "id": 9,
        "uuid": "ef1b4690-ef90-4e01-943e-2e7e4e43ade2",
        "name": "Ps Thongs 3 Packs",
        "price": "716.00",
        "category": "Tops",
        "seller": {
            "first": "Bonita",
            "last": "Simonis",
            "name": "Bonita Simonis",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://plus.unsplash.com/premium_photo-1682097611613-1a161e997e49?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTg5fHxzZWR1Y3RpdmV8ZW58MHx8MHx8fDA%3D'
    },
    {
        "id": 10,
        "uuid": "d18f2ca6-0951-4d5c-9f6e-de57376c4e09",
        "name": "Seamless Low Long Bra",
        "price": "62.00",
        "category": "Nighties",
        "seller": {
            "first": "Lawson",
            "last": "Prohaska",
            "name": "Lawson Prohaska",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://plus.unsplash.com/premium_photo-1683120882603-f1b71a2d44d5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjV8fHNlZHVjdGl2ZSUyMHdvbWFufGVufDB8fDB8fHww'
    },
    {
        "id": 11,
        "uuid": "066c02ca-7218-45e0-8a4f-917baa79b752",
        "name": "Babydoll Nightie",
        "price": "778.00",
        "category": "Nighties",
        "seller": {
            "first": "Jaclyn",
            "last": "Wiza",
            "name": "Jaclyn Wiza",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://plus.unsplash.com/premium_photo-1664461662691-82e1ef3fff14?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c3dpbXN1aXR8ZW58MHx8MHx8fDA%3D'
    },
    {
        "id": 12,
        "uuid": "f3645dc0-07a8-47fa-a43c-8cbd4b8ca64b",
        "name": "Plus Size Bra",
        "price": "259.00",
        "category": "Tops",
        "seller": {
            "first": "Jan",
            "last": "Yost",
            "name": "Jan Yost",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1531469535976-c6fc3604014f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 13,
        "uuid": "136b3859-8e19-4f93-8872-2ca3d8128974",
        "name": "Valeria Lace Bra",
        "price": "988.00",
        "category": "Bra",
        "seller": {
            "first": "Barbara",
            "last": "Schneider",
            "name": "Barbara Schneider",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1589657445951-e6dc3d747fb1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 14,
        "uuid": "774dc15d-f5cd-4f17-9bca-9df743b6dd85",
        "name": "Smart Inner Mini Skirt",
        "price": "67.00",
        "category": "Skirts",
        "seller": {
            "first": "Leonel",
            "last": "Ziemann",
            "name": "Leonel Ziemann",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1584651254522-7a4799cfc353?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzR8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 15,
        "uuid": "0aef2b8a-f6ac-45d3-8896-5aed85069b52",
        "name": "Push Up Nipple Cover",
        "price": "595.00",
        "category": "Covers",
        "seller": {
            "first": "Marco",
            "last": "Predovic",
            "name": "Marco Predovic",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1467632499275-7a693a761056?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 16,
        "uuid": "f2a8f74b-3077-4370-b0c4-7f71ff0c3a44",
        "name": "Lace Cut Out Bodysuit",
        "price": "718.00",
        "category": "Bodysuit",
        "seller": {
            "first": "Karlee",
            "last": "Klocko",
            "name": "Karlee Klocko",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1624338618005-0a93e308abae?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 17,
        "uuid": "34784137-cdb2-4873-ab4f-6290b20dfa04",
        "name": "Sammy Sports Bra",
        "price": "385.00",
        "category": "Covers",
        "seller": {
            "first": "Antonio",
            "last": "Dibbert",
            "name": "Antonio Dibbert",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1616147503419-500e80be8447?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTl8fHN3aW1zdWl0fGVufDB8fDB8fHww'
    },
    {
        "id": 18,
        "uuid": "a7337a7c-277b-41e8-90b4-8efae0f50a26",
        "name": "Lacy Dream Thong",
        "price": "670.00",
        "category": "Thongs",
        "seller": {
            "first": "Trace",
            "last": "Ruecker",
            "name": "Trace Ruecker",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1606434468519-1845987bf2e6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE5fHxzd2ltc3VpdHxlbnwwfHwwfHx8MA%3D%3D'
    },
    {
        "id": 19,
        "uuid": "757958a6-179e-41ab-b454-f3fb09d9bf24",
        "name": "Cupro Lace Top",
        "price": "203.00",
        "category": "Laces",
        "seller": {
            "first": "Nakia",
            "last": "Schuppe",
            "name": "Nakia Schuppe",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1570210132316-a6608fe55f76?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI4fHxzd2ltc3VpdHxlbnwwfHwwfHx8MA%3D%3D'
    },
    {
        "id": 20,
        "uuid": "513adcc2-112d-4843-b468-22556fcb26fd",
        "name": "Recycled Workout Crop Bra",
        "price": "314.00",
        "category": "Bra",
        "seller": {
            "first": "Maggie",
            "last": "Stanton",
            "name": "Maggie Stanton",
            "image": "https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"
        },
        image: 'https://images.unsplash.com/photo-1524502397800-2eeaad7c3fe5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjQzfHxzd2ltc3VpdHxlbnwwfHwwfHx8MA%3D%3D'
    }
]
      
export { categoriesData, productsData } 