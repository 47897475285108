import SVG from 'react-inlinesvg';
import IconInstagram from '../../shared/assets/icons/instagram.svg';
import BaseIcon from './BaseIcon';

const Instagram = ({ color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconInstagram} {...iconProps} style={{ color: color}}/>
        </BaseIcon>
    )
}

export default Instagram;
