import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { useReactToPrint } from 'react-to-print';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import LoadingBar from 'react-top-loading-bar'
import "./invoiceandreceipts.css"
const cookies = new Cookies();

const ReceiptPdf = () => {
    const axiosPrivate = useAxiosPrivate();
    const [showPage, setShowPage] = useState(false)
    let { receipt_id } = useParams();
    const [progress, setProgress] = useState(30)
    const [data, setData] = useState();
    const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    useEffect(() => {
        axiosPrivate.post(`generate_receipt/`, { receipt_id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            setData(res.data)
            setProgress(100)
            setShowPage(true)
        }).catch(err => {
        })
    }, [receipt_id, axiosPrivate])
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {showPage && <>
            <div className="cont mt-4" id="printSection" ref={componentRef}>
                <div className="row">
                    <div className="top-left">
                        {/* <h4>Bill to</h4>
                        <div className="split">
                            <div className="split-right">Name: {data?.buyer_fullName} </div>
                        </div>
                        <div className="split">
                            <div className="split-left">Email: {data?.buyer_email} </div>
                        </div>
                        <div className="split">
                            <div className="split-left">Phone No:  {data?.buyer_phone_no} </div>
                        </div> */}
                    </div>

                    <div className="col-12">
                        <h2>RECEIPT</h2>

                        <div className="d-flex mb-1">
                            <div className="split-left">RECEIPT NUMBER : &nbsp;</div>
                            <div className="split-right"> {data?.receipt_number} </div>

                        </div>
                        <div className="d-flex mb-1">
                            <div className="split-left">DATE : &nbsp;</div>
                            <div className="split-right"> {data?.receipt_date} </div>

                        </div>

                        <div className="d-flex mb-1">
                            <div className="split-left">UB GENESIS PTE LTD</div>
                        </div>

                        {/* <div className="d-flex mb-1">
                            <div className="split-left">Email : &nbsp;</div>
                            <div className="split-right">{data?.buyer_email}</div>

                        </div> */}


                    </div>
                </div>

                <div className="items table-responsive">
                    <table className="items-row table">
                        <thead>
                            <tr>
                        <th className="items-row-cell header title">Order No</th>

                        <th className="items-row-cell header quantity">Tracking No</th>

                        <th className="items-row-cell header unit-price">Item Description</th>

                        <th className="items-row-cell header sum">Quantity</th>

                        <th className="items-row-cell header vat-rate">Item Price</th>
                        </tr>
                        </thead>    

                        {data?.orders?.map((elem, index) => {
                            return (
                                <tbody>
                                    <tr>
                                <div className="items-row" key={index}>
                                    <td className="items-row-cell title">{index + 1} </td>

                                    <td className="items-row-cell quantity"> {elem?.buyer_tracking_no}</td>

                                    <td className="items-row-cell title"> {elem?.product_description} </td>
  
                                    <td className="items-row-cell quantity"> {elem?.quantity} </td>

                                    <td className="items-row-cell unit-price"> {elem?.product_purchased_price?.toFixed(2)} </td>

                                    
                                </div>
                                </tr>
                                </tbody>
                            )
                        })}

                    </table>
                </div>

                <div className="summary row">

                    <div className="summary-left col-lg-8 col-md-7 col-12">
                        <p><strong>MODE OF PAYMENT : &nbsp;</strong>{data?.payment_method} </p>
                        <p><strong>MODE OF SHIPPING : &nbsp;</strong> {data?.delivery_method} </p>
                    </div>

                    <div className="summary-right col-lg-4 col-md-5 col-12 me-auto">
                        <div className="d-flex mb-1">
                            <div className="split-left">SUBTOTAL : &nbsp;</div>
                            <div className="split-right">SGD {data?.sub_total?.toFixed(2)} </div>
                        </div>

                        <div className="d-flex mb-1">
                            <div className="split-left">SHIPPING FEE : &nbsp;</div>
                            <div className="split-right">SGD {data?.shipping_fee?.toFixed(2)} </div>
                        </div>

                        <div className="d-flex mb-1">
                            <div className="split-left">TRANSACTION FEE : &nbsp;</div>
                            <div className="split-right">SGD {data?.transaction_fee?.toFixed(2)}</div>
                        </div>

                        <div className="d-flex mb-1">
                            <div className="split-left"><strong>TOTAL AMOUNT : &nbsp;</strong></div>
                            <div className="split-right"><strong>SGD {data?.total?.toFixed(2)} </strong></div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <button className="btn btn-primary m-5 float-end" onClick={handlePrint}>print</button>
            </div>
            </>}
        </>
    )
}
export default ReceiptPdf;