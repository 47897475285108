import React, { useEffect, useState } from 'react';
import "../../../Dashboard/Dashboard.css"
import Cookies from "universal-cookie";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import optionsData from '../../../../../utils/constants/options'
import Form from 'react-bootstrap/Form';
import LoadingBar from 'react-top-loading-bar'

export default function SellerProductDetails(props) {
    const [edit] = useState(true)
    const [progress, setProgress] = useState(30)
    const cookies = new Cookies();
    // const [setImage] = useState();
    const [productName, setProductName] = useState("");
    const [bodyType, setBodyType] = useState();
    const [category] = useState();
    const [country, setCountry] = useState();
    const [race, setRace] = useState();
    const [gender, setGender] = useState();
    const [price, setPrice] = useState("");
    const [age, setAge] = useState();
    // const [setId] = useState();
    const [description, setDescription] = useState();
    const [ageOptions] = useState(optionsData.age);
    const [raceOptions] = useState(optionsData.race);
    const [sizeOptions] = useState(optionsData.size);
    const [countryOPAPI, setCountryOPAPI] = useState([]);
    const [categoryOPAPI, setCategoryOPAPI] = useState([])
    const [productList, setProductList] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [images, setImages] = useState();
    const [quantity, setQuantity] = useState();
    const onLoad = () => {
        //method for button times
        var group1 = document.getElementById("group3");
        group1.classList.remove('hide');
    }
    useEffect(() => {
        axiosPrivate.get("seller/seller_products_listing/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            const products = res.data?.products;
            setProductList(products)
            setProductName(products[props.productId].name)
            setAge(products[props.productId].age)
            setBodyType(products[props.productId].bodyType)
            setQuantity(products[props.productId].quantity)
            setPrice(products[props.productId].price)
            setRace(products[props.productId].race)
            setImages(products[props.productId].image)
            setDescription(products[props.productId].description)
            // setId(res.data[props.productId].id)
            setGender(products[props.productId].gender)
            setProgress(100)
            // setShowPage(true)
        }).catch(err => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosPrivate])
    useEffect(() => {
        axiosPrivate.get("product_country_list/",
        )
            .then(res => {
                setCountryOPAPI(res.data)
            }).catch(err => {
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosPrivate])
    useEffect(() => {
        axiosPrivate.get("product_category_list/",
        )
            .then(res => {
                setCategoryOPAPI(res.data)
            }).catch(err => {
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosPrivate])
    useEffect(() => {
        var fileTag = document.getElementById("file-input"),
            preview = document.getElementById("seller-preview");

        fileTag.addEventListener("change", function () {
            changeImage(this);
        });

        function changeImage(input) {
            var reader;

            if (input.files && input.files[0]) {
                reader = new FileReader();

                reader.onload = function (e) {
                    preview.setAttribute('src', e.target.result);
                }

                reader.readAsDataURL(input.files[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
            {productList &&
                <div className='container ' id='Seller-View'>
                    <div className='row'>
                        <div className='Seller-main shadow  p-0 '>

                            <div className='d-flex m-4'>
                                <h2>{productName}</h2>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 mx-auto '>
                                    <div className='seller-form '>
                                        <div className=" seller-img-area position-absolute">
                                            <div className="seller-profile-pic position-relative">

                                                <div className="seller-profile-img">
                                                    {
                                                        !images &&
                                                        <div className="">
                                                            <span className=" d-flex flex-column text-center  seller-upload-container" >
                                                                <i className="bi bi-images"></i>
                                                            </span>
                                                        </div>
                                                    }
                                                    <img src={images} alt="" id="seller-preview" />

                                                    <div className=" seller-profilepic__content " id="group3" onClick={onLoad}>

                                                        <label htmlFor="file-input">
                                                            <span className="seller-profilepic__icon d-flex flex-column text-center " >
                                                                <i className="fas fa-camera"></i>

                                                                <small>Upload Photo</small>
                                                            </span>
                                                        </label>

                                                        <input id="file-input" type="file"
                                                            // onChange={(event) => {
                                                            //     setImage(event.target.files[0]);
                                                            // }}
                                                            disabled={edit} />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='edit-product-form'>
                                            <div className="row p-lg-5 p-md-5 p-0">

                                                <div className="col-lg-6 col-12 mb-3">
                                                    <label htmlFor="inputName" className="form-label"><strong>Product Name</strong></label>
                                                    <input
                                                        type="email"
                                                        className="form-control form_clr"
                                                        id="inputName"
                                                        value={productName ? productName : "productName"}
                                                        name={productName}
                                                        onChange={(e) => setProductName(e.target.value)}
                                                        disabled={edit} />
                                                </div>

                                                <div className="col-lg-6 col-12 mb-3">
                                                    <label htmlFor="inputPrice" className="form-label"><strong>Price</strong></label>
                                                    <input
                                                        type="email"
                                                        className="form-control form_clr"
                                                        id="inputPrice"
                                                        value={price ? price : ""}
                                                        name={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        disabled={edit} />
                                                </div>

                                                <div className='col-lg-6 col-12 mb-3'>
                                                    <label htmlFor="inputBodyType" className="form-label"><strong>Body Type</strong></label>
                                                    <select
                                                        id="inputBodyType"
                                                        className="form-select form_clr category-field mt-1"
                                                        value={bodyType ? bodyType : ""}
                                                        name={bodyType}
                                                        onChange={(e) => setBodyType(e.target.value)}
                                                        disabled={edit}
                                                    >
                                                        {sizeOptions.map(
                                                            (obj, index) => (
                                                                <option key={index} value={obj.value} className="dropdown-list">{obj.label}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>

                                                <div className='col-lg-6 col-12 mb-3'>
                                                    <label htmlFor="inputCountry" className="form-label"><strong>Country</strong></label>
                                                    <select
                                                        id="inputCountry"
                                                        className="form-select form_clr category-field mt-1"
                                                        value={country ? country : ""}
                                                        name={country}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        disabled={edit}

                                                    >
                                                        {countryOPAPI.map(
                                                            (obj, index) => (
                                                                <option key={index} value={obj.country_name} className="dropdown-list">{obj.country_name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-lg-6 col-12 mb-3'>
                                                    <label htmlFor="inputCategory" className="form-label"><strong>Category</strong></label>
                                                    <select
                                                        id="inputCategory"
                                                        className="form-select form_clr category-field mt-1"
                                                        value={category ? category : ""}
                                                        name={category}
                                                        onChange={(e) => setBodyType(e.target.value)}
                                                        disabled={edit}
                                                    >
                                                        {categoryOPAPI.map(
                                                            (obj, index) => (
                                                                <option key={index} value={obj.name} className="dropdown-list">{obj.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                                <div className='col-lg-6 col-12 mb-3'>
                                                    <label htmlFor="inputRace" className="form-label"><strong>Race</strong></label>
                                                    <select
                                                        id="inputRace"
                                                        className="form-select form_clr category-field mt-1"
                                                        value={race ? race : ""}
                                                        name={race}
                                                        onChange={(e) => setRace(e.target.value)}
                                                        disabled={edit}
                                                    >
                                                        {raceOptions.map(
                                                            (obj, index) => (
                                                                <option key={index} value={obj.value} className="dropdown-list">{obj.label}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>


                                                <div className='col-lg-6 col-12 mb-3'>
                                                    <label htmlFor="inputAge" className="form-label"><strong>Age</strong></label>
                                                    <select
                                                        id="inputAge"
                                                        className="form-select form_clr category-field mt-1"
                                                        value={age ? age : ""}
                                                        name={age}
                                                        onChange={(e) => setAge(e.target.value)}
                                                        disabled={edit}
                                                    >
                                                        {ageOptions.map(
                                                            (obj, index) => (
                                                                <option key={index} value={obj.value} className="dropdown-list">{obj.label}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-12 mb-3">
                                                    <label htmlFor="inputGender" className="form-label">Gender</label>

                                                    <select value={gender ? gender : ""} id="inputGender" className="form-select form_clr category-field mt-1" disabled={edit}>
                                                        <option value="MALE">Male</option>
                                                        <option value="FEMALE">Female</option>
                                                    </select>
                                                </div>

                                                <div className='col-12 mb-3'>
                                                    <label htmlFor="inputQuantity" className="form-label"><strong>Quantity</strong></label>
                                                    <input
                                                        type="number"
                                                        id="inputQuantity"
                                                        className="form-control form_clr category-field mt-1"
                                                        value={quantity ? quantity : ""}
                                                        name={quantity}
                                                        onChange={(e) => setQuantity(e.target.value)}
                                                        disabled={edit}
                                                    />
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <label htmlFor="exampleFormControlTextarea1" className="form-label"><strong>Description</strong></label>
                                                    <Form.Group className="" controlId="exampleForm.ControlTextarea1"
                                                        id="exampleFormControlTextarea1"
                                                        required>
                                                        <Form.Control as="textarea" className='form_clr' onChange={(e) => setDescription(e.target.value)} value={description} name={description} rows={3} placeholder="Add Product description here..." disabled={edit} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            }
            {/* </>
            } */}
        </>
    );
}
