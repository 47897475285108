import Missing from '../../pages/public/Missing/Missing'
import Unauthorized from '../../pages/public/Unauthorized/unauthorized'
import Username from '../../pages/public/Username/Username'
import Otp from '../../pages/public/Otp/Otp'
import Main from '../../pages/public/Main/Main'
import Contact from '../../pages/public/LandingPage/subcomponents/Contact/Contact'
import ContactUs from '../../pages/public/ContactUs'
import About from '../../pages/public/About/About'
import Ourguarantee from '../../pages/public/LandingPage/subcomponents/Ourguarantee/Ourguarantee'
import Faq from '../../pages/public/Faq/Faq'
import Birthday from '../../pages/public/Birthday/Birthday'
import LandingPage from '../../pages/public/LandingPage/LandingPage'
import SignupPage from '../../pages/public/Signup/SignupPage'
import TermsAndConditions from '../../pages/public/TermsAndConditions/TermsAndConditions'
import Login from '../../pages/public/Login/Login'
import ResetPassword from '../../pages/public/ResetPassword/ResetPassword'
import HowItWorks from '../../pages/public/HowItWorks/HowItWorks'
import NewLandingPage from '../../pages/public/LandingPage/StaticLandingPage'
const unProtectedRoutes = [
    {
        path: "/",
        element: <NewLandingPage />
    },
    {
        path: "/old-landing-page",
        element: <LandingPage />
    },
    // {
    //     path: "/Login",
    //     element: <Login />
    // },
    // {
    //     path: "/signup",
    //     element: <SignupPage />
    // },
    {
        path: "/about",
        element: <About />
    },
    {
        path: "/our-guarantee",
        element: <Ourguarantee />
    },
    {
        path: "/faq",
        element: <Faq />
    },
    {
        path: "/contact",
        element: <Contact />
    },
    {
        path: "/contact-us",
        element: <ContactUs />
    },
    {
        path: "/main",
        element: <Main />
    },
    // {
    //     path: "/user/reset-password/:token",
    //     element: <ResetPassword />
    // },
    // {
    //     path: "/otp",
    //     element: <Otp />
    // },
    // {
    //     path: "/username",
    //     element: <Username />
    // },
    // {
    //     path: "/user-details",
    //     element: <Birthday />
    // },
    {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />
    },
    {
        path: "/unauthorized",
        element: <Unauthorized />
    },
    {
        path: "*",
        element: <Missing />
    },
    // {
    //     path: "/myorders",
    //     element: ""
    // },
    // {
    //     path: "/track-orders",
    //     element: ""
    // },
    // {
    //     path: "/wishlist",
    //     element: ""
    // },
    {
        path: "/how-it-works",
        element: <HowItWorks />
    },
]
export default unProtectedRoutes;