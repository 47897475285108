import Footer from "../../../layout/Footer/Footer";
import Header from "../../../layout/Header/Header";
import UserProfile from "./subcomponents/UserProfile";

const Profile = () => {
    return (
        <div className="pageBody">
            {/* <Header /> */}
            <UserProfile />
            {/* <Footer /> */}
        </div>
    )
}

export default Profile