import React, { useContext, useState } from 'react';
import Styles from './header.module.scss';
import logo from '../../../assets/images/png/Fantasy_Senses_logo.png';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import HeartIcon from '../../Icons/Heart';
import HamburgerIcon from '../../Icons/Hamburger';
import { siteLinks} from '../navs';
import { CartIcon } from '../../Icons';
import { useTranslation } from 'react-i18next';
import UserDropdown from '../../UserDropdown';
import LanguageDropdown from '../../LanguageDropdown';
import Cart from '../../Modal/Cart/Cart';
import { calculateTotalItemCount } from '../../../utils/helpers/helpers';
import { GlobalContext } from '../../../store/GlobalContext';
import PromotionBanner from '../../PromotionBanner';


const Header = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn")
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('header');
  const [showCart, setShowCart] = useState(false);
  const { state } = useContext(GlobalContext);
  const { cartItems } = state;

  return (
    <div className={Styles.container}>
      <div className={Styles.innerContainer}>
        <div className={Styles.overlay}></div>
        <div className={Styles.headerContentContainer}>
          <Cart showModel={showCart} setShowModel={setShowCart} />
          <Link className={Styles.logo} to={'/'}>
            <img src={logo} alt="Fantasy Senses" />
          </Link>
          <div className={Styles.navContainer}>
            {
              siteLinks.map ((nav, index) => (
                <Link key={index} className={Styles.navItem} to={nav.link}>{nav.title}</Link>
              ))
            }
          </div>
          <div className={Styles.rightActionContainer}>
            { (isLoggedIn === "true" && isLoggedIn) &&
              <UserDropdown /> || <button type='button' className='btn btn-sm btn-light' onClick={() => navigate('/login') } >Login / Register</button>
            }
            <HamburgerIcon className={classNames(Styles.icon, Styles.hamburgerIcon)} clickable={true} color="white" iconProps={{height: 40, width: 40}} />
            <div className="d-flex items-center " style={{ marginLeft: '15px'}}>
              {/* <GlobeIcon className={classNames(Styles.icon)} clickable={true} color="white" />
              <span className={Styles.language} style={{marginLeft: '10px', color: 'white'}}>US(en)</span> */}
              <LanguageDropdown />
            </div>
            <HeartIcon className={classNames(Styles.icon)} clickable={true} color="white" onClick={() => navigate('/wishlist') }/>
            <CartIcon className={classNames(Styles.icon)}
              clickable={true}
              count={calculateTotalItemCount(cartItems)}
              color="white"
              onClick={() => setShowCart(true)}/>
          </div>
        </div>
      </div>      
      {/* <PromotionBanner text="Shop now and get up to 10% discount!"/> */}
    </div>
  )
}

export default Header