import React, { useContext } from 'react'
import Styles from './productItem.module.scss'
import Avatar from '../Avatar';
import classNames from 'classnames';
import HeartIcon from '../Icons/Heart';
import Cookies from "universal-cookie";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { GlobalContext } from '../../store/GlobalContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../services/api/axios';

const ProductItem = ({ className, rounded, product}) => {
    const cookies = new Cookies();
    const axiosPrivate = useAxiosPrivate();
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate();

    const handleAddToCart = async () => {
        try {
            const response = await axiosPrivate.post(`/product/add_to_cart/${product.id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({ type: "REFRESH_CART", payload: !state.refreshCart })
            }
            // Handle success if needed
        } catch (err) {
            console.error(err, 'error');
            // Handle error if needed
        }
    };

    function isProductInCart() {
        const array = state.cartItems
        for (let i = 0; i < array?.length; i++) {
            const seller = array[i];
            for (let j = 0; j < seller?.items?.length; j++) {
                const item = seller.items[j];
                if (item.product_id === product.id) {
                    return true;
                }
            }
        }
        return false;
    }

    const HandleAddToFavourite = async () => {
        console.log("ki hoya")
        try {
            const response = await axios.post(`product/add_to_favourite/${product.id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({
                    type: "REFRESH_FAVORITES",
                    payload: !state.refreshFavorites
                })
            }
        } catch (err) {
        }
    }

    const HandleRemoveFromFavourite = async () => {
        let wishlist_id;
        for (let i = 0; i < state.favorites?.length; i++) {
            if (product.id === state.favorites[i]?.product_id) {
                wishlist_id = state.favorites[i]?.wishlist_id;
            }
        }
        try {
            const response = await axios.delete(`product/remove_from_favourite/${wishlist_id}/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({
                    type: "REFRESH_FAVORITES",
                    payload: !state.refreshFavorites
                })
            }
        } catch (err) {
        }
    }

    function isProductInFavorite() {
        const array = state.favorites
        for (let i = 0; i < array?.length; i++) {
            if (array[i].product_id === product.id) {
                return true;
            }
        }
        return false;
    }

  return (
    <div className={classNames(className, Styles.container, {[Styles.rounded]: rounded})}>
        <div className={Styles.heartIconContainer}>
            
            {
                isProductInFavorite() ?
                    <span aria-hidden="true" onClick={HandleRemoveFromFavourite}>
                        <HeartIcon className={Styles.heartIcon} fill={"pink"} color={"black"} iconProps={{height:16, width:16}}/>
                    </span>
                    :
                    <span
                        onClick={HandleAddToFavourite}
                        aria-hidden="true"
                    >
                        <HeartIcon className={Styles.heartIcon} fill={"white"} color={"black"} iconProps={{height:16, width:16}}/>
                    </span>
            }
        </div>
        <img src={product.image} alt="" className={Styles.image} />
        <div className={Styles.overlay}></div>
        <div className={Styles.descriptionContainer}>
            <div className={Styles.titlePriceContainer}>
                <span className={Styles.title}>{product.name}</span>
                <span className={Styles.price}>{product.price}</span>
            </div>
            <div className={Styles.categoryName}>{product.category}</div>
            <div className={Styles.footerContainer}>
                <div className={Styles.seller}>
                    <Avatar src={"https://plus.unsplash.com/premium_photo-1688740375397-34605b6abe48?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTY1fHxhdmF0YXJ8ZW58MHx8MHx8fDA%3D"} profileId={"Delphine Schoen"}  height={34} width={34}/>
                    <span className={Styles.sellerName}>{"Delphine Schoen"}</span>
                </div>
                <button className='btn btn-light btn-sm' onClick={(state.isLoggedIn==="true" || state.isLoggedIn===true) ? handleAddToCart : ()=>navigate('/login')} disabled={isProductInCart()}>Add To Cart</button>
            </div>
        </div>
    </div>
  )
}

export default ProductItem