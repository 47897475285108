import React from 'react'
import Mainsignup from './subcomponents/Mainsignup'

const SignupPage = () => {
    return (
        <>
            <Mainsignup />
        </>
    )
}

export default SignupPage