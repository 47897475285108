import React from 'react'
import HowItWorksForBuyers from './subcomponents/HowItWorksForBuyers/HowItWorksForBuyers'
import HowItWorksForSellers from './subcomponents/HowItWorksForSellers/HowItWorksForSellers'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'

const  HowItWorks = (props) => {
  return (
    <>
        {/* <Header /> */}
        <HowItWorksForBuyers/>
        <HowItWorksForSellers/>
        {/* <Footer/> */}
    </>
  )
}


export default HowItWorks
