import React, { useState } from "react"
import Styles from './contactDetails.module.scss';
import SectionContainer from '..';
import ContactForm from "./ContactForm"
import ContactDetails from "./ContactDetails"


const Contact = () => {


  return (
    <SectionContainer className={Styles.container}>
        <div className="row">
          <div className="col-md-6 col-xs-12">
         
          </div>
          <div className="col-md-6 col-xs-12">
          <ContactDetails />
            <ContactForm/>
          </div>
         </div>
    </SectionContainer>
  )
}

export default Contact




