import React, { useCallback, useContext, useEffect } from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import Productsitems from './subcomponents/Product/products'
import { useState } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from 'react-router-dom';
import axios, { axiosPrivate } from '../../../services/api/axios';
import { GlobalContext } from '../../../store/GlobalContext';
import SearchNavbar from '../../../components/SearchNavbar/NewSearchNavbar';
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection';
import { useTranslation } from 'react-i18next';
import withAuth from '../../../shared/hoc/withAuth';
import Newsletter from '../../../components/Sections/Newsletter';
import Styles from './shared.module.scss';
import classNames from 'classnames';
import SectionContainer from '../../../components/Sections';
import { Button, Dropdown, DropdownButton, Form, Pagination } from 'react-bootstrap';
import ShopFilter from '../../../components/Filters/ShopFilter';
import ProductItem from '../../../components/ProductItem';
import { fetchProducts } from '../../../services/buyer/buyer';


const cookies = new Cookies()
const MenProductPage = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const { t } = useTranslation('product')
    const [checkCart, setCheckCart] = useState(false);
    const [query, setQuery] = useState("");
    const [searchType, setSearchType] = useState(true)
    const Navigate = useNavigate();

    const products = state.products ?? [];
    const [filteredList, setFilteredList] = useState([]);
    const [filters, setFilters] = useState({
        gender: "MALE",
        categories: [],
        countries: [],
        bodyTypes: [],
        races: [],
        ages: [],
    });

    const handleSearchChange = (e) => {
        setQuery(e.target.value);
    }
    const searchProduct = useCallback(() => {
         fetchProducts(state.language, { searchString: query, filterType: 'product', gender:'MALE' });
    },[state.language, query])

    useEffect(() => {
        searchProduct()
    }, [searchProduct])



    useEffect(() => {
        let filteredProducts = [...products];

        filteredProducts = filteredProducts.filter((product) => product.gender === filters.gender)
        filteredProducts = filters.categories.length > 0 ? filteredProducts.filter((product) => filters.categories.includes(product.category)) : filteredProducts
        filteredProducts = filters.countries.length > 0 ? filteredProducts.filter((product) => filters.countries.includes(product.country)) : filteredProducts
        filteredProducts = filters.bodyTypes.length > 0 ? filteredProducts.filter((product) => filters.bodyTypes.includes(product.bodyType)) : filteredProducts
        filteredProducts = filters.races.length > 0 ? filteredProducts.filter((product) => filters.races.includes(product.race)) : filteredProducts
        filteredProducts = filters.ages.length > 0 ? filteredProducts.filter((product) => filters.ages.includes(product.age)) : filteredProducts

        setFilteredList(filteredProducts);
    }, [products, filters.gender, filters.categories, filters.countries, filters.bodyTypes, filters.races, filters.ages]);


    

    const toggleGender = () => {
        Navigate('/product-page-women')
    }

    return (
        <div className='pageBody'>
            {/* <Header /> */}
            {/* <SearchAndGenderSection />
            <section id="breadcrumb">
                <nav className='row px-lg-5 mx-lg-3' aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item text-dark opacity-5 " style={{paddingLeft:'8px'}}><Link to="/">SHOP</Link></li>
                        <li className="breadcrumb-item text-dark active" aria-current="page">I’m interested in Mens Clothes</li>
                    </ol>
                </nav>
            </section>
            <section id="hotels" className="section-with-bg wow fadeInUp">
                <div className="row px-lg-5 mx-3">
                    <Productsitems
                        genderbtn="I'm interested in Female's clothes"
                        btn="about-btn-women scrollto"
                        location="/product-page-women"
                        gender={"MALE"}
                        checkCart={checkCart}
                        onChange={value => setCheckCart(value)}
                        query={query}
                        searchType={searchType}
                        setQuery={setQuery}
                        setSearchType={setSearchType}
                    />
                </div>
            </section> */}
            {/* <Footer /> */}
            <SectionContainer >
                <div className={Styles.hero}>
                    <h5>LIMITED EDITION</h5>
                    <h3>50% OFF</h3>
                    <span>see all collection</span>
                </div>

                <div className={Styles.topLayout}>
                    <div className={Styles.leftPanel}>
                        <Button size="lg" className={Styles.interestButton} onClick={toggleGender}>I'M INTERESTED IN FEMALES CLOTHES</Button>
                    </div>
                    <div className={Styles.rightPanel}>
                        <div className={Styles.searchContainer}>
                            <span className={Styles.resultsInfo}>Showing 12 of 20 results</span>
                            <div className={Styles.searchSortContainer}>
                                <Form.Control style={{ marginRight: '20px'}} value={query} onChange={handleSearchChange}/>
                                <DropdownButton  title="Sort By">
                                    <Dropdown.Item>Popular</Dropdown.Item>
                                    <Dropdown.Item>Name</Dropdown.Item>
                                    <Dropdown.Item>Rating</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(Styles.contentLayout)}>
                    <div>
                        <ShopFilter currentFilters={filters} setFilters={setFilters}/>
                    </div>
                    <div className={Styles.rightPanel}>
                        {  (
                            filteredList.length > 0 &&
                                <div className={Styles.productsContainer}>
                                    {
                                        filteredList.map((product) => (
                                        <ProductItem className={Styles.product} rounded={true} product={product} key={product.uuid}/>
                                        ))
                                    }
                                </div>
                            )
                            || (<div>No Product Found.</div>)
                        }
                        <div className={Styles.paginationContainer}>
                            <Pagination className={'productPagination'}>
                                <Pagination.Prev> Previous </Pagination.Prev>
                                <Pagination.Item >{1}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item>{10}</Pagination.Item>
                                <Pagination.Item>{11}</Pagination.Item>
                                <Pagination.Item active>{12}</Pagination.Item>
                                <Pagination.Item>{13}</Pagination.Item>
                                <Pagination.Item>{14}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item>{20}</Pagination.Item>
                                <Pagination.Next>Next</Pagination.Next>
                            </Pagination>
                        </div>
                    </div>
                </div>
            </SectionContainer>
            <Newsletter />
        </div>
    )
}

export default MenProductPage