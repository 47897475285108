import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import Styles from './languageDrowdown.module.scss'
import classNames from 'classnames';
import { GlobeIcon } from '../Icons'
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../store/GlobalContext';

const LanguageDropdown = ({ ...props}) => {
  const { t, i18n } = useTranslation('header');
  const { state, dispatch } = useContext(GlobalContext);
  const [language, setLanguage] = useState(state.language);


  useEffect(() => {
    dispatch({
      type: "LANGUAGE",
      payload: language
    });
    i18n.changeLanguage(language);
  }, [language])

  return (
    <Dropdown align="end" {...props} >
      <Dropdown.Toggle className={Styles.languageDropdownToggle}>
        <GlobeIcon
          clickable={true}
          color="white" />
          <span className='px-2 text-uppercase'>{language}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu className={classNames(Styles.languageDropdownMenu, 'shadow p-2')}>
        <Dropdown.Item className="dropdown-item" type="button" onClick={() => setLanguage("en")}>{t('dropdown21')}</Dropdown.Item>
        <Dropdown.Item className="dropdown-item" type="button" onClick={() => setLanguage("zh")}>{t('dropdown22')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageDropdown