import React, { useContext, useState } from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import "./Wishlist.css"
import { Link, useNavigate } from 'react-router-dom'
import SearchNavbar from '../../../components/SearchNavbar/NewSearchNavbar'
import { GlobalContext } from '../../../store/GlobalContext'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import Cookies from 'universal-cookie'
import MyAlert from '../../../components/Alerts/Alert/alert'
import { isProductInCart } from '../../../utils/helpers/helpers'
import axios from '../../../services/api/axios'
import image from '../../../assets/images/webp/Frame-31.webp'
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection'

const cookies = new Cookies();
const Wishlist = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [loaderId, setLoaderId] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [showAlert, setShowAlert] = useState('')
    const [text, setText] = useState();

    // const filteredItems = state?.favorites?.filter(favorite => {
    //     return !state?.cartItems?.some(cart => cart.items.some(item => item.product_id === favorite.product_id));
    // });
    console.log('STATE.FVORITES----', state.favorites)
    const handleMasterCheckboxChange = () => {
        setSelectAll(!selectAll);
        const allItemIds = state.favorites.map((elem) => elem.product_id);
        setSelectedItems(selectAll ? [] : allItemIds);
    };

    const handleCheckboxChange = (itemId) => {
        const updatedSelectedItems = selectedItems.includes(itemId)
            ? selectedItems.filter((id) => id !== itemId)
            : [...selectedItems, itemId];
        setSelectedItems(updatedSelectedItems);
        setSelectAll(updatedSelectedItems.length === state.favorites.length);
    };
    const HandleRemoveFromFavourite = async (id) => {
        setText("");
        setShowAlert("");
        setLoaderId(id)
        try {
            const response = await axiosPrivate.delete(`product/remove_from_favourite/${id}/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response) {
                dispatch({
                    type: "REFRESH_FAVORITES",
                    payload: !state.refreshFavorites
                })
                setLoaderId("")
            }
        } catch (err) {
            setText(err?.response?.data?.Error)
        }
    }
    function handleAddToCart() {
        setShowAlert("");
        setText("");
        if (selectedItems?.length === 0) {
            setText("Please select an item first")
            return;
        }
        let products = []
        for (let i = 0; i < selectedItems?.length; i++) {
            let product = selectedItems[i];
            if (!isProductInCart(product, state.cartItems)) {
                products.push(product);
            }
        }
        if (products.length > 0) {
            axios.post('product/add-multiple-to-cart/', { products: JSON.stringify(products) }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }).then(res => {
                if (res?.status === 200) {
                    dispatch({ type: "REFRESH_CART", payload: !state.refreshCart })
                }
                setSelectedItems([])
                setSelectAll(false)
                setShowAlert("Selected items are added in the cart");
                window.location.reload()
            }).catch(err => {
                setText(err?.response?.data?.Error)
            })
        } else {
            setText("Selected items are already in the cart")
            setSelectedItems([])
            setSelectAll(false)
        }
    }
    return (
        <div className='pageBody pb-5'>
            {/* <Header /> */}
            <SearchAndGenderSection />
            <section id='wishlist'>
                <div className='row px-lg-5 px-sm-5 mx-3'>
                    <div className='card'>
                        <div className='m-3'>
                            <MyAlert text={text} setText={setText} />
                        </div>
                        <div className={showAlert ? "alert alert-success alert-dismissible fade show m-3" : "offscreen"}>
                            {showAlert}
                            <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                        </div>
                        <div className='p-4'>
                            <h2 className='text-uppercase m-0'>Wishlist</h2>
                        </div>

                        {
                            state?.favorites?.length > 0 ?
                                <>
                                    <div className='px-4 mb-3'>
                                        <button className='btn-addToCart text-uppercase' style={{ backgroundColor: `${!selectedItems.length ? 'grey' : '#E86669'}`, borderColor: `${!selectedItems.length ? 'grey' : '#E86669'}` }} onClick={handleAddToCart} disabled={!selectedItems.length} >Add to cart</button>
                                    </div>

                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-center'>
                                                    <div className="form-check my-auto">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="customCheck1"
                                                            checked={selectAll}
                                                            onChange={handleMasterCheckboxChange}
                                                        />
                                                    </div>
                                                </th>
                                                <th scope="col" >PRODUCT DETAILS</th>
                                                <th scope="col" className='text-center'>PRICE</th>
                                                <th scope="col" className='text-center'>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state?.favorites?.map(elem => (
                                                <tr>
                                                    <td className='text-center'>
                                                        <div className="form-check my-auto">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`customCheck-${elem.product_id}`}
                                                                checked={selectedItems.includes(elem.product_id)}
                                                                onChange={() => handleCheckboxChange(elem.product_id)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex wish'>
                                                            <Link to={`/product-display-page-men/${elem.url_link}`}> <img className={`img-fluid me-${window.screen.width > 500 ? "3" : "5"}`} src={elem?.image} alt='' style={{ width: "80px", height: "100px", borderRadius: "10px",objectFit: window.screen.width > 500 ? "cover" : "contain"}} /></Link>
                                                            <div className='wishlist-productDetails'>
                                                                <Link to={`/product-display-page-men/${elem.url_link}`}><h5 className='' style={{ color: "#f47274" }}>{elem?.name}</h5></Link>
                                                                <p className=''>{elem?.description?.slice(0, 70)}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>S$ {parseFloat(elem?.price).toFixed(2)} </td>
                                                    <td className='text-center'>
                                                        <i onClick={() => HandleRemoveFromFavourite(elem.wishlist_id)} className={loaderId === elem?.wishlist_id ? "visually-hidden" : "fa-regular fa-trash-can fa-2xl"}></i>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                                : <>
                                    <div className='no-data'>
                                        <img src={image} alt="logo" className='data-display' />
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </div>
    )
}

export default Wishlist