import React, { useState } from "react"
import Styles from './contactDetails.module.scss';



const ContactDetails = () => {
    return (
        <div className="">
            <h2>Contact Us</h2>
            <p>If you have any queries do contact us at the following and we hope you have a good time here with us. Off to exploring!</p>
        </div>
    )
  }
  
  export default ContactDetails