import React from 'react'
import Mainbirthday from './subcomponents/Mainbirthday'

const Birthday = () => {
    return (
        <>
            <Mainbirthday />
        </>
    )
}

export default Birthday