import React from 'react'
import FormBody from './subcomponents/FormBody/FormBody';
import Header from '../../../layout/Header/Header';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import Footer from '../../../layout/Footer/Footer'
const cookies = new Cookies()
const Checkout = () => {
    const [checkCart, setCheckCart] = useState(false);
    return (
        <div  style={{ background: "#fff" }}>
            {/* <Header /> */}
            <FormBody
                checkCart={checkCart}
                onChange={value => setCheckCart(value)}
            />
            {/* <Footer/> */}
        </div>
    )
}

export default Checkout;