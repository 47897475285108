import React, { useCallback, useEffect, useState } from 'react'   
import Styles from './featuredProduct.module.scss'
import classNames from 'classnames';
import {  productsData } from './data';
import CategoryTabs from '../../CategoryTabs';
import ProductItem from '../../ProductItem';
import { Carousel } from 'react-bootstrap';
import useScreenSize from '../../../hooks/useScreenSize';
import SectionContainer from '..';
import { Link } from 'react-router-dom'

const FeaturedProducts = () => {
  const [activeCategory, setActiveCategory] = useState("");
  // const [categories, setCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [groupCount, setGroupCount] = useState(0);
  const [productCountGroup, setProductCountGroup] = useState(4);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const screenSize = useScreenSize();


  const categories = productsData.map((product) => {
    return product.category
  }).reduce((acc, curr) => {
    const temp =  acc.find((el) => el === curr);
    if (!temp) {
      acc.push(curr);
    }

    return acc;
  }, []);


  const filterProducts = useCallback(() => {
    let tempProducts = [];
    if(activeCategory === "") {
      tempProducts = productsData;
    }
    else {
      //filter products by category
      tempProducts = productsData.filter((product) => {
        return product.category === activeCategory
      })
    }

    setFilteredProducts(tempProducts);
    if(tempProducts.length > 0) {
      const count = Math.ceil(tempProducts.length / productCountGroup);
      setGroupCount(count);
    } else {
      setGroupCount(0);
    }
  }, [activeCategory, productCountGroup]);


  useEffect(() => {
    //filter products by category
    filterProducts()
  }, [filterProducts]);

  useEffect(() => {
    setActiveSlideIndex(0);
  },[activeCategory, productCountGroup])

  useEffect(()=> {

    const {width } = screenSize
    if(width < 576) {
      setProductCountGroup(1)
    }
    else if(width < 768) {
      setProductCountGroup(2)
    }
    else if(width < 992) {
      setProductCountGroup(3)
    }
    else {
      setProductCountGroup(4)
    }
  }, [screenSize])

  return (
    <SectionContainer className={Styles.container} style={{ background: '#F1F1F1' }}>
        <div className="row mb-5">
            <h3 className={Styles.sectionTitle}>Featured <span>Products</span></h3>
            <span className={Styles.sectionSubTitle}>Indulge in the allure of our Products, where Pretty and Sexy Seller presents an exclusive collection of scented undergarments designed to fulfill your fantasies.</span>
        </div>
        <CategoryTabs categories={categories}
          activeCategory={activeCategory}
          setActiveCategory={(category) => setActiveCategory(category)} />

        <Carousel className={classNames(Styles.carouselContainer, 'featuredProductCarousel')} activeIndex={activeSlideIndex}
          onSelect={(index) => setActiveSlideIndex(index)} indicators={false} interval={null} >
            {
                  [...Array(groupCount)].map((el, index) => {
                    return (
                      <Carousel.Item className={classNames(Styles.carouselItem)} key={index}>
                        <div className={Styles.carouselInnerItem}>
                          {
                            [...filteredProducts].splice(index * productCountGroup, productCountGroup).map((product, index) => {
                              return (<ProductItem rounded={true} product={product} key={product.uuid}/>)
                            })
                          }
                        </div>
                      </Carousel.Item>
                    )
                  })
              }

        </Carousel>
        <div className={classNames(Styles.actionContainer)}>
          <Link to='/product-page-men'>
            <button className="btn btn-dark " style={{ width: '180px', height: '70px', fontSize: '1.5rem' }}>See More</button>
          </Link>
        </div>
    </SectionContainer>
  )
}

export default FeaturedProducts