import { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { axiosPrivate } from '../../../../../services/api/axios';
import Spinner from 'react-bootstrap/Spinner';
const SendEmail = () => {
    const [validated] = useState(false)
    const [showAlert, setShowAlert] = useState('')
    const [errMsg, setErrMsg] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [email, setEmail] = useState('');
    const handleSignup = () => {
        setShowAlert('')
        setErrMsg('')
        setShowLoader(true)
        let body = {
            email: email,
        }
        axiosPrivate.post('user/reset_password/email/', body)
            .then(res => {
                setShowAlert("We have sent you an email to reset your password. Please check your inbox.")
                setShowLoader(false)
            }).catch(err => {
                setShowLoader(false)
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                    setErrMsg(err.response?.data.Error);
                }
                console.log(errMsg, 'errMcg')
            })
    }
    return (
        <>
            <div className="container mt-2 p-lg-5 p-md-5 p-4" >
                <Form className='text-center' noValidate validated={validated}>
                    <div className={errMsg ? "alert alert-danger alert-dismissible fade show" : "offscreen"}>
                        {errMsg}
                        <button type="button" className={errMsg ? "btn-close" : "d-none"} onClick={() => setErrMsg('')}></button>
                    </div>
                    <div className={showAlert ? "alert alert-info alert-dismissible fade show" : "offscreen"}>
                        {showAlert}
                        <button type="button" className={showAlert ? "btn-close" : "d-none"} onClick={() => setShowAlert('')}></button>
                    </div>
                    <h4 className='mb-4'>Enter your email address to reset your password.</h4>
                    <Form.Group className="mb-4">
                        <Form.Control
                            type="email"
                            id="email"
                            autoComplete="off"
                            className="form-control "
                            name="email"
                            value={email}
                            placeholder="Your Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required />
                    </Form.Group>
                    <div className="text-center">
                        <button className="btn btn-next  px-5" type="button" disabled={!email || showLoader ? true : false} onClick={handleSignup}>
                        {showLoader ? <Spinner style={{ width: "1.4rem", height: "1.4rem" }} /> : "Verify Email"}</button>
                    </div>
                </Form>
            </div>
        </>
    )
}
export default SendEmail;