import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "react-phone-number-input/style.css";
import ScrollButton from "./components/Buttons/ScrollButton";
import { GlobalStateProvider } from "./store/GlobalState";
import "./i18n";
import { createTheme, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "./index.scss";
const theme = createTheme({
  /** Put your mantine theme override here */
});

// import "./static/css/style.css"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <MantineProvider theme={theme}>
      <GlobalStateProvider>
        <App />
      </GlobalStateProvider>
      <ScrollButton />
    </MantineProvider>
  </>
  // </React.StrictMode>
);
