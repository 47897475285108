import { Navigate } from 'react-router-dom';


const ProtectedRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!(isLoggedIn === 'true') || !(isLoggedIn)) {
    return <Navigate to='/login' />;
  }
  return children;
};


export default ProtectedRoute;