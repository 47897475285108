import React from 'react'
import Styles from './featuredCategory.module.scss'
import classNames from 'classnames';

const FeaturedCategoryItem = ({ className, fullHeight,  category, actionPlacement = 'bottomLeft' }) => {

  const actionPlacements = {
    TOP_RIGHT: 'topRight',
    TOP_LEFT: 'topLeft',
    BOTTOM_LEFT: 'bottomLeft',
    BOTTOM_RIGHT: 'bottomRight'
  };

  const actionPlacementClasses = {
    [actionPlacements.TOP_RIGHT]: Styles.topRight,
    [actionPlacements.TOP_LEFT]: Styles.topLeft,
    [actionPlacements.BOTTOM_LEFT]: Styles.bottomLeft,
    [actionPlacements.BOTTOM_RIGHT]: Styles.bottomRight
  }

  return (
    <div className={classNames(Styles.item, className, { [Styles.fullHeight]: fullHeight })}>
      <img className={Styles.image} src={category?.image} alt={category?.name}/>
      <div className={classNames(Styles.titleActionContainer, actionPlacementClasses[actionPlacement])}>
        <div className={Styles.title}>{ category?.name }</div>
        <button className="btn btn-dark" style={{ width: '140px' }}>Shop Now</button>
      </div>
    </div>
  )
}
export default FeaturedCategoryItem