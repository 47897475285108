import React from 'react'
import Modal from 'react-bootstrap/Modal';

const ConfirmationModel = ({
    showModel, setShowModel, status, changeStatus
}) => {
    const handleClose = () => {
        setShowModel(false)
    };
    return (
        <>
            <Modal show={showModel} onHide={handleClose}
                size="md"
                aria-labelledby="example-modal-sizes-title-lg" className="ps-0"
            >
                <Modal.Body className='my-4'>
                    <h3 className='mb-5'>Change Status</h3>
                    <p className='my-5'>Are you sure you want to change the status of selected orders to <strong>{status}</strong>?</p>
                    <button className="btn btn-dark form-control" type="button" onClick={() => changeStatus()}>Change</button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmationModel
