import React, { useEffect, useState } from 'react'
import FeaturedCategoryItem from './FeaturedCategoryItem'
import Styles from './featuredCategory.module.scss'
import SectionContainer from '..';
import axios from '../../../services/api/axios';

const FeaturedCategories = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get('buyer/landing-page/featured-categories/?limit=5')
      .then(res => {
        setCategories(res?.data?.categories)
      }).catch(err => {
          console.log(err)
      })
  },[])

  return (
    <SectionContainer >
      <div className='row mb-5'>
        <h3 className={Styles.sectionTitle}>Featured <span>Categories</span></h3> 
        <span className={Styles.sectionSubTitle}>Discover the diverse and enticing collections available on FantasySenses.</span>
      </div>
      <div className={Styles.categoriesContainer}>
        {
          categories.map((category, index) =>
          <FeaturedCategoryItem key={index} category={category} actionPlacement={index === 2 ? 'topLeft' : 'bottomLeft'}/>)
        }
      </div>
    </SectionContainer>
  )
}

export default FeaturedCategories