export function extractMonthAndDay(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Get the month and day from the date object
    const month = months[date.getMonth()];
    const day = date.getDate();

    // Return the result
    return { month, day };
}

export function calculateTotalItemCount(sellers) {
    let totalCount = 0;

    sellers?.forEach((seller) => {
        totalCount += seller.items.length;
    });

    return totalCount;
}

export function isProductInCart(product_id, sellersData) {
    for (const seller of sellersData) {
        for (const item of seller.items) {
            if (item.product_id === product_id) {
                return true;
            }
        }
    }
    return false;
}