import React from 'react'
import Styles from './categoryTab.module.scss'
import classNames from 'classnames';

const CategoryTabs = ({ categories, activeCategory, setActiveCategory }) => {
    return (
    <div className={Styles.container}>
        <div className={classNames(Styles.tab, {[Styles.active]: activeCategory === ""})} key={-1}
            onClick={() => setActiveCategory("")}>All</div>
        {
           categories.map((category, index) =>
           <div className={classNames(Styles.tab, {[Styles.active]: activeCategory === category})} 
                key={index} onClick={() => setActiveCategory(category)}>
                {category}
           </div>
        )}
    </div>
  )
}

export default CategoryTabs