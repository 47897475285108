import { useLocation, Navigate, Outlet } from "react-router-dom";
const AuthGuard = () => {
  const location = useLocation();
  const isLoggedIn = window.localStorage.getItem("isLoggedIn");

  return [true, "true"].includes(isLoggedIn) ? (
    <Outlet />
  ) : (
    <Navigate to="/Login" state={{ from: location }} replace />
  );
};
export default AuthGuard;
