import React from 'react'
import Styles from './footer.module.scss'
import { EmailIcon, InstagramIcon, LocationIcon, PhoneIcon, WhatsAppIcon, LineIcon, WeChatIcon } from '../../Icons';
import { siteLinks, categories, helpLinks } from '../navs';
import { Link } from 'react-router-dom';
import classNames from  'classnames';
import { useState } from 'react';
import weChatQr from '../../../shared/assets/images/weChatQr.jpg';
import logoWhite from '../../../assets/images/png/Fantasy_Senses_logo_white.png';

const Footer = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div className={Styles.container}>
        <div className={Styles.mainContainer}>
            <div className={Styles.detailsItem}>
                <Link className={Styles.logo} to={'/'}>
                    <img src={logoWhite} alt="Fantasy Senses" />
                </Link>
            </div>
            <div className={Styles.detailsItem}>
                <div className={Styles.title}>
                    Quick Links
                </div>
                {
                    siteLinks.map((nav, index) => 
                        <Link className={Styles.listItem} key={index} to={nav.link}>
                            {nav.title}
                        </Link>
                    )
                }
            </div>
            <div className={Styles.detailsItem}>
                <div className={Styles.title}>
                    Our Products
                </div>
                {
                    categories.map((nav, index) => 
                        <Link className={Styles.listItem} key={index}>
                            {nav.title}
                        </Link>
                    )
                }
            </div>
            <div className={Styles.detailsItem}>
                <div className={Styles.title}>
                    Blogs
                </div>
                {
                    categories.map((nav, index) => 
                        <Link className={Styles.listItem} key={index}>
                            {nav.title}
                        </Link>
                    )
                }
            </div>
            <div className={Styles.detailsItem}>
                <div className={Styles.title}>
                    Help
                </div>
                {
                    helpLinks.map((nav, index) => 
                        <Link className={Styles.listItem} key={index} to={nav.link}>
                            {nav.title}
                        </Link>
                    )
                }
            </div>
        </div>
        <div className={Styles.socialIconContainer}>
            <Link to='https://api.whatsapp.com/send?phone=6587263155&text=Hi,%20I%27d%20like%20to%20inquire%20about' target='_blank target='_blank>
                <WhatsAppIcon className={classNames(Styles.socialIcon)} clickable={true} iconProps={{height: 40, width: 40}} color='white'/>
            </Link>
            <Link to='https://line.me/R/fantasysenses' target='_blank'>
                <LineIcon className={classNames(Styles.socialIcon)} clickable={true} iconProps={{height: 32, width: 32}} color='white'/>
            </Link>
            <Link to='https://www.instagram.com/fantasysenses/' target='_blank'>
                <InstagramIcon className={classNames(Styles.socialIcon)} clickable={true} iconProps={{height: 40, width: 40}} color='white'/>
            </Link>            
            <WeChatIcon className={classNames(Styles.socialIcon)} clickable={true} iconProps={{height: 32, width: 32}} color='white' onClick={handleShow}/>
        </div>

        {
            show ?

            <div className={Styles.imageModal}>
                <span onClick={handleClose}>&times;</span>
                <img src={weChatQr} alt="We Chat QR"/>        
            </div>

            : null
        }

        <div className={Styles.bottomContainer}>
            © 2024 FantasySenses. All Rights Reserved.
        </div>
    </div>
  )
}

export default Footer