import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import cx from "classnames";
import Cookies from "universal-cookie";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";

import axios from "../../services/api/axios";
import { GlobalContext } from "../../store/GlobalContext";

import Styles from "./SearchOptions.module.scss";

const cookies = new Cookies();
const SearchNavbar = () => {
  const { state } = useContext(GlobalContext);
  const [isProduct, setIsProduct] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [query, setQuery] = useState("");
  const Navigate = useNavigate();
  const handleInputChange = e => {
    const inputValue = e.target.value;
    setQuery(inputValue);
  };

  useEffect(() => {
    if (query) {
      axios
        .post(
          "buyer/suggestion/",
          {
            filter_type: isProduct ? "product" : "seller",
            search_string: query
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookies.get("access")}`,
              "Accept-Language": state?.language
            },
            withCredentials: true
          }
        )
        .then(res => {
          setSuggestions(res?.data);
          setIsShown(Boolean(res?.data?.length));
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [query, isProduct]);
  return (
    // <div
    //   onFocus={() => setIsShown(true)}
    //   onBlur={() => setIsShown(false)}
    //   className="d-flex align-items-center p-relative"
    // >
    //   <input
    //     type="text"
    //     className="search-input"
    //     placeholder="Search..."
    //     id="searchText"
    //     name="searchKeyword"
    //     value={query}
    //     onChange={handleInputChange}
    //   />
    //   <i className="fa fa-search"></i>
    //   {/* <input className="search-btn" type='button' value={isProduct ? "Products" : "Sellers"} onClick={() => setIsProduct(!isProduct)} /> */}

    //   <CustomDropdown isProduct={isProduct} setIsProduct={setIsProduct} />
    //   {suggestions.length > 0 && isShown && (
    //     <div className="suggestions-container p-2">
    //       <ul>
    //         {suggestions.map((suggestion, index) => (
    //           <li
    //             key={index}
    //             style={{ cursor: "pointer" }}
    //             onMouseDown={e => {
    //               e.preventDefault(); // Prevents the input field from losing focus
    //               Navigate(
    //                 suggestion?.name
    //                   ? `/product-display-page-women/${suggestion?.url_link}`
    //                   : `/seller/${suggestion?.userName}`
    //               );
    //             }}
    //           >
    //             {suggestion?.name ?? suggestion?.userName}
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   )}
    // </div>
    <div className="p-relative">
      <InputGroup size="lg">
        <Form.Control
          placeholder="Search"
          value={query}
          onChange={handleInputChange}
        />
        <Dropdown>
          <Dropdown.Toggle variant="dark" className={Styles.customToggle}>
            <i className="fa fa-gear"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: "210px" }}>
            <div className="d-flex flex-column px-3 py-1 pt-2">
              <div className="d-flex space-between w-100">
                <Form.Label className="flex-grow-1">
                  Search by Product
                </Form.Label>
                <Form.Check
                  type="switch"
                  variant="dark"
                  checked={isProduct}
                  onChange={() => setIsProduct(true)}
                />
              </div>

              <div className="d-flex space-between w-100">
                <Form.Label className="flex-grow-1">
                  Search by Seller
                </Form.Label>
                <Form.Check
                  type="switch"
                  variant="dark"
                  checked={!isProduct}
                  onChange={() => setIsProduct(false)}
                />
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Button
          variant="dark"
          style={{ borderLeft: "1px solid gray", minWidth: "120px" }}
        >
          Search
        </Button>
      </InputGroup>
      {suggestions.length > 0 && isShown && (
        <ul className={cx(Styles.suggestionsContainer, "p-3")}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className={Styles.suggestion}
              onClick={e => {
                e.preventDefault(); // Prevents the input field from losing focus
                Navigate(
                  suggestion?.name
                    ? `/product-display-page-women/${suggestion?.url_link}`
                    : `/seller/${suggestion?.userName}`
                );
                setIsShown(false);
              }}
            >
              {suggestion?.name ?? suggestion?.userName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchNavbar;
