import { useEffect, useState } from "react";
import "../../Dashboard/Dashboard.css"
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export const WeeklySalesData = () => {
    const axiosPrivate = useAxiosPrivate();
    const [sales, setSales] = useState([]);
    useEffect(() => {
        axiosPrivate.get("seller/sales/",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            console.log(res.data, "data")
            setSales(res.data)
        }).catch(err => {
        })
    }, [axiosPrivate])

    const grouped = sales?.results?.map((elem) => {
        let date = new Date(elem.date_created)
        return {
            start: convert(startOfWeek(date, { weekStartsOn: 1 })),
            end: convert(endOfWeek(date, { weekStartsOn: 1 }))
        }
    })
    const uniqueIds = [];

    const uniqueWeeks = grouped?.filter(element => {
        const isDuplicate = uniqueIds?.includes(element?.start);

        if (!isDuplicate) {
            uniqueIds.push(element?.start);

            return true;
        }

        return false;
    });
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const getWeeklyData = () => {
        let arr = []
        for (let i = 0; i < uniqueWeeks?.length; i++) {
            let body = sales?.results?.filter(elem => convert(elem?.date_created) >= convert(uniqueWeeks[i]?.start) && convert(elem?.date_created) <= convert(uniqueWeeks[i]?.end))
            var total = 0
            var adminFee = 0
            var someDate = new Date(uniqueWeeks[i]?.end);
            var numberOfDaysToAdd = 10;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            var date1 = new Date(result);
            var date2 = new Date();
            var Difference_In_Time = date1.getTime() - date2.getTime();
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            for (let i = 0, _len = body?.length; i < _len; i++) {
                total += body[i]?.total_sale
                adminFee += body[i]?.admin_fee
            }
            let data = {
                startDate: uniqueWeeks[i]?.start,
                endDate: uniqueWeeks[i]?.end,
                results: body,
                totalSales: total,
                adminFee: adminFee,
                payoutAmount: total - adminFee,
                days: parseInt(Difference_In_Days)
            }
            arr.push(data)
        }
        return arr;
    }
    return getWeeklyData();
}
