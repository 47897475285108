const siteLinks = [
  {
    title: "Home",
    link: "/"
  },
  {
    title: "Shop",
    link: "/product-page-men"
  },
  // {
  //     "title": "Find Seller",
  //     "link": "/find-seller"
  // },
  // {
  //     "title": "Find Items",
  //     "link": "/find-items"
  // },
  //   {
  //     title: "Sell",
  //     link: "/seller/dashboard"
  //   },
  //   {
  //     title: "Blogs",
  //     link: "/blogs"
  //   },
  {
    title: "About Us",
    link: "/about"
  },
  {
    title: "FAQs",
    link: "/faq"
  },
  {
    title: "Contact Us",
    link: "/contact-us"
  }
];

const categories = [
  {
    title: "Swimwear",
    link: "/"
  },
  {
    title: "Nightwear",
    link: "/"
  },
  {
    title: "Bikini",
    link: "/"
  },
  {
    title: "Skirts",
    link: "/"
  },
  {
    title: "Lingerie",
    link: "/"
  },
  {
    title: "Underwear",
    link: "/"
  }
];

const helpLinks = [
  {
    title: "Contact Us",
    link: "/contact"
  },
  {
    title: "FAQs",
    link: "/faq"
  }
];

export { siteLinks, categories, helpLinks };
