import React from 'react'
import BuyerSellerFaq from './subcomponents/BuyerSellerFaq';
// import SellersFaq from './subcomponents/SellersFaq';
import Header from '../../../layout/Header/Header';
import Footer from '../../../layout/Footer/Footer';

const Faq = () => {
    return (
        <div className='pageBody'>
            {/* <Header /> */}
            <BuyerSellerFaq />
            {/* <SellersFaq /> */}
            {/* <Footer /> */}
        </div>
    )
}

export default Faq;