import React from "react"
export default function Feature() {
  return (
    <section id="how-it-works-buyer">
      <div className="container-fluid" data-aos="fade-up">
        <div className="text-center my-5">
          <h2>HOW IT WORKS FOR BUYERS </h2>
        </div>

        <div className="row mb-5 px-5">
          <div className="col-lg-4">
            <div className="d-flex align-items-center mx-4">
              <h1 className="display-1 num me-5 mb-0">1</h1>
              <p className="lh-base mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center mx-4">
              <h1 className="display-1 num me-5 mb-0">2</h1>
              <p className="lh-base mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center mx-4">
              <h1 className="display-1 num me-5 mb-0">3</h1>
              <p className="lh-base mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}