import React, { useContext } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import Styles from "./userDropdown.module.scss";
import { UserIcon } from "../Icons";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../store/GlobalContext";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import AuthContext from "../../context/AuthProvider";
import UserArea from "./UserArea";

const BuyerDropDownItems = ({ handleChangeBuyerSide, t }) => {
  return (
    <>
      {/* <Dropdown.Item
        onClick={() => handleChangeBuyerSide("/dashboard")}
        as="button"
        className="btn btn-primary text-uppercase"
      >
        {t("dropdown11")}
      </Dropdown.Item> */}
      <Dropdown.Item as={Link} to="/myorders" className="myWishlist mb-1">
        {t("dropdown12")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/wishlist" className="myWishlist mb-1">
        {t("dropdown13")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/profile" className="Profile mb-1">
        {t("dropdown14")}
      </Dropdown.Item>
    </>
  );
};

const SellerDropDownItems = ({ handleChangeBuyerSide, t, gender }) => {
  return (
    <>
      {/* <Dropdown.Item
        onClick={() =>
          handleChangeBuyerSide(
            gender === "MALE" ? "/product-page-men" : "/product-page-women"
          )
        }
        // className="btn btn-primary text-uppercase my-3"
      >
        {t("dropdown27")}
      </Dropdown.Item> */}
      <Dropdown.Item as={Link} to="/dashboard" className="myWishlist mb-1">
        {t("dropdown23")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/products-list" className="myWishlist mb-1">
        {t("dropdown26")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/order-list" className="myWishlist mb-1">
        {t("dropdown12")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/seller-sales" className="Profile mb-1">
        {t("dropdown24")}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/profile" className="Profile mb-1">
        {t("dropdown25")}
      </Dropdown.Item>
    </>
  );
};

const UserDropdown = ({ ...props }) => {
  const { t, i18n } = useTranslation("header");
  const { state, dispatch } = useContext(GlobalContext);
  console.log("UserDropdown", state);
  const {
    buyerSide,
    user: { userName, image },
    productsApiParams
  } = state;
  const { setAuth } = useContext(AuthContext);
  const gender = productsApiParams.gender ?? "MALE";

  const navigate = useNavigate();
  const logOut = () => {
    setAuth({});
    localStorage.setItem("isLoggedIn", "false");
    navigate("/login");
  };

  const handleChangeBuyerSide = linkToNavigate => {
    console.log(linkToNavigate);
    dispatch({
      type: "BUYER_SIDE",
      payload: !state.buyerSide
    });

    console.log(state.buyerSide);
    navigate(linkToNavigate);
  };

  return (
    <Dropdown
      align="end"
      className={Styles.userDropdown}
      id="header_dropdown"
      {...props}
    >
      <Dropdown.Toggle
        className={Styles.userDropdownToggle}
        variant=""
      >
        <UserIcon clickable={true} color="white" />
        <span className="px-2">{userName}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={classNames(Styles.userDropdownMenu, "shadow p-2")}
      >
        <UserArea />
        {/* <Dropdown.Item as={Link} to="/me" className="mb-1">
          {" "}
          {userName}
        </Dropdown.Item> */}
        <Dropdown.Divider />
        <Dropdown.Header>{`Switch Role`}</Dropdown.Header>
        <Dropdown.Item
          className={classNames(
            { ["active"]: buyerSide },
            "d-flex align-items-center"
          )}
          onClick={() =>
            handleChangeBuyerSide(
              gender === "MALE" ? "/product-page-men" : "/product-page-women"
            )
          }
        >
          <span className="flex-grow-1">{t("dropdown27")}</span>
          {buyerSide && <i className="fa fa-check-circle"></i>}
        </Dropdown.Item>
        <Dropdown.Item
          className={classNames(
            { ["active"]: !buyerSide },
            "d-flex align-items-center"
          )}
          onClick={() => handleChangeBuyerSide("/dashboard")}
          // className="btn btn-primary text-uppercase"
        >
          <span className="flex-grow-1">{t("dropdown11")}</span>
          {!buyerSide && <i className="fa fa-check-circle"></i>}
        </Dropdown.Item>
        <Dropdown.Divider />
        {(buyerSide && (
          <BuyerDropDownItems
            handleChangeBuyerSide={handleChangeBuyerSide}
            t={t}
          />
        )) || (
          <SellerDropDownItems
            handleChangeBuyerSide={handleChangeBuyerSide}
            t={t}
            gender={gender}
          />
        )}
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => logOut()} className="Sign_out ">
          {t("dropdown15")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
