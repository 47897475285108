import React, { useContext, useState } from 'react'
import Styles from './shopFilter.module.scss';
import classNames from 'classnames';
import { Accordion, Button, Form, FormCheck, InputGroup } from 'react-bootstrap';
import { Slider } from '@mui/material';
import { races, bodyTypes, ages} from './filterConstants';
import { GlobalContext } from '../../../store/GlobalContext';

const ShopFilter = ({ currentFilters, setFilters: setCurrentFilters }) => {
  const [priceRange, setPriceRange] = useState([0, 100]);
  const { state } = useContext(GlobalContext);
  const [filters, setFilters] = useState({...currentFilters});

  const categories = state.categoryList?.map((category) => {
    return {
      label: category.name,
      value: category.name
    }
  });
  const countries = state.countryList?.map((country) => {
    return {
      label: country.country_name,
      value: country.country_name
    }
  });

  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
    setFilters({ ...filters, prices: { min: newValue[0], max: newValue[1] } });
  }

  const handleMinChange = (event) => {
    setPriceRange([event.target.value, priceRange[1]]);
    setFilters({ ...filters, prices: { min: event.target.value, max: priceRange[1] } });
  }

  const handleMaxChange = (event) => {
    setPriceRange([priceRange[0], event.target.value]);
    setFilters ({ ...filters, prices: { min: priceRange[0], max: event.target.value } });
  }

  const handleCategoriesChange = (event) => {
    const { value, checked } = event.target;
    const { categories } = filters
    if (checked)
      setFilters({ ...filters, categories: [value, ...categories] });
    else
      setFilters({ ...filters, categories: [...categories.filter((category) => category !== value)] });
  }

  const handleBodyTypesChange = (event) => {
    const { value, checked } = event.target;
    const { bodyTypes } = filters
    if (checked)
      setFilters({ ...filters, bodyTypes: [value, ...bodyTypes] });
    else
      setFilters({ ...filters, bodyTypes: [...bodyTypes.filter((item) => item !== value)] });
  }

  const handleCountriesChange = (event) => {
    const { value, checked } = event.target;
    const { countries } = filters
    if (checked)
      setFilters({ ...filters, countries: [value, ...countries] });
    else
      setFilters({ ...filters, countries: [...countries.filter((item) => item !== value)] });
  }

  const handleRacesChange = (event) => {
    const { value, checked } = event.target;
    const { races } = filters
    if (checked)
      setFilters({ ...filters, races: [value, ...races] });
    else
      setFilters({ ...filters, races: [...races.filter((item) => item !== value)] });
  }

  const handleAgesChange = (event) => {
    const { value, checked } = event.target;
    const { ages } = filters
    if (checked)
      setFilters({ ...filters, ages: [value, ...ages] });
    else
      setFilters({ ...filters, ages: [...ages.filter((item) => item !== value)] });
  }

  const applyFilters = () => {
    setCurrentFilters({...filters});
    console.log('applyFilters')
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.headerContainer}>
        <span className={Styles.header}>Filters</span>
        <a>Clear filters</a>
      </div>
      <Accordion defaultActiveKey={['0']} alwaysOpen>
        <Accordion.Item eventKey="0" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div">Categories</Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            { categories.map((item) => (
              <FormCheck
                name='category'
                className={Styles.checkBox}
                key={item.value}
                value={item.value}
                label={item.label}
                checked={filters?.categories?.includes(item.value)}
                onChange={handleCategoriesChange}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div"> Body Type </Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            { bodyTypes.map((item) => (
              <FormCheck
                className={Styles.checkBox}
                key={item.value}
                value={item.value}
                label={item.label}
                checked={filters?.bodyTypes?.includes(item.value)}
                onChange={handleBodyTypesChange}
                />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div"> Country </Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            { countries.map((item) => (
              <FormCheck
                className={Styles.checkBox}
                key={item.value}
                value={item.value}
                label={item.label}
                checked={filters?.countries?.includes(item.value)}
                onChange={handleCountriesChange}
                />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div"> Race </Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            { races.map((item) => (
              <FormCheck
                className={Styles.checkBox}
                key={item.value}
                value={item.value}
                label={item.label}
                checked={filters?.races?.includes(item.value)}
                onChange={handleRacesChange}
                />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div"> Age </Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            { ages.map((item) => (
              <FormCheck
                className={Styles.checkBox}
                key={item.value}
                value={item.value}
                label={item.label}
                checked={filters?.ages?.includes(item.value)}
                onChange={handleAgesChange}
                />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className={Styles.accordionItem}>
          <Accordion.Header className={Styles.accordionHeader} as="div"> Price </Accordion.Header>
          <Accordion.Body  className={Styles.accordionBody}>
            <div style={{padding: '6px'}}>
              <Slider className={'priceFilterSlider'} value={priceRange} onChange={handlePriceRangeChange} valueLabelDisplay="auto"/>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: '48%'}}>
                <label>From</label>
                <Form.Control
                  value={priceRange[0]}
                  onChange={handleMinChange}
                />
              </div>
              <div style={{width: '48%'}}>
                <label>To</label>
                <Form.Control
                  value={priceRange[1]}
                  onChange={handleMaxChange}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className='mt-3 d-flex justify-content-end'>
        <Button className='me-3' onClick={applyFilters}>Apply</Button>
        <Button variant='secondary'>Clear</Button>
      </div>
      
    </div>
  )
}

export default ShopFilter