import React from 'react'
import classNames from 'classnames';
import Styles from './shared.module.scss'

const SectionContainer = ({ children, className, ...props}) => {
  return (
    <section className={classNames(Styles.container, className)} {...props}>
        {children}
    </section>
  )
}

export default SectionContainer