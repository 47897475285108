import SVG from 'react-inlinesvg';
import IconCart from '../../shared/assets/icons/cart.svg';
import BaseIcon from './BaseIcon';

const Cart = ({ color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconCart} {...iconProps} style={{ color: color}}/>
        </BaseIcon>
    )
}

export default Cart;
