import React,{useContext,useRef,useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../../../store/GlobalContext';
const HowItWorksForSellers = () => {

    const { t } = useTranslation('landingpage');
    const { state, dispatch } = useContext(GlobalContext)
    const contactRef = useRef(null);
    useEffect(() => {
        if (contactRef) {
            dispatch({
                type: 'CONTACT_REF',
                payload: contactRef
            })
        }
    }, [])
    return (
        <section id="how-it-works-sell" ref={contactRef}>
            <div className="container-fluid" data-aos="fade-up">
                <div className="text-center my-3">
                    <h2>{t('heading3')}</h2>
                </div>
                <div className="row px-lg-5">
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-lg-5 me-sm-4 me-3">{t('no1')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-lg-5 me-sm-4 me-3">{t('no2')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex align-items-center mx-4">
                            <h1 className="display-1 num me-lg-5 me-sm-4 me-3">{t('no3')}</h1>
                            <p className="lh-base">{t('p2')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorksForSellers;