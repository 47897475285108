import React, { useEffect, useState } from "react";
// import { MDBDataTable } from "mdbreact";
import "../Dashboard/Dashboard.css"
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProductEditModel from "../../../components/Modal/ProductEditModel/ProductEditModel"
import Cookies from "universal-cookie";
import DashboardHeader from "../Dashboard/subcomponents/DashboardHeader";
import LoadingBar from 'react-top-loading-bar'
import MyAlert from "../../../components/Alerts/Alert/alert";
import ProductModel from "../../../components/Modal/ProductViewModel/ProductViewModel";
import DeleteModal from "../../../components/Modal/DeleteModel/DeleteModal";
const cookies = new Cookies();
export default function SellerProduct() {
  const [showPage, setShowPage] = useState(false)
  const [showModel, setShowModel] = useState(false)
  const [text, setText] = useState();
  const [progress, setProgress] = useState(30)
  const axiosPrivate = useAxiosPrivate();
  const [productList, setProductList] = useState();
  const [productState, setProductState] = useState(true);
  const [itemName, setItemName] = useState('');
  const [itemType, setItemType] = useState('');
  const [itemId, setItemId] = useState();
  useEffect(() => {
    axiosPrivate.get("seller/seller_products_listing/",
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('access')}`
        },
        withCredentials: true
      }
    ).then(res => {
      console.log(res.data, 'products')
      setProductList(res.data)
      setProgress(100)
      setShowPage(true)
    }).catch(err => {
    })
  }, [productState, axiosPrivate])
  const showDeleteModel = (name, id) => {
    setItemName(name)
    setItemType('Product')
    setItemId(id)
    setShowModel(true)
  }
  const handleDeleteProduct = (id) => {
    axiosPrivate.delete(`seller/delete_seller_product/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.get('access')}`
        },
        withCredentials: true
      }
    ).then(res => {
      setShowModel(false)
      setProductState(prev => !prev)
    }).catch(err => {
      setText(err.code)
    })
  }
  const mobileViewData = productList?.map((elem, index) => {
    return (
      <>
        <div className="col-12" style={{ border: "0.15rem solid #17a2b8" }}>
          {/* <div className=" col-12 "></div> */}
          <div className='p-3 Myorders-pkg-1 shadow card' style={{ backgroundColor: "black" }}>
            {/* <div className='Track-order-pkg d-flex justify-content-between'>
                        <div className='d-flex flex-column'>
                            <span style={{ color: "#E86669", cursor: 'pointer' }}>Tracking Number : {elem?.seller_tracking_no}</span>
                            <small></small>
                        </div>
                    </div>

                    <hr /> */}

            <div>
              <div className="col-12">
                <p>Quantity: {elem.quantity}</p>
                <p>Date: {elem?.dateCreated}</p>
                {/* <p>{elem?.buyer_name}</p> */}
              </div>
              <div className='col-12 d-flex  p-lg-3 p-md-3 p-4 ps-lg-3 ps-md-3 ps-0 align-items-center'>
                <div className="col-8 d-flex">
                  <div className=" img-wrap" onClick={() => window.open(`/ProductDetails/${elem.url_link}`)}>
                    <Link to={""}><img src={elem?.image} alt='not found' /></Link>
                  </div>
                  &nbsp;
                  <div className="d-flex flex-column">
                    <Link to="" className="txt-txt">{elem?.name}
                    </Link>
                    <p className="mb-1">{elem?.price}</p>
                    <span style={{ color: elem.status === "PUBLISHED" ? "black" : "white" }} className={elem?.status === "PUBLISHED" ? "badge btn_status btn_Completed" : elem?.status === "CANCELLED" ? "badge btn_status btn-danger" : elem?.status === "INITIATED" ? "badge btn_status btn-warning" : "badge btn_status btn-dark"}>{elem?.status}</span>
                    <small className='warranty'><Link to={"/"}></Link></small>
                  </div>
                </div>


                <div className="col-4 d-flex justify-content-end">
                  <div className="text-center d-flex flex-column">
                    <button className='btn btn-outline-light btn-sm'><ProductModel productId={index} /></button>
                    <button className='btn btn-outline-success btn-sm mt-1'><ProductEditModel productId={index} /></button>
                    <button className="btn btn-outline-danger btn-sm mt-1"><i onClick={() => showDeleteModel(elem.name, elem.id)} className="fa-solid fa-trash-can"></i>
                    </button>
                  </div>

                  {/* <Dropdown>
                    <Dropdown.Toggle id="dropdown-button-basic" style={{ cursor: 'pointer', backgroundColor: "#E86669", fontSize: "1rem" }}>
                      Select Action
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='dropdown-menu' style={{ backgroundColor: "black" }}>
                      <Dropdown.Item className='myWishlist mb-1'
                        style={{
                          color: "white",
                        }}
                      >

                        <Button
                          variant="success"
                          style={{ width: "100%" }}
                        ><ProductModel productId={index} />View
                        </Button>

                      </Dropdown.Item>
                      <Dropdown.Item className='myWishlist mb-1'
                        style={{
                          color: "white",
                        }}>
                        <Button
                          variant="warning"
                          style={{ width: "100%" }}
                        ><ProductEditModel productId={index} />Edit
                        </Button>

                      </Dropdown.Item>
                      <Dropdown.Item className='myWishlist mb-1'>

                        
                        <Button
                          variant="danger"
                          style={{ width: "100%",color:"black" }}
                        > <i onClick={() => showDeleteModel(elem.name, elem.id)} className="fa-solid fa-trash-can " style={{color:"black"}}></i> Delete
                        </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    )
  })
  const userAttributes = []
  productList?.map((el, index) => {
    userAttributes.push({
      image: <span onClick={() => window.open(`/product-display-page-men/${el.url_link}`)}>
        <img src={el.image} style={{ width: "30px", height: "30px", cursor: "pointer" }} alt="not available" /></span>,
      name: <span style={{ cursor: "pointer" }} onClick={() => window.open(`/product-display-page-men/${el.url_link}`)}>{el.name}</span>,
      status: el.approveProduct === "APPROVED" ? el.status : el.approveProduct === "REJECTED" ? "REJECTED" : "FOR APPROVAL",
      qty: el.quantity,
      date: el.dateCreated,
      price: `SGD${el.price}`,
      action: <div className="text-center d-flex justify-content-around">
        <ProductModel productId={index} />
        <ProductEditModel productId={index} />
        <i onClick={() => showDeleteModel(el.name, el.id)} className="fa-solid fa-trash-can "></i></div>

    })
  });
  const data = {
    columns: [
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 100
      },
      {
        label: "Product Name",
        field: "name",
        sort: "asc",
        width: 150
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150
      },
      // {
      //   label: "Stock",
      //   field: "stock",
      //   sort: "asc",
      //   width: 270
      // },
      {
        label: "Qty.",
        field: "qty",
        sort: "asc",
        width: 200
      },
      {
        label: "Price",
        field: "price",
        sort: "asc",
        width: 100
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100
      }
    ],
    rows: userAttributes
  };

  return (
    <>
      <DeleteModal
        setShowModel={setShowModel}
        showModel={showModel}
        itemType={itemType}
        itemName={itemName}
        itemId={itemId}
        deleteFunction={handleDeleteProduct} />
      <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      {showPage === true && <>
        <DashboardHeader />
        <div className="container dash-table" id="Seller_product">
          <MyAlert text={text} setText={setText} />
          <div className="card shadow p-3" >
            <div className="card-header d-flex justify-content-between">
              <div>
                <h3>Products</h3>
              </div>

              <div className=''>
                <Link to={"/seller-view-product"} className="btn btn-view">Add new Product</Link>
              </div>


            </div>
            <div className="card-body px-3">
              {/* <MDBDataTable responsive bordered sortable data={data} /> */}
            </div>

          </div>
        </div>
        <div className="container mobile-view-track my-5">
          <div className="card-header d-flex justify-content-between mb-4">
            <div>
              <h3>Products</h3>
            </div>

            <div className=''>
              <Link to={"/seller-view-product"} className="btn btn-view">Add new Product</Link>
            </div>


          </div>
          {mobileViewData}
        </div>
      </>
      }

    </>
  )
}
