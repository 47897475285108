import React from 'react'
import Styles from './featuredBlog.module.scss';
import Avatar from '../../Avatar';
import moment from 'moment/moment';
import avatarImage from '../../../assets/images/png/avatar.png';


const FeaturedBlogItem = ({ blog, ...props }) => {
  const blogDate = moment(new Date(blog?.created_at)).format('MMMM DD, yyyy');
  return (
    <div className={Styles.item} {...props}>
      <img className={Styles.image} src={blog.thumbnail} alt={blog.title}/>
      <div className={Styles.descriptionContainer}>
        <div className={Styles.title}>{blog.title}</div>
        <div className={Styles.descriptionFooterContainer}>
          <div className={Styles.authorContainer}>
            {/* <Avatar src={blog.author?.image} profileId={blog.author?.id}  height={34} width={34}/> */}
            <Avatar src={avatarImage} profileId={blog.author?.id}  height={34} width={34}/>
            {/* <span className={Styles.authorName}>{blog.author?.name}</span> */}
            <span className={Styles.authorName}>FS Admin</span>
          </div>
          <span className={Styles.date}>{blogDate}</span>
        </div>
      </div>
    </div>
  )
}

export default FeaturedBlogItem