import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../../../../layout/Header/Header';
import Footer from '../../../../layout/Footer/Footer';
import "./ThankYou.css";

const ThankYou = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');

        if (status === 'canceled') {
            navigate('/payment');
        }
    }, [location, navigate]);

    return (
        <>
            {/* <Header /> */}
            <section id='thankyou'>
                <div className='thank-container'>
                    <div className='row px-5 mx-3'>
                        <h2>Thank your for your order!</h2>
                        <p>Your order has been placed! You’ll received an email for order notification!</p>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    );
}

export default ThankYou;
