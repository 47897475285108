import {
    SET_CONTACT_REF,
    CONTACT_REF,
    BLOGS,
    BUYER_SIDE,
    CATEGORY_LIST,
    CHECKOUT_INFO,
    COUNTRY_LIST,
    IS_LOGGED_IN,
    LANGUAGE,
    ORDERS_API_PARAMS,
    ORDER_ITEMS,
    PRODUCTS_API_PARAMS,
    PRODUCT_PAGE_OFFSET,
    REFRESH_CART,
    REFRESH_FAVORITES,
    REFRESH_ORDERS,
    REFRESH_PROFILE,
    SEARCH_QUERY,
    SEARCH_TYPE,
    SET_CART_ITEMS,
    SET_FAVORITE_ITEMS,
    SET_FEATURED_PRODUCTS,
    SET_FEATURED_SELLERS,
    SET_PRODUCTS,
    USER_INFO
} from "./ActionConstants";

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_CONTACT_REF:
            return { ...state, setContactRef: action.payload };
        case CONTACT_REF:
            return { ...state, contactRef: action.payload };
        case USER_INFO:
            return { ...state, user: action.payload };
        case LANGUAGE:
            return { ...state, language: action.payload };
        case SET_PRODUCTS:
            return { ...state, products: action.payload };
        case SET_FEATURED_PRODUCTS:
            return { ...state, featuredProducts: action.payload };
        case SET_FEATURED_SELLERS:
            return { ...state, featuredSellers: action.payload };
        case SET_CART_ITEMS:
            return { ...state, cartItems: action.payload };
        case SET_FAVORITE_ITEMS:
            return { ...state, favorites: action.payload };
        case BLOGS:
            return { ...state, blogs: action.payload };
        case IS_LOGGED_IN:
            return { ...state, isLoggedIn: action.payload };
        case COUNTRY_LIST:
            return { ...state, countryList: action.payload };
        case CATEGORY_LIST:
            return { ...state, categoryList: action.payload };
        case ORDER_ITEMS:
            return { ...state, orderItems: action.payload };
        case REFRESH_CART:
            return { ...state, refreshCart: action.payload };
        case REFRESH_ORDERS:
            return { ...state, refreshCart: action.payload };
        case REFRESH_PROFILE:
            return { ...state, refreshProfile: action.payload };
        case REFRESH_FAVORITES:
            return { ...state, refreshFavorites: action.payload };
        case SEARCH_TYPE:
            return { ...state, searchType: action.payload };
        case SEARCH_QUERY:
            return { ...state, searchQuery: action.payload };
        case PRODUCT_PAGE_OFFSET:
            return { ...state, productPageOffset: action.payload };
        case CHECKOUT_INFO:
            return { ...state, checkoutInfo: action.payload };
        case ORDERS_API_PARAMS:
            return { ...state, ordersApiParams: action.payload };
        case PRODUCTS_API_PARAMS:
            return { ...state, productsApiParams: action.payload };
        case BUYER_SIDE:
            return { ...state, buyerSide: action.payload }
        default:
            return state;
    }
};