import React from 'react'
import ArticlesCards from './subcomponents/ArticlesCards'

const Articles = () => {
    return (
        <div>
            <ArticlesCards />
        </div>
    )
}

export default Articles