import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import UserProfile from "../../../pages/seller/Profile/subcomponents/SellerEditProfile"

const EditProfileModel = ({ refresh, setRefresh }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setRefresh(!refresh)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    return (
        <>
            <button onClick={handleShow} className="btn btn-view">Edit Profile</button>
            <Modal show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Body style={{ background: "" }}>
                    <UserProfile
                        show={show}
                        onChange={value => setShow(value)}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}
export default EditProfileModel;