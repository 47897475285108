import React from 'react'
import Mainusername from './subcomponents/Mainusername'

const Username = () => {
    return (
        <>
            <Mainusername />
        </>
    )
}

export default Username