import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Header from '../../../layout/Header/Header';
import Footer from '../../../layout/Footer/Footer';
import "./SellerDashboard.css"
import { Link } from 'react-router-dom';
import image from "../../../assets/images/png/demo-img.png"
import { color } from '@mui/system';
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection'

const SellerDashboard = () => {
    return (
        <>
            {/* <Header /> */}
            <SearchAndGenderSection />

            <section id='small-cards'>
                <div className='row px-5 mx-3'>
                    <div className='col-lg-4'>
                        <a href='/' className="small-box bg-sale">
                            <div className="inner">
                                <h6 className='mb-4' style={{ color: "#F47274" }}>TOTAL SALE</h6>
                                <h2 className='mb-0'>S$1000.00</h2>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"></i>
                            </div>
                        </a>
                    </div>
                    <div className='col-lg-4'>
                        <a href='/' className="small-box bg-other">
                            <div className="inner">
                                <h6 className='mb-4' style={{ color: "#F47274" }}>TOTAL ORDERS</h6>
                                <h2 className='mb-0'>5</h2>
                            </div>
                            <div className="icon">
                                <i className="ion ion-ios-cart"></i>
                            </div>
                        </a>
                    </div>
                    <div className='col-lg-4'>
                        <a href='/' className="small-box bg-other">
                            <div className="inner">
                                <h6 className='mb-4' style={{ color: "#F47274" }}>CUSTOMERS</h6>
                                <h2 className='mb-0'>5</h2>
                            </div>
                            <div className="icon">
                                <i className="ion ion-ios-people"></i>
                            </div>
                        </a>
                    </div>
                </div>

            </section>

            <section id='sellerDashboard'>
                <div className='row px-5 mx-3'>
                    <div className='card mb-5'>
                        <div className='p-4'>
                            <h2 className='text-uppercase m-0'>Recent Orders</h2>
                        </div>

                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <div className="dropdown mx-4">
                                <button className="btn-status dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    CHANGE STATUS
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="#">INITITED</a></li>
                                    <li><a className="dropdown-item" href="#">READY_TO-DISPATCH</a></li>
                                    <li><a className="dropdown-item" href="#">COMPLETED</a></li>
                                </ul>
                            </div>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateRangePicker']}>
                                    <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
                                </DemoContainer>
                            </LocalizationProvider> */}
                            <form className="search mx-4" action="#">
                                <input type="text" className="search-input" placeholder="Search tracking #" id='searchText' name="searchKeyword" onkeypress="handle" />
                                <i className="fa fa-search"></i>
                            </form>
                        </div>

                        <table>
                            <thead>
                                <tr>
                                    <th scope="col" className='text-center'>
                                        <div className="form-check my-auto">
                                            <input className="form-check-input" type="checkbox" id="customCheck1" />
                                        </div>
                                    </th>
                                    <th scope="col" className='text-center'>Order #</th>
                                    <th scope="col" className='text-center'>IMG</th>
                                    <th scope="col" className='text-center'>SELLER TRACKING #</th>
                                    <th scope="col" className='text-center'>PRODUCT NAME</th>
                                    <th scope="col" className='text-center'>ORDER TOTAL</th>
                                    <th scope="col" className='text-center'>USERNAME</th>
                                    <th scope="col" className='text-center'>DATE & TIME</th>
                                    <th scope="col" className='text-center'>STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>
                                        <div className="form-check my-auto">
                                            <input className="form-check-input" type="checkbox" id="customCheck1" />
                                        </div>
                                    </td>
                                    <td className='text-center'>1</td>
                                    <td className='text-center'>
                                        <img className='img-fluid' src={image} alt='' style={{ width: "60px", height: "60px", borderRadius: "6px" }} />
                                    </td>
                                    <td className='text-center'>UBG20231SG</td>
                                    <td className='text-center'>Bra worn at 3 days</td>
                                    <td className='text-center'>S$80.00</td>
                                    <td className='text-center'>abcjdv</td>
                                    <td className='text-center'>19 Oct 2023 12:23</td>
                                    <td className='text-center'>
                                        <span className="badge badgeInit rounded-pill text-dark">INITIATED</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <div className="form-check my-auto">
                                            <input className="form-check-input" type="checkbox" id="customCheck1" />
                                        </div>
                                    </td>
                                    <td className='text-center'>2</td>
                                    <td className='text-center'>
                                        <img className='img-fluid' src={image} alt='' style={{ width: "60px", height: "60px", borderRadius: "6px" }} />
                                    </td>
                                    <td className='text-center'>UBG20231SG</td>
                                    <td className='text-center'>Bra worn at 3 days</td>
                                    <td className='text-center'>S$80.00</td>
                                    <td className='text-center'>abcjdv</td>
                                    <td className='text-center'>19 Oct 2023 12:23</td>
                                    <td className='text-center'>
                                        <span className="badge badgeDispatch rounded-pill text-dark">READT_TO_DISPATCH</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>
                                        <div className="form-check my-auto">
                                            <input className="form-check-input" type="checkbox" id="customCheck1" />
                                        </div>
                                    </td>
                                    <td className='text-center'>3</td>
                                    <td className='text-center'>
                                        <img className='img-fluid' src={image} alt='' style={{ width: "60px", height: "60px", borderRadius: "6px" }} />
                                    </td>
                                    <td className='text-center'>UBG20231SG</td>
                                    <td className='text-center'>Bra worn at 3 days</td>
                                    <td className='text-center'>S$80.00</td>
                                    <td className='text-center'>abcjdv</td>
                                    <td className='text-center'>19 Oct 2023 12:23</td>
                                    <td className='text-center'>
                                        <span className="badge badgeComp rounded-pill text-dark">COMPLETED</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    )
}

export default SellerDashboard