import SVG from 'react-inlinesvg';
import IconWeChat from '../../shared/assets/icons/weChat.svg';
import BaseIcon from './BaseIcon';

const WeChat = ({ color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconWeChat} {...iconProps} style={{ color: color}}/>
        </BaseIcon>
    )
}

export default WeChat;
