import Styles from './shared.module.scss'
import classNames from 'classnames';

const BaseIcon = ({ clickable, className, count = 0, children, iconProps, ...props}) => {
    return (
        <div className={classNames(Styles.container, className, {[Styles.clickable]: clickable})} {...props} >
            { (count > 0) &&
                <div className={Styles.countIndicator}>
                    <div className={Styles.indicatorInnerContainer}>
                        <div className={Styles.indicatorOverlay}></div>
                        <div className={Styles.indicatorCount}>{count}</div>
                    </div>
                </div>
            }
            {children}
        </div>
    );
}
export default BaseIcon;