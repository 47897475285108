import About from "../../pages/public/About/About";
import Articles from "../../pages/public/Articles/Articles";
import Faq from "../../pages/public/Faq/Faq";
import ViewArticles from "../../pages/public/ViewArticles/ViewArticles";

const newroutes = [
    {
        path: "/new/about",
        element: <About />
    },
    {
        path: "/new/faq",
        element: <Faq />
    },
    {
        path: "/new/articles",
        element: <Articles />
    },
    {
        path: "/new/view-articles/:uuid",
        element: <ViewArticles />
    },
]
export default newroutes;