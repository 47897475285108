import { useRef, useState, useEffect, useContext } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import axios from '../../../../../services/api/axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import SendEmailModal from '../../../../../components/Modal/SendEmailModal/SendEmailModal';
import logo from "../../../../../assets/images/png/Fantasy_Senses_logo.png";
import './Mainlogin.css'
import styles from './Mainlogin.module.css';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../../../store/GlobalContext';
const LOGIN_URL = '/auth/login/';
const cookies = new Cookies();
export default function Login() {
    const { t } = useTranslation('registration')
    const { dispatch } = useContext(GlobalContext)
    const { setAuth } = useAuth();
    const [loader, setLoader] = useState(false);
    const Navigate = useNavigate();
    const location = useLocation();
    const from = location?.state?.from?.pathname || "/";

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        let token
        try {
            const response = await axios.post(
                LOGIN_URL, { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    withCredentials: true,
                }
            )
            const accessToken = response?.data?.access;
            localStorage.setItem("access", JSON.stringify(response?.data?.access));
            window.localStorage.setItem("isLoggedIn", "true")
            // localStorage.setItem("name", "abdul");
            cookies.set('access', response?.data?.access);
            cookies.set('refresh', response?.data?.refresh);
            // const roles = response?.data?.roles;
            setAuth({ email, password, accessToken });
            setEmail('');
            setPassword('');
            setLoader(false)
            dispatch({
                type: "IS_LOGGED_IN",
                payload: true
            })
            Navigate(from, { replace: true });
        } catch (err) {
            setLoader(false)
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Username or password incorrect');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }
    return (
        <section id='login'>
            <SendEmailModal showModal={showModal} setShowModal={setShowModal} />
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                        <div className="card shadow text-white">
                            <div className="card-body p-lg-5 p-md-5 p-4">
                                <div className="mb-md-5">
                                    <div className='text-center'>
                                        <img src={logo} className="img-fluid" alt="Fantasy Senses" />
                                    </div>

                                    {/* <h2 className="fw-bold mb-2 text-uppercase" style={{ color: "#f6ecd0" }}>Login</h2> */}
                                    <p className="text-center text-white mt-3 mb-5">{t('p1')}</p>
                                    {errMsg && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{margin:0}}>{errMsg}</p>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>}
                                    <form className="form-outline form-white mb-5" onSubmit={handleSubmit}>
                                        <div>
                                            <input
                                                type="email"
                                                id="email"
                                                ref={emailRef}
                                                autoComplete="off"
                                                className="form-control "
                                                name="email"
                                                value={email}
                                                placeholder={t('placeholder1')}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div >
                                            <br />
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="password"
                                                value={password}
                                                id="password"
                                                placeholder={t('label2')}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <Link className='mt-5 float-end' onClick={() => setShowModal(true)} to={""}>{t('text2')}</Link>
                                        <div className="text-center mb-5 position">
                                            <button className={`${styles?.loginbutton} mb-3`} type="submit">
                                                {loader ? <Spinner animation="border" style={{ width: "1.4rem", height: "1.4rem" }} role="status">
                                                    <span className="visually-hidden">{t('text3')}</span>
                                                </Spinner> : t('text1')}
                                            </button>
                                        </div>
                                    </form>
                                    <p>
                                        {t('text4')}<br />
                                        <span className="line">
                                            <Link to={"/signup"}>{t('text5')}</Link>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}