import React from 'react';
import Styles from './avatar.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const initialStyles = [
    { color: 'white', backgroundColor: 'red' },
    { color: 'white', backgroundColor: 'blue' },
    { color: 'white', backgroundColor: 'green' },
    { color: 'white', backgroundColor: 'purple' },
    { color: 'white', backgroundColor: 'gray' },
    { color: 'white', backgroundColor: 'orange' },
    { color: 'white', backgroundColor: 'yellow' },
    { color: 'white', backgroundColor: 'maroon' },
    { color: 'white', backgroundColor: 'olive' },
    { color: 'white', backgroundColor: 'lime' },
    { color: 'white', backgroundColor: 'aqua' },
  ];
  

const Avatar = ({ src, round = true, square, height = 24 ,width = 24, initials = 'UN', profileId = 1, ...props}) => {
    const initialStyleIndex = profileId
    ? profileId % initialStyles.length
    : Math.floor(Math.random() * initialStyles.length - 1);

  return (
    <div className={classNames(Styles.container, {[Styles.round]: round}, {[Styles.square]: square})}
        style={{width: `${width}px`, height: `${height}px` }}>

        {(src && <img className={Styles.image} src={src} alt='No Image'/>)  || (
            <div className={Styles.initials} style={{ ...initialStyles[initialStyleIndex], fontSize: width / 3 }}>
                {initials}
            </div>
        )}

    </div>
  )
}

Avatar.propTypes = {
    src: PropTypes.string,
    initials: PropTypes.string,
    profileId: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number
};



export default Avatar