import React from 'react';
import Styles from './howItWorks.module.scss';

const HowItWorksItem = ({title,data}) => {
  return (
    <div className={Styles.item}>
        {title}
        {
          data.steps.map((step, index) => 
          <div className={Styles.step} key={index}>
            <i className="fa-solid fa-check" style={{marginRight: '10px'}}/> {step}
          </div>)
        }
        <button className='btn btn-dark' style={{width: '175px',height: '50px', marginTop: '30px', marginLeft: '35px'}}>{data.buttonLabel}</button>
    </div>
  )
}

export default HowItWorksItem