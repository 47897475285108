import React, { useContext } from "react";

import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { GlobalContext } from "../../store/GlobalContext";

const UserArea = () => {
  const { state: { user = {} } = {} } = useContext(GlobalContext);

  return (
    <div className="d-flex p-2">
      <Image
        src={user?.image}
        roundedCircle
        style={{ height: 50, width: 50 }}
      />
      <div className="d-flex flex-column px-2">
        <p className="m-0">{user?.fullName}</p>
        <p className="m-0 text-muted font-weight-light">{user?.email}</p>
      </div>
    </div>
  );
};

export default UserArea;
