import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "../context/AuthProvider";
import AuthGuard from "../components/AuthGuard";
import Main from "../components/Layout/Main";
import AppWrapper from "../AppWrapper";
import Missing from "../pages/public/Missing/Missing";
import Profile from "../pages/buyer/Profile/Profile";
import NewLandingPage from "../pages/public/LandingPage/StaticLandingPage";
import unProtectedRoutes from "../routers/Routes/unprotected";
import unProtectedRoutes2 from "../routers/Routes/unprotected2";
import protectedRoutes from "../routers/Routes/protected";
import otherRoutes from "../routers/Routes/newroutes";


const index = () => (
  <BrowserRouter>
    <AuthProvider>
      <AppWrapper>
        <Routes>
            {unProtectedRoutes2.map(r => (
              <Route key={r.path} path={r.path} element={r.element} />
            ))}
          <Route path={`/`} element={<Main />}>
            {unProtectedRoutes.map(r => (
              <Route key={r.path} path={r.path} element={r.element} />
            ))}
            <Route index element={<NewLandingPage />} />
            <Route element={<AuthGuard />}>
              {protectedRoutes.map(r => (
                <Route key={r.path} path={r.path} element={r.element} />
              ))}
              <Route path={`/me`} element={<Profile />} />
            </Route>
          </Route>

          {otherRoutes.map(r => (
            <Route key={r.path} path={r.path} element={r.element} />
          ))}

          <Route path="*" element={<Missing />} />
        </Routes>
      </AppWrapper>
    </AuthProvider>
  </BrowserRouter>
);

export default index;

