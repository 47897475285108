const bodyTypes = [
    { label: "Skinny", value: "SKINNY"},
    { label: "Average", value: "AVERAGE"},
    { label: "Curvy", value: "CURVY"},
    { label: "Athletic", value: "ATHLETIC"}
  ];

  const races = [
    { label: "Chinese", value: "CHINESE" },
    { label: "Taiwanese", value: "TAIWANESE" },
    { label: "Japanese", value: "JAPANESE" },
    { label: "Korean", value: "KOREAN" },
    { label: "Thailand", value: "THAILAND" },
    { label: "Malay", value: "MALAY" },
    { label: "Indian", value: "INDIAN" },
    { label: "Caucasian", value: "CAUCASIAN" },
    { label: "Eurasian", value: "EURASIAN" },
    { label: "Russian", value: "RUSSIAN" },
    { label: "Mixed", value: "MIXED" },
    { label: "Others", value: "OTHERS" }
  ];

  const ages = [
    { label: "21-25", value: "21-25" },
    { label: "26-30", value: "26-30" },
    { label: "31-35", value: "31-35" },
    { label: "36-40", value: "36-40" },
    { label: "41-45", value: "41-45" },
    { label: "46-50", value: "46-50" },
    { label: "50+", value: "50+" }
  ];

export { ages, races, bodyTypes}