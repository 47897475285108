import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { WeeklySalesData } from "./subcomponents/WeeklySalesData"
const SalesInvoice = () => {
    let { invoiceId } = useParams();
    const data = WeeklySalesData();
    const [showPage] = useState(true)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    function dateFormat(inputDate, format) {
        //parse the input date
        const date = new Date(inputDate);

        //extract the parts of the date
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        //replace the month
        format = format?.replace("MM", month.toString().padStart(2, "0"));

        //replace the year
        if (format.indexOf("yyyy") > -1) {
            format = format?.replace("yyyy", year.toString());
        } else if (format?.indexOf("yy") > -1) {
            format = format?.replace("yy", year.toString().substr(2, 2));
        }

        //replace the day
        format = format?.replace("dd", day.toString().padStart(2, "0"));

        return format;
    }
    const Invoice = data?.map((elem, index) => {
        if (index === (parseInt(invoiceId) - 1)) {
            return (
                <>
                    {showPage && <>
                        <div className="cont mt-5" id="printSection" ref={componentRef}>
                            <div className="top row d-flex">
                                <div className="top-left col-lg-7 col-md-7 col-12">
                                    <h3>Bill to</h3>
                                    <div className="split mt-4">
                                        <div className="split-right">UB GENESIS PTE LTD</div>

                                    </div>
                                </div>

                                <div className="top-right col-lg-5 col-md-5 col-12 mt-lg-0 mt-md-0 mt-4">
                                    <h2>Sales Invoice</h2>

                                    <div className="split">
                                        <div className="split-left">Weekly date :</div>
                                        <div className="split-right"> {dateFormat(elem?.startDate, 'dd-MM-yyyy')} to {dateFormat(elem?.endDate, 'dd-MM-yyyy')} </div>

                                    </div>

                                    {/* <div className="split">
                                        <div className="split-left">Name :</div>
                                        <div className="split-right"> {data?.buyer_name} </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="items table-responsive">
                                <table className="items-row table">
                                    <thead>
                                        <tr>
                                            {/* <th className="items-row-cell header title">Weekly Date</th> */}

                                            <th className="items-row-cell header title" style={{ width: "25%" }}>Order No</th>

                                            <th className="items-row-cell header unit-price" style={{ width: "25%" }}>Sales Amount</th>

                                            <th className="items-row-cell header vat-rate" style={{ width: "25%" }}>Admin Fee</th>

                                            <th className="items-row-cell header sum" style={{ width: "25%" }}>Payout Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <div className="items-row">

                                                <td className="items-row-cell quantity" style={{ width: "25%" }}> {elem?.results?.map((el, i) => {
                                                    return "#" + el?.order_id + `${elem?.results[i + 1] ? ", " : ""}`
                                                })} </td>

                                                <td className="items-row-cell title" style={{ width: "25%" }}> SGD {elem?.totalSales} </td>

                                                <td className="items-row-cell quantity" style={{ width: "25%" }}> SGD {elem?.adminFee} </td>

                                                <td className="items-row-cell unit-price" style={{ width: "25%" }}> SGD {elem?.payoutAmount} </td>

                                            </div>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div className="summary row">
                                <div className="summary-title">
                                    <h2>Summary</h2>
                                </div>
                                <div className="row">
                                    <div className="summary-left col-lg-8 col-md-7 col-12">
                                        <p><strong>Weekly Date: </strong> {elem?.startDate} to {elem?.endDate} </p>
                                    </div>

                                    <div className="summary-right col-lg-4 col-md-5 col-12 me-auto">

                                        <div className="split mb-1">
                                            <div className="split-left"><strong>PAYOUT&nbsp;AMOUNT</strong></div>
                                            <div className="split-right"><strong>SGD {elem?.payoutAmount} </strong></div>
                                        </div>
                                        <div className="split mb-1">
                                            <div className="split-left">ADMIN&nbsp;CHARGES</div>
                                            <div className="split-right">SGD {elem?.adminFee} </div>
                                        </div>
                                        <div className="split mb-1">
                                            <div className="split-left">SUBTOTAL</div>
                                            <div className="split-right">SGD {elem?.totalSales} </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary m-5 float-end" onClick={handlePrint}>print</button>
                        </div>
                    </>
                    }
                </>
            )
        }
        return null;
    })
    return (
        <>
            {Invoice}
        </>
    )
}

export default SalesInvoice;