import { StarFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { CloseButton, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Cookies from 'universal-cookie';
import axios from '../../../services/api/axios';
import { useTranslation } from 'react-i18next';
const cookies = new Cookies();

const AddReview = ({
    showModel, setShowModel, orderId, productId
}) => {
    const { t } = useTranslation('addreview')
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loader, setLoader] = useState(false)
    const handleClose = () => {
        setShowModel(false)
    };
    const handleSubmitReview = () => {
        setLoader(true)
        if (rating === 0 || review === '') {
            alert('Please add a rating and review before proceeding.');
            setLoader(false)
            return;
        }
        const body = {
            order: orderId, product: productId, rating, review
        }
        axios.post('buyer/review/', body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }).then(res => {
                if (res?.status === 200) {
                    setSubmitted(true)
                }
                setLoader(false)
                console.log(res, 'res')
            }).catch(err => {
                console.log(err, "err")
                setLoader(false)
            })
    }
    return (
        <>
            <Modal
                show={showModel}
                onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                className="ps-0"
                style={{
                    border: "none"
                }}
            >
                <Modal.Body className='p-4' style={{
                    backgroundColor: "white",
                    borderRadius: "0.4rem"
                }}>
                    {submitted ?
                        <div style={{ color: "black" }}>
                            <div className='text-right'>
                                <CloseButton
                                    onClick={handleClose}
                                    className='mt-1'
                                    variant="dark"
                                    style={{ color: "black", fontWeight: "bold", }}
                                />
                            </div>
                            <div className='text-center' style={{ marginTop: '5rem', marginBottom: '5rem' }}>
                                <h3>{t('heading2')}</h3>
                                <p style={{ margin: "2rem" }}>{t('p1')}</p>
                            </div>
                        </div>
                        :
                        <div style={{ margin: '2rem' }}>
                            <div className='d-flex justify-content-between'
                                style={{ color: "black" }}>
                                <h3 style={{ fontWeight: "bold" }}>{t('heading1')}</h3>
                                <CloseButton
                                    onClick={handleClose}
                                    className='mt-1'
                                    variant="dark"
                                    style={{ color: "black", fontWeight: "bold" }}
                                />
                            </div>
                            <div className='m-1 mt-4'>
                                <StarFilled style={{ color: rating >= 1 ? '#ffbe18' : 'grey', fontSize: "2rem" }} onClick={() => setRating(rating !== 1 ? 1 : 0)} />
                                <StarFilled style={{ color: rating >= 2 ? '#ffbe18' : 'grey', fontSize: "2rem" }} onClick={() => setRating(rating !== 2 ? 2 : 1)} />
                                <StarFilled style={{ color: rating >= 3 ? '#ffbe18' : 'grey', fontSize: "2rem" }} onClick={() => setRating(rating !== 3 ? 3 : 2)} />
                                <StarFilled style={{ color: rating >= 4 ? '#ffbe18' : 'grey', fontSize: "2rem" }} onClick={() => setRating(rating !== 4 ? 4 : 3)} />
                                <StarFilled style={{ color: rating >= 5 ? '#ffbe18' : 'grey', fontSize: "2rem" }} onClick={() => setRating(rating !== 5 ? 5 : 4)} />
                            </div>
                            <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder={t('placeholder1')}
                                    style={{ backgroundColor: "white", color: "black" }}
                                    onChange={(e) => setReview(e.target.value)}
                                />
                            </Form.Group>
                            <div style={{ color: 'black' }}>
                                <button
                                    onClick={handleSubmitReview}
                                    className='btn btn-danger px-4'
                                    style={{ borderRadius: "0.4rem" }}
                                >{loader ? <Spinner animation="border" style={{ width: "1.4rem", height: "1.4rem", color: 'blak' }} role="status" >
                                    <span className="visually-hidden">{t('text3')}</span>
                                </Spinner> : t('btn1')}
                                </button>
                                <span className='mx-2' style={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleClose}>{t('btn2')}</span>
                            </div>
                        </div>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddReview