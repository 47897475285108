import SVG from 'react-inlinesvg';
import IconHeart from '../../shared/assets/icons/heart.svg';
import BaseIcon from './BaseIcon';

const Heart = ({ fill, color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconHeart} {...iconProps} style={{ color: color, fill: fill }}/>
        </BaseIcon>
    )
}

export default Heart;
