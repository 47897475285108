import { AutoComplete } from 'antd';
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const CustomSearchComponent = ({
    searchQuery,
    setSearchQuery,
    searchType,
}) => {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [filteredSearch, setFilteredSearch] = useState([])
    useEffect(() => {
        axiosPrivate.get('search/',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            }
        ).then(res => {
            console.log(res?.data, 'daaaaya')
            const Data = res?.data?.map((item, index) => ({
                key: index,
                label: `${item.name}`,
                value: `${item.name}`,
                type: item.type,
            }));
            setOptions(Data)
        }).catch(err => {
        })
    }, [axiosPrivate])
    const onSearch = (searchText) => {

    };
    const onSelect = (data) => {
        setSearchQuery(data)
        setValue(data)
    };
    const onChange = (data) => {
        setSearchQuery(data)
        setValue(data);
    };
    useEffect(() => {
        if (value !== "") {
            setFilteredSearch(options?.filter(elem => elem?.label?.toLowerCase().includes(value.toLowerCase())))
        }
        else (
            setFilteredSearch(options)
        )
    }, [options, value])
    return (
        <>
            <AutoComplete
                options={filteredSearch?.filter(elem => {
                    if (searchType) {
                        return elem.type === "product"
                    } else {
                        return elem.type === "seller"
                    }
                })}
                style={{
                    width: '100%',
                    height: '44px',
                    backgroundColor: 'black',
                    fontSize: "16px",
                    color: 'white',
                    hoverBackgroundColor: "#060c22",
                    zIndex: '1000',
                    borderRadius: '10px'
                }}
                value={searchQuery}
                popupClassName="popupClassName"
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                placeholder="input here"
            />
            <br />
        </>
    );
};
export default CustomSearchComponent;