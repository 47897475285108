
import Username from '../../pages/public/Username/Username'
import Otp from '../../pages/public/Otp/Otp'
import Birthday from '../../pages/public/Birthday/Birthday'
import SignupPage from '../../pages/public/Signup/SignupPage'
import Login from '../../pages/public/Login/Login'
import ResetPassword from '../../pages/public/ResetPassword/ResetPassword'

const unProtectedRoutes2 = [

    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/signup",
        element: <SignupPage />
    },
    {
        path: "/user/reset-password/:token",
        element: <ResetPassword />
    },
    {
        path: "/otp",
        element: <Otp />
    },
    {
        path: "/username",
        element: <Username />
    },
    {
        path: "/user-details",
        element: <Birthday />
    }
  
]
export default unProtectedRoutes2;