import React, {  useState } from 'react'
import classNames from 'classnames';
import Styles from './featuredSeller.module.scss'
import { Link, useNavigate } from 'react-router-dom';

const FeaturedSellerItem = ({ className, seller, ...props }) => {
  const sellerImages = [ seller.image, seller?.product_image_1, seller?.product_image_2, seller?.product_image_3 , seller?.product_image_4];
  const [ activeImageIndex, setActiveImageIndex ] = useState(0)
  return (
      <div className={classNames(Styles.item)}>
          <div className={classNames(Styles.imageNavContainer)}>
            {
              sellerImages?.map((image, index) => (
                <span className={classNames(Styles.imageNavItem, { [Styles.active]: index === activeImageIndex })} key={index} onClick={() => setActiveImageIndex(index)}></span>
              ))
            }
          </div>

          <img className={Styles.image} src={sellerImages[activeImageIndex]} alt={seller.name}/>
          <div className={Styles.footerOverlay}></div>
          <div className={Styles.footerContainer}>
            <div className={Styles.footerHeader}>
                <div className={Styles.nameAge}>
                  { seller.userName } | { seller.age }
                </div>
                <div>
                  <i className='fa-solid fa-location-dot'></i> { seller?.country || 'Unspecified' }
                </div>
            </div>
            <p className={Styles.footerBody}>
              {seller?.sellerDescription}
            </p>
            <div className={Styles.footerAction}>
              <div className={Styles.starIconContainer}>
                <i className={classNames(Styles.starIcon, 'fa-regular fa-star')} />
                <span>{ seller?.rating || 0 }</span>
              </div>
              <Link to={`/seller/${seller.userName}`}>Visit Store</Link>
            </div>
          </div>
      </div>
  
  )
}

export default FeaturedSellerItem