import React from "react"
import "./Faqs.css";
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

const BuyerSellerFaq = () => {
    const { t } = useTranslation('faqs');
    return (
        <section id="faq">
            <div className="container-fluid my-3" data-aos="fade-up">
                <div className="row px-lg-5 px-sm-5 mx-3">
                    <div className="section-title text-center">
                        <h2 className='text-uppercase'>{t('heading1')}</h2>
                    </div>
                    <p className='text-center pb-4'>{t('text1')}</p>

                    {/* <!-- Pills --> */}

                    <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-12">

                            <ul className="nav nav-pills nav-justified mb-3">
                                <li className="nav-item">
                                    <Link className="nav-link active" id="nav-Buyer-tab" data-bs-toggle="tab" data-bs-target="#nav-Buyer" role="tab" aria-controls="nav-Buyer" aria-selected="true" to="#">{t('heading2')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" id="nav-Seller-tab" data-bs-toggle="tab" data-bs-target="#nav-Seller" role="tab" aria-controls="nav-Seller" aria-selected="true" to="#">{t('heading3')}</Link>
                                </li>
                            </ul>

                            <div className="tab-content " id="nav-tabcontent" >

                                {/* For Buyer */}
                                <div className="tab-pane fade show active" id="nav-Buyer" role="tabpanel" aria-labelledby="nav-Buyer-tab">
                                    <Accordion  >
                                        <Accordion.Item eventKey="0" >
                                            <Accordion.Header>{t('heading4')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[1, 2, 3, 4].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`squestion${elem}`)}</h6>
                                                        <p>{t(`sanswer${elem}`)}</p>
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        {/* Accordion-2 */}

                                        <Accordion.Item eventKey="1" className="mt-3">
                                            <Accordion.Header>{t('heading5')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[5, 6, 7, 8, 9].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`squestion${elem}`)}</h6>
                                                        <p>{t(`sanswer${elem}`)}</p>
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        {/* Accordion-3 */}

                                        <Accordion.Item eventKey="2" className="mt-3">
                                            <Accordion.Header>{t('heading6')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[10, 11].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`squestion${elem}`)}</h6>
                                                        <p>{t(`sanswer${elem}`)}</p>
                                                        <br />
                                                    </>
                                                ))}
                                                <div className="col-lg-6 mb-5">
                                                    <Table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th className="Table_head text-center text-uppercase">{t('tableheading1')}</th>
                                                                <th className="Table_head text-center text-uppercase">{t('tableheading2')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg_color">
                                                            <tr>
                                                                <td className="text-center">{t('text2')}</td>
                                                                <td className="text-center">{t('text4')}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-center">{t('text3')}</td>
                                                                <td className="text-center">{t('text5')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                {[12, 13].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`squestion${elem}`)}</h6>
                                                        <p>{t(`sanswer${elem}`)}</p>
                                                        <br />
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>



                                </div>

                                {/* For Seller */}

                                <div className="tab-pane fade show " id="nav-Seller" role="tabpanel" aria-labelledby="nav-Seller-tab">
                                    <Accordion >
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>{t('heading4')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[1, 2, 3, 4].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`bquestion${elem}`)}</h6>
                                                        <p>{t(`banswer${elem}`)}</p>
                                                        <br />
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        {/* Accordion-2 */}

                                        <Accordion.Item eventKey="4" className="mt-3">
                                            <Accordion.Header>{t('heading5')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[5, 6, 7].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`bquestion${elem}`)}</h6>
                                                        <p>{t(`banswer${elem}`)}</p>
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        {/* Accordion-3 */}

                                        <Accordion.Item eventKey="5" className="mt-3">
                                            <Accordion.Header>{t('heading6')}</Accordion.Header>
                                            <Accordion.Body className="Accor_head">
                                                {[8, 9].map(elem => (
                                                    <>
                                                        <h6 className="mb-2">{t(`bquestion${elem}`)}</h6>
                                                        <p>{t(`banswer${elem}`)}</p>
                                                    </>
                                                ))}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>



                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default BuyerSellerFaq;