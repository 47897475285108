import React from 'react'
import Hero from '../../../components/Sections/Hero'
import FeaturedSellers from '../../../components/Sections/FeaturedSellers/FeaturedSellers'
import Header from '../../../components/Layout/Header'
import FeaturedCategories from '../../../components/Sections/FeaturedCategories'
import FeaturedBlogs from '../../../components/Sections/FeaturedBlogs'
import FeaturedProducts from '../../../components/Sections/FeaturedProducts'
import HowItWorks from '../../../components/Sections/HowItWorks'
import Footer from '../../../components/Layout/Footer'
import StayInTouch from '../../../components/Sections/StayInTouch'

const StaticLandingPage = () => {
  return (
    <main>
        {/* <Header /> */}
        <Hero/>
        <FeaturedProducts />
        <FeaturedSellers />
        <HowItWorks/>
        <FeaturedCategories />
        <FeaturedBlogs />
        <StayInTouch />
        {/* <Footer /> */}
    </main>
  )
}

export default StaticLandingPage