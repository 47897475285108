import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom"
import { GlobalContext } from "../../../../../store/GlobalContext";
export default function Hero() {
    const { t } = useTranslation('landingpage');
    const { state } = useContext(GlobalContext);
    const navigate = useNavigate()
    return (
        <section id="hero">
            <div className="hero-container" data-aos="zoom-in" data-aos-delay="100">
                <div className="row container-fluid hero-inner">
                    <div className="col-lg-6 text-left py-5 ps-lg-5 ps-sm-0">
                        <h3 className="mb-lg-4 mb-sm-2 pb-0 text-dark">
                            <span className="text-dark">{t('text1')}</span>
                            &nbsp;&&nbsp;
                            <span>{t('text2')}</span>
                        </h3>
                        <h1 className="mb-2 pb-0">{t('heading1')}</h1>
                        <h1 className="mb-4 pb-0 text-dark">{t('heading2')}</h1>
                        <p className="mb-3 pb-0">{t('p1')}</p>
                        <p className="mb-4 pb-0"><b>{t('text3')}</b></p>
                        <div className="d-flex align-items-center">
                            <Link to="/product-page-men" className="about-btn text-uppercase">{t('text1')}</Link>
                            <p className="mb-0 mx-4 text-secondary" style={{paddingLeft:'7px'}}>{t('text4')}</p>
                            <Link to='/dashboard' className="about-btn text-uppercase">{t('text2')}</Link>
                        </div>

                    </div>
                    <div className="col-lg-6" />
                </div>
            </div>
        </section>




    )
}