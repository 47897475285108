import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import LoadingBar from 'react-top-loading-bar'
import MyAlert from '../../../../components/Alerts/Alert/alert'
import { GlobalContext } from '../../../../store/GlobalContext';
import { AddToCart } from '../../../../utils/cart/cartUtils';
import { AddToFavorite } from '../../../../utils/favorite/favorite';
import SearchNavbar from '../../../../components/SearchNavbar/NewSearchNavbar';
import axios from '../../../../services/api/axios';
import SearchAndGenderSection from '../../../../components/SearchAndGenderSection/SearchAndGenderSection';
const cookies = new Cookies();

const ProductDetailsMain = () => {
  const { id } = useParams();
  const { state } = useContext(GlobalContext);
  const [product, setProduct] = useState({});
  const [showPage, setShowPage] = useState(false)
  const [progress, setProgress] = useState(30)
  const [related, setRelated] = useState([])
  const navigate = useNavigate()
  // const [toggle, setToggle] = useState(false)
  const [text, setText] = useState('');


  useEffect(() => {
    axios.get(`product-detail/related-products/?product=${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('access')}`,
        'Accept-Language': state.language
      },
      withCredentials: true
    }).then(res => {
      setShowPage(true);
      setProgress(100);
      setProduct(res?.data?.product)
      setRelated(res?.data?.related_products)

    }).catch(err => {
      console.log(err)
    })
  }, [id])
  console.log('PRODUCT----', product)

  const [expanded, setExpanded] = useState(false);
  const maxLength = 80;

  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
    if (carouselRef.current) {
      const carousel = new window.bootstrap.Carousel(carouselRef.current);
      carousel.to(index);
    }
  };

  const defaultImageUrl = "https://server.fantasysenses.com/media/default.jpg";
  const imageStyle = { width: "420px", height: "520px" };
  const thumbnailStyle = {
    marginBottom: '10px',
    borderRadius: '8px',
    border: '2px solid #f47493',
    height: '123px',
    objectFit: 'contain',
    cursor: 'pointer',
  };
  
  const images = [
    product.image,
    product.extra_image1 !== defaultImageUrl && product.extra_image1,
    product.extra_image2 !== defaultImageUrl && product.extra_image2,
    product.extra_image3 !== defaultImageUrl && product.extra_image3,
  ].filter(Boolean); // Filter out any false values

  useEffect(() => {
    if (carouselRef.current) {
      const carousel = new window.bootstrap.Carousel(carouselRef.current, {
        interval: 100000, // Set the interval to 10 seconds
      });
      carousel.to(activeIndex);
    }
  }, [activeIndex]);
  return (
    <div>
      <LoadingBar color="#f11946" progress={progress} onLoaderFinished={() => setProgress(0)} />
      {showPage === true && <>
        {/*<!-- ======= Product Details Sectionn ======= -->*/}
        <section id="speakers-details">
          <div className="container-fluid">
            <MyAlert text={text} setText={setText} />
            <SearchAndGenderSection />
            <Breadcrumb className='d-flex justify-content-start align-items-center bread-container px-lg-5 mx-3 my-5'>
              <Breadcrumb.Item onClick={() => navigate(product?.gender === "MALE" ? "/product-page-men" : "/product-page-women")}>
                SHOP
              </Breadcrumb.Item>
              <Breadcrumb.Item className='Bread-product'
                onClick={() => navigate(product?.gender === "MALE" ? "/product-page-men" : "/product-page-women")}
              >I’m interested in {`${product?.gender === "FEMALE" ? "Womens" : "Mens"}`} Clothes</Breadcrumb.Item>
              <Breadcrumb.Item active className='Bread-product'>{product?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row justify-content-between px-lg-5 mx-3 mb-5">
            <div className="col-lg-6 col-md-10 col-10 Product_img">
        <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel" ref={carouselRef}>
          <div className="carousel-indicators">
            {images.map((_, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === activeIndex ? 'active' : ''}
                aria-current={index === activeIndex ? 'true' : 'false'}
                aria-label={`Slide ${index + 1}`}
                onClick={() => handleThumbnailClick(index)}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {images.map((image, index) => (
              <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                <img src={image} className="d-block w-100" alt={`Product image ${index + 1}`} style={imageStyle} />
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" onClick={() => setActiveIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)}>
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" onClick={() => setActiveIndex((prevIndex) => (prevIndex + 1) % images.length)}>
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div className='col-lg-1 col-md-2 col-sm-12 bg-white description-images'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            className="d-block w-100"
            alt={`Thumbnail image ${index + 1}`}
            style={thumbnailStyle}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
              <div className="col-lg-5 col-md-10  col-12 mx-auto mt-md-3 mb-3 mt-4">
                <div className="details mt-lg-3 mt-md-3 mt-2">
                  <h2 style={{ marginBottom: "8px" }}>{product.name}</h2>
                  <h4><strong>SGD{product.price}</strong></h4>
                  <strong style={{ color: '#E86669', cursor: "pointer" }} className="mb-5 seller-product-page"><Link to={`/seller/${product?.seller_name}`}>{product?.seller_name}{product?.isVerified && <i className="far fa-check-circle mb-4"></i>}</Link></strong>
                  <p>{product.description}</p>
                  <div className="row">
                  <div className='d-flex align-items-center mb-2'>
                      {product?.quantity > 0 ? <AddToCart id={product?.id} /> : <button className='addToCart' disabled>OUT OF STOCK</button>}
                      <AddToFavorite id={product?.id} />
                  </div>
                  </div>
                </div >
              </div >
            </div >
          </div >
        </section >
        {related?.length > 0 &&
          <section id="feature-prod" className="feature-prod py-5">
            <div className="container-fluid mt-3" data-aos="fade-up">
              <div className="section-title text-center">
                <h2 className='text-dark text-uppercase mb-0'>Other Products</h2>
              </div>
              <div className="row px-lg-5 mx-3">
                {related?.map(elem => {
                  return (
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                      <div className="member" data-aos="fade-up" data-aos-delay="100">
                        <Link to={`/product-display-page-men/${elem?.url_link}`} >
                          <div className="member-img" >
                            <img src={elem?.image} className="img-fluid image-item" alt="" />
                          </div>
                        </Link>
                        <div className="member-info" >
                          <Link to={`/product-display-page-men/${elem?.url_link}`}>

                            <h5 className='mb-0'>{elem?.name}</h5>
                            <h4>S${elem?.price}</h4>
                            <div className="description">
                              <p>{expanded ? elem?.description : elem?.description?.length > maxLength ? `${elem?.description?.slice(0, maxLength)}... ` : elem?.description}
                                {elem?.description?.length > maxLength && (
                                  <button className="toggle-btn" onClick={toggleDescription} style={{ background: "none", border: "none", color: "#F47274", padding: "0", width: "auto", fontSize: "1vw" }}>
                                    {expanded ? 'Read less' : 'Read more'}
                                  </button>
                                )}
                              </p>
                            </div>
                          </Link>
                          <div className='d-flex align-items-center mb-2'>
                            {elem?.quantity > 0 ? <AddToCart id={elem?.id} /> : <button className='addToCart' disabled>OUT OF STOCK</button>}
                            <AddToFavorite id={elem?.id} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        }
      </>
      }
    </div >
  )
}

export default ProductDetailsMain;