import axios from "../api/axios";



const fetchFeaturedProducts = async (language) => {
    try {
        const response = await axios.get('buyer/landing-page/featured-products/?limit=4',
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data = await response?.data?.products;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
    }
};

const fetchFeaturedSellers = async (language) => {
    try {
        const response = await axios.get('buyer/landing-page/featured-sellers/',
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data = await response?.data?.sellers;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
        throw error;
    }
};

const fetchBlogs = async (language) => {
    try {
        const response = await axios.get('view-blogs/?limit=3',
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data = await response?.data?.blogs;
        return data;
    } catch (error) {
        console.error('Error fetching products:', error.message);
        throw error;
    }
}
export { fetchFeaturedProducts, fetchFeaturedSellers, fetchBlogs };