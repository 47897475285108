import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Alert, CloseButton, Modal } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Loader from '../../Loaders/Loader/loader';
import { GlobalContext } from '../../../store/GlobalContext';
const cookies = new Cookies();
const Cart = ({
    showModel, setShowModel, paynowData
}) => {
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const cartData = state.cartItems
    const [bool, setBool] = useState(false);
    const [loaderId, setLoaderId] = useState('')
    const UpdateCart = async (id, qty, add) => {
        setLoaderId(id)
        let body;
        if (add == 1) {
            body = {
                quantity: qty + 1
            }
        } else {
            body = {
                quantity: qty - 1
            }
        }
        await axiosPrivate.patch(`product/update_cart_item/${id}/`, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            })
            .then(res => {
                setBool(!bool)
                setLoaderId("")
            }).catch(err => {
            })
    }
    const RemoveFromCart = async (id) => {
        setLoaderId(id)
        await axiosPrivate.delete(`product/remove_from_cart/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${cookies.get('access')}`
                },
                withCredentials: true
            })
            .then(res => {
                setBool(!bool)
                setLoaderId("")
                dispatch({
                    type: "REFRESH_CART",
                    payload: !state.refreshCart
                })
            }).catch(err => {
            })
    }
    const RemoveCartFromSellerId = async (id) => {
        await axiosPrivate.delete(`buyer/remove-cart-items/group/?seller_id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${cookies.get('access')}`
            },
            withCredentials: true
        }).then(res => {
            dispatch({
                type: "REFRESH_CART",
                payload: !state.refreshCart
            })
        }).catch(err => {
            console.log(err)
        })
    }

    const handleClose = () => {
        setShowModel(false)
    };

    return (
        <>
            <Modal show={showModel} onHide={handleClose}
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                className="ps-0"
            >
                <Modal.Body
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        borderRadius: "0.3rem",
                        padding: "3rem",
                    }}
                >
                    {cartData?.length > 0 ? <>{cartData?.map((elem) => (
                        <div style={{ border: "0.1rem solid #F47274", borderRadius: "0.5rem", marginBottom: '25px' }}>
                            <div className='d-flex justify-content-between'
                                style={{ backgroundColor: "#F47274", padding: "0.5rem", borderTopLeftRadius: "0.4rem", borderTopRightRadius: "0.4rem" }}>

                                <span style={{ color: "white", marginLeft: "1rem" }}>{elem?.seller_name}{elem.is_verified && (<i className="fa-solid fa-circle-check fa-lg p-3"></i>)} </span>

                                <CloseButton style={{ color: "white" }} onClick={() => RemoveCartFromSellerId(elem?.seller_id)} />
                            </div>
                            {elem?.items?.map((obj) => (
                                <div style={{ margin: "1rem" }}>
                                    <div>
                                        <div className=" col-12 Products-container d-flex justify-content-between  ">
                                            <div className="col-lg-8 col-md-8 col-6 Product-name d-flex">
                                                <div className=" img-wrap" >
                                                    <Link to={`/product-display-page-men/${obj.url_link}`}><img style={{ borderRadius: "0.7rem", objectFit: 'cover' }} src={obj.image} alt='not found' /></Link>
                                                </div>
                                                <div className="content flex-column ms-2">
                                                    <label className="para-txt" style={{ color: "black" }}>
                                                        {obj.product_name}
                                                    </label>
                                                    <div className="Quantity-automation  mb-3">
                                                        <span className="item-quantity-prefix" style={{}}>Qty:</span>
                                                        <span className="item-quantity-value">{obj?.quantity}</span>
                                                    </div>
                                                    <div>
                                                        <p className="">S$ {parseFloat(obj.price).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-4 Product-price text-center pt-2">
                                                <p className="operations del">
                                                    <i style={{ marginTop: "2rem" }} onClick={() => { RemoveFromCart(obj.cart_id) }} className={loaderId ? "visually-hidden" : "fa-solid fa-trash-can"}></i>
                                                    {loaderId === obj.cart_id && <Loader />}
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    ))}
                        <div className='text-center'>
                            <button className='btn btn-primary my-5 px-4 py-2' style={{ borderRadius: '2rem' }} onClick={() => navigate('/checkout')}>CHECKOUT</button>
                        </div> </>
                        : <>
                            <span>
                                <Alert variant="" style={{ color: "black", backgroundColor: "#f6ecd0" }} className='mt-4 '>
                                    <h6 className="mb-0 p-3" style={{ fontWeight: "500" }}>
                                        No Items In Cart.
                                    </h6>
                                </Alert>
                            </span>
                        </>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Cart
