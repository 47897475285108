import React from 'react'
import Styles from './stayInTouch.module.scss';
import SectionContainer from '..';
import Newsletter from '../Newsletter';

const StayInTouch = () => {
  return (
    <SectionContainer className={Styles.container}>
        <div className={Styles.infoContainer}>
            <div className={Styles.info}>
                Free shipping  & returns
            </div>
            <div className={Styles.info}>
                30-day return policy
            </div>
            <div className={Styles.info}>
                100% Safe & Anonymous
            </div>
        </div>
        <Newsletter />
    </SectionContainer>
  )
}

export default StayInTouch