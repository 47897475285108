import React, { useEffect, useState } from 'react'
import Styles from './featuredBlog.module.scss'
import FeaturedBlogItem from './FeaturedBlogItem';
import SectionContainer from '..';
import axios from '../../../services/api/axios';
import { useNavigate } from 'react-router-dom';


const FeaturedBlogs = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([])
   useEffect(() => {
    axios.get('view-blogs/?limit=5')
      .then(res => {
          setBlogs(res?.data?.blogs)
      }).catch(err => {
          console.log(err)
      })
  }, [])

  const onBlogClicked = (blog) => {
    navigate(`/new/view-articles/${blog?.uuid}`)
  }

  return (
    <SectionContainer style={{ background: '#F1F1F1' }} >
      <div className='row mb-5'>
        <h3 className={Styles.sectionTitle}>Featured <span>Blogs</span></h3> 
        <span className={Styles.sectionSubTitle}>Discover articles on the latest trends in intimate wear, the psychology behind senspory experiences and tips on how to elevate your personal fantasy life.</span>
      </div>
      <div className={Styles.blogsContainer}>
        {
          blogs.map((blog, index) => 
          <FeaturedBlogItem key={index} blog={blog} onClick={() => onBlogClicked(blog)} />)
        }
      </div>
      <div className="row align-items-center justify-content-center mb-2 mt-5">
        <button className="btn btn-dark " style={{ width: '180px', height: '70px', fontSize: '1.5rem' }} onClick={() => navigate('/new/articles')}>See More</button>
      </div>
    </SectionContainer>
  )
}

export default FeaturedBlogs