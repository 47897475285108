import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import { MdOutlineArrowLeft } from "react-icons/md";

import { MdOutlineArrowRight } from "react-icons/md";
import ReactPaginate from 'react-paginate';


const Filters = ({
    ageOptions,
    sizeOptions,
    countryOptions,
    categoryOptions,
    raceOptions,
    location,
    btn,
    genderbtn,
    ageOnChange,
    sizeOnChange,
    countryOnChange,
    categoryOnChange,
    raceOnChange,
    pageCount,
    handlePageClick,
    itemOffset,
    pageOffset

}) => {
    const { t } = useTranslation('product')
    return (
        <div className="row m-0">
            <div className="col-lg-12 align-items-center mb-5 p-0 " role="group" style={{ borderBottom: "2px solid #cdcdcd", textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="filter-row">
                    <h6 className="text-upperrcase text-dark mb-0">{t('heading1')} </h6>
                    <div className="btn-group">
                        <select id="ageFilter"
                            className="btn-btn dropdown-toggle"
                            // style={{ background: "#040919" }}
                            aria-expanded="false"
                            onChange={(e) => {
                                ageOnChange(e.target.value)
                            }}
                        >
                            {ageOptions.map(
                                (obj, index) => (
                                    <option key={index} value={obj.value}>{obj.label}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="btn-group">
                        <select
                            className="btn-btn dropdown-toggle"
                            // style={{ background: "#040919" }}
                            aria-expanded="false"
                            onChange={(e) => {
                                sizeOnChange(e.target.value)
                            }}>
                            {sizeOptions.map(
                                (obj, index) => (
                                    <option key={index} value={obj.value}>{obj.label}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="btn-group">
                        <select
                            type="button"
                            className="btn-btn dropdown-toggle"
                            // style={{ background: "#040919" }}
                            aria-expanded="false"
                            onChange={(e) => {
                                countryOnChange(e.target.value)
                            }}
                        >
                            <option value="" className="dropdown-list">COUNTRY
                            </option>
                            {countryOptions?.map(
                                (obj, index) => (
                                    <option key={index} value={obj.value}
                                    // className="dropdown-list" 
                                    // style={{ backgroundColor: "#060c22" }}
                                    >{obj.label}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="btn-group">
                        <select
                            type="button"
                            className="btn-btn dropdown-toggle"
                            // style={{ background: "#040919" }}
                            aria-expanded="false"
                            onChange={(e) => {
                                categoryOnChange(e.target.value)
                            }}>
                            <option value="">{t('filter4')}
                            </option>
                            {categoryOptions?.map(
                                (obj, index) => (
                                    <option key={index} value={obj.value}>{obj.label}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                    <div className="btn-group">
                        <select
                            type="button"
                            className="btn-btn dropdown-toggle"
                            // style={{ background: "#040919" }}
                            aria-expanded="false"
                            onChange={(e) => {
                                raceOnChange(e.target.value)
                            }}>
                            {raceOptions?.map(
                                (obj, index) => (
                                    <option key={index} value={obj.value}>{obj.label}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                </div>
                {/* <Link to={location} className={btn}>{genderbtn}</Link> */}
                <div className="filter-row-paginate" style={{ fontSize: '15px' }}>
                    <MdOutlineArrowLeft size={30} />
                    PAGE {pageOffset == 0 ? 1 : pageOffset} OF {pageCount}
                    <MdOutlineArrowRight onClick={() => {
                        if (pageCount === 1) {
                            return;
                        }
                        handlePageClick()
                    }} size={30} />
                    {/* <div className="row"><div className="col-4"></div><div className="col-4">
                        <ReactPaginate
                            activeClassName="active"
                            nextLinkClassName="page-link"
                            nextClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            previousLabel="previous"
                            nextLabel="next"
                            className=""
                            breakLabel="..."
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            forcePage={pageOffset}
                        /></div><div className="col-4"></div></div> */}

                </div>
            </div>

        </div>

    )
}
export default Filters;