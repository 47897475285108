import React, { useContext } from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Cookies from "universal-cookie";
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../store/GlobalContext';
import './favorite.css'
import { useNavigate } from 'react-router-dom';
import axios from '../../services/api/axios';
const cookies = new Cookies();

export const AddToFavorite = ({ id }) => {
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation('landingpage');
    const { state, dispatch } = useContext(GlobalContext)
    const navigate = useNavigate();

    const mystyle = {
        // verticalAlign: "middle",
        fontSize: "1.8vw",
        // padding: "6px",
        // borderRadius: "50%", 
        color: "#E86669",

    };
    const HandleAddToFavourite = async () => {
        console.log("ki hoya")
        try {
            const response = await axios.post(`product/add_to_favourite/${id}/`, {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({
                    type: "REFRESH_FAVORITES",
                    payload: !state.refreshFavorites
                })
            }
        } catch (err) {
        }
    }
    const HandleRemoveFromFavourite = async () => {
        let wishlist_id;
        for (let i = 0; i < state.favorites?.length; i++) {
            if (id === state.favorites[i]?.product_id) {
                wishlist_id = state.favorites[i]?.wishlist_id;
            }
        }
        try {
            const response = await axios.delete(`product/remove_from_favourite/${wishlist_id}/`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            );
            if (response?.status === 200) {
                dispatch({
                    type: "REFRESH_FAVORITES",
                    payload: !state.refreshFavorites
                })
            }
        } catch (err) {
        }
    }
    function isProductInFavorite() {
        const array = state.favorites
        for (let i = 0; i < array?.length; i++) {
            if (array[i].product_id === id) {
                return true;
            }
        }
        return false;
    }
    return (
        // <button className='addToCart' onClick={(state.isLoggedIn === "true" || state.isLoggedIn === true) ? handleAddToCart : () => navigate('/login')} disabled={isProductInCart()}>{t('text7')}</button>
        <>
            {
                isProductInFavorite() ?
                    <i className="fa fa-heart favIcon" aria-hidden="true" onClick={HandleRemoveFromFavourite}></i>
                    :
                    <i
                        onClick={HandleAddToFavourite}
                        className="fa-regular fa-heart favIcon"
                        aria-hidden="true"
                    ></i>
            }
        </>
    );
};


