import React from 'react'
import Styles from './hero.module.scss'
import heroImage from '../../../shared/assets/images/hero.png'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <section className={Styles.heroContainer}>
      <img className={Styles.image} src={heroImage} alt="" />
      <div className={Styles.innerContainer}>
        <div className={Styles.titleDescriptionContainer}>
          <h4 className={Styles.sectionTitle}>Where all your <span>magic comes true</span></h4>
          <p>The world's first discreet online marketplace for scented undergarments. 
          100% safe and anonymous.</p>

          <div className={Styles.actionContainer}>
            <Link to='/product-page-men'>
              <button className="btn btn-dark btn-lg" style={{ width: '140px' }}>SHOP NOW</button>
            </Link>
            <Link to='/dashboard'>
              <button className="btn btn-light btn-lg" style={{ width: '140px' }}>SELL NOW</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero