import React, { useContext, useEffect, useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers-pro';
// import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import Header from "../../../layout/Header/Header";
import Footer from "../../../layout/Footer/Footer";
import "./SellerTotalSales.css";
import { Link } from "react-router-dom";
import image from "../../../assets/images/png/demo-img.png";
import { color } from "@mui/system";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Modal from "react-bootstrap/Modal";
import { CloseButton } from "react-bootstrap";
import Cookies from "universal-cookie";
import { GlobalContext } from "../../../store/GlobalContext";
import SearchAndGenderSection from "../../../components/SearchAndGenderSection/SearchAndGenderSection";
import { useTranslation } from "react-i18next";
import axios from "axios";
const cookies = new Cookies();

const SellerTotalSales = () => {
  const { t } = useTranslation("sellerSales");
  const axiosPrivate = useAxiosPrivate();
  const { state, dispatch } = useContext(GlobalContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sales, setSales] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(0);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [afterModel, setAfterModel] = useState(false);
  const [text, setText] = useState("");
  console.log("STATE.LANGUAGE---", state.language);
  const [selectedRows, setSelectedRows] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleLinkClick = (index) => {
    setActiveLink(index);
  };
  const payoutFunc = async (body) => {
    if (activeLink == 0) {
      if (!name || !phone) {
        return alert("All fields must be filled");
      }
      await axiosPrivate
        .post(`seller/create_payout/`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
            "Accept-Language": state.language,
          },
          withCredentials: true,
        })
        .then((response) => {
          setText(response.data.detail);
          setModalOpen(false);
          setAfterModel(true);
          setName("");
          setUserName("");
          setPhone("");
          // alert(response.detail)
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (activeLink == 1) {
      if (!bankAccountName || !bankName || !bankAccountNumber || !name) {
        return alert("All fields must be filled");
      }
      await axiosPrivate
        .post(`seller/create_payout/`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
            "Accept-Language": state.language,
          },
          withCredentials: true,
        })
        .then((response) => {
          setText(response.data.detail);
          setModalOpen(false);
          setAfterModel(true);
          // alert(response.detail)
          setName("");
          setUserName("");
          setBankName("");
          setBankAccountName("");
          setBankAccountNumber("");
        });
    }
    return;
  };
  useEffect(() => {
    setName(state.user.fullName);
  }, [state.user]);
  useEffect(() => {
    const initialReq = async () => {
      await axiosPrivate
        .get(`seller/sales/?search_value=&start_date=&end_date=`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
            "Accept-Language": state.language,
          },
          withCredentials: true,
        })
        .then((response) => {
          setSales(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    initialReq();
  }, [afterModel]);
  const getSellerSales = async () => {
    const response = await axiosPrivate.get(
      `seller/sales/?search_value=&start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("access")}`,
          "Accept-Language": state.language,
        },
        withCredentials: true,
      }
    );
    setSales(response.data);
  };
  const handleCheckboxChange = (orderId) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = { ...prevSelectedRows };
      if (newSelectedRows[orderId]) {
        delete newSelectedRows[orderId];
      } else {
        newSelectedRows[orderId] = sales.recent_orders.find(
          (order) => order.orders[0] === orderId
        ).orders;
        setOrders(newSelectedRows[orderId] || []);
      }
      return newSelectedRows;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allRows = {};
      sales.recent_orders.forEach((order) => {
        allRows[order.orders[0]] = order.orders;
      });
      setSelectedRows(allRows);
      const allOrders = [];
      sales.recent_orders.forEach((order) => {
        allOrders.push(...order.orders);
      });
      setOrders(allOrders);
    } else {
      setSelectedRows({});
    }
  };
  const downloadInvoice = () => {
    axiosPrivate
      .post(
        "seller/download/invoice/",
        {
          orders: orders,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header />
      <section id="search-header">
        <div className="row  mx-3">
          <SearchAndGenderSection />
        </div>
      </section>

      <section id="small-cards">
        <div className="row px-lg-5 px-sm-5 mx-3">
          <div className="col-lg-3">
            <div className="small-box bg-other">
              <div className="inner">
                <h5 className="mb-1" style={{ color: "#F47274" }}>
                  {t("heading1")}
                </h5>
                <h6
                  className="mb-1"
                  style={{ color: "#F47274", fontSize: "0.8rem" }}
                >
                  ({sales?.weekly_total_sale[0] || 0})
                </h6>
                <h2 className="mb-0">S${sales?.weekly_total_sale[1] || 0}</h2>
              </div>
              {/* <div className="icon">
                                <i className="ion ion-stats-bars"></i>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="small-box bg-other">
              <div className="inner">
                <h5 className="mb-4" style={{ color: "#F47274" }}>
                  {t("heading2")}
                </h5>
                <h2 className="mb-0">S${sales?.monthly_sales || 0}</h2>
              </div>
              {/* <div className="icon">
                                <i className="ion ion-ios-cart"></i>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="small-box bg-other">
              <div className="inner">
                <h5 className="mb-4" style={{ color: "#F47274" }}>
                  {t("heading3")}
                </h5>
                <h2 className="mb-0">S${sales?.lifetime_sales || 0}</h2>
              </div>
              {/* <div className="icon">
                                <i className="ion ion-ios-people"></i>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-3">
            <div className="small-box bg-other">
              <div className="inner">
                <h5 className="mb-4" style={{ color: "#F47274" }}>
                  {t("heading4")}
                </h5>
                <h2 className="mb-0">S${sales?.total_payout || 0}</h2>
              </div>
              {/* <div className="icon">
                                <i className="ion ion-ios-people"></i>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
      <section id="SellerSales">
        <div className="row px-lg-5 px-sm-5 mx-3">
          <div className="card mb-4">
            <div className="p-4">
              <h2 className="text-uppercase m-0">Recent Orders</h2>
            </div>

            <div
              className="d-flex align-items-center justify-content-between mb-3"
              style={{ width: window.screen.width < 500 && "1254px" }}
            >
              <button className="btn-invoice mx-4" onClick={downloadInvoice}>
                {t("dropdown")}
              </button>

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateRangePicker']}>
                                    <DateRangePicker localeText={{ start: 'Check-in', end: 'Check-out' }} />
                                </DemoContainer>
                            </LocalizationProvider> */}
              <div>
                <span className="mx-2">Filter Date:</span>
                <input
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type="date"
                  style={{ borderRadius: "0.4rem" }}
                />
                <i class="fa fa-calendar calender"></i>
                <span className="mx-2 mt-2">{t("text2")}</span>
                <input
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                  style={{ borderRadius: "0.4rem" }}
                />
                <i class="fa fa-calendar calender"></i>
                <button
                  className="btn btn-primary mx-2"
                  onClick={() => getSellerSales()}
                >
                  {t("text2")}
                </button>
              </div>
              <form className="search mx-4" action="#">
                <input
                  type="text"
                  className="search-input"
                  placeholder={t("placeholder1")}
                  id="searchText"
                  name="searchKeyword"
                  onkeypress="handle"
                />
                <i className="fa fa-search"></i>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" className="text-center">
                      <div className="form-check my-auto">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="selectAllCheckbox"
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </div>
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading1")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading2")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading3")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading4")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading5")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading6")}
                    </th>
                    <th scope="col" className="text-center">
                      {t("tableheading7")}
                    </th>
                  </tr>
                </thead>
                {sales &&
                  sales.recent_orders &&
                  sales.recent_orders.map((order) => (
                    <tbody key={order.orders[0]}>
                      <tr>
                        <td className="text-center">
                          <div className="form-check my-auto">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={`customCheck${order.orders[0]}`}
                              checked={
                                selectedRows[order.orders[0]] !== undefined
                              }
                              onChange={() =>
                                handleCheckboxChange(order.orders[0])
                              }
                            />
                          </div>
                        </td>
                        <td className="text-center">
                          <a href="/">{order.orders[0]}</a>{" "}
                        </td>
                        <td className="text-center">{order.created_range}</td>
                        <td className="text-center">
                          S${order.total_sale_weekly}
                        </td>
                        <td className="text-center">S${order.admin_fee}</td>
                        <td className="text-center">S${order.payout_amount}</td>
                        {order.is_eligible ? (
                          <>
                            <td className="text-center">
                              <span className="badge badgeDispatch rounded-pill text-dark">
                                {order.payout_status}
                              </span>
                            </td>
                            <td className="text-center">
                              <span
                                className="badge badgeComp rounded-pill text-dark "
                                style={{ cursor: "pointer" }}
                                onClick={() => setModalOpen(true)}
                              >
                                {t("status1")}
                              </span>
                            </td>
                            <Modal
                              show={modalOpen}
                              onHide={() => setModalOpen(false)}
                              size="lg"
                              aria-labelledby="example-modal-sizes-title-lg"
                              className="ps-0"
                            >
                              <Modal.Body className="p-lg-5 p-md-5 p-3 ">
                                <div style={{ textAlign: "right" }}>
                                  <CloseButton
                                    onClick={() => setModalOpen(false)}
                                    className="btn-close"
                                  />
                                </div>
                                <div className="PayoutModel">
                                  <p>
                                    {t("heading5")}{" "}
                                    <strong>({order.created_range})</strong>
                                  </p>
                                  <div className="mode-of-payment">
                                    <label
                                      htmlFor="inputName"
                                      className="form-label"
                                    >
                                      Mode of payout:
                                    </label>
                                    <div className="mode-of-payments-btns">
                                      <nav className="nav nav-pills flex-column flex-sm-row">
                                        <a
                                          className={`flex-sm-fill text-sm-center nav-link ${
                                            activeLink === 0 ? "active" : ""
                                          }`}
                                          aria-current={
                                            activeLink === 0 ? "page" : null
                                          }
                                          href="#"
                                          onClick={() => handleLinkClick(0)}
                                          style={{
                                            marginRight: "5px",
                                            borderRadius: "8px",
                                            fontSize: "15px",
                                            padding: "9px 10px",
                                          }}
                                        >
                                          {t("btn3")}
                                        </a>
                                        <a
                                          className={`flex-sm-fill text-sm-center nav-link ${
                                            activeLink === 1 ? "active" : ""
                                          }`}
                                          aria-current={
                                            activeLink === 1 ? "page" : null
                                          }
                                          href="#"
                                          onClick={() => handleLinkClick(1)}
                                          style={{
                                            borderRadius: "8px",
                                            fontSize: "15px",
                                            padding: "9px 10px",
                                          }}
                                        >
                                          {t("btn4")}
                                        </a>
                                      </nav>
                                    </div>
                                    {activeLink === 0 ? (
                                      <>
                                        <label
                                          htmlFor="inputName"
                                          className="form-label mt-3"
                                        >
                                          {t("label1")}
                                        </label>
                                        <div className="input-group mb-3 ">
                                          <span
                                            className="input-group-text"
                                            id="basic-addon1"
                                          >
                                            +65
                                          </span>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Phone Number"
                                            aria-label="Phone Number"
                                            aria-describedby="basic-addon1"
                                            name="phone"
                                            value={phone}
                                            onChange={(e) =>
                                              setPhone(e.target.value)
                                            }
                                          />
                                        </div>
                                        <p>{t("p2")} </p>
                                        <div className="d-flex mt-3">
                                          <button
                                            className="btn btn-save"
                                            onClick={() => {
                                              const dateRange =
                                                order.created_range;
                                              const dates =
                                                dateRange.split(" to ");
                                              const startDate__ = dates[0];
                                              const endDate__ = dates[1];
                                              const payoutBody = {
                                                order_ids: order.orders,
                                                payout_method: "PAYNOW",
                                                week_start_date: startDate__,
                                                week_end_date: endDate__,
                                                name: name,
                                                phone_no: phone,
                                                total_sale:
                                                  order.total_sale_weekly,
                                                admin_fee: order.admin_fee,
                                                payout_amount:
                                                  order.payout_amount,
                                              };
                                              payoutFunc(payoutBody);
                                            }}
                                          >
                                            {t("btn5")}
                                          </button>
                                          <button className="btn btn-cancel">
                                            {t("btn6")}
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="mt-3">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label"
                                          >
                                            Bank Name:
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form_clr"
                                            id="inputName"
                                            value={bankName ? bankName : ""}
                                            name="bankName"
                                            onChange={(e) =>
                                              setBankName(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <label
                                            htmlFor="inputName"
                                            className="form-label"
                                          >
                                            {t("label2")}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control form_clr"
                                            id="inputName"
                                            value={
                                              bankAccountName
                                                ? bankAccountName
                                                : ""
                                            }
                                            name="bankAccountName"
                                            onChange={(e) =>
                                              setBankAccountName(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <label
                                            htmlFor="inputName"
                                            className="form-label"
                                          >
                                            {t("label3")}
                                          </label>
                                          <input
                                            type="number"
                                            className="form-control form_clr"
                                            id="inputName"
                                            value={
                                              bankAccountNumber
                                                ? bankAccountNumber
                                                : ""
                                            }
                                            name="bankAccountNumber"
                                            onChange={(e) =>
                                              setBankAccountNumber(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <p>{t("p2")} </p>
                                        <div className="d-flex mt-3">
                                          <button
                                            className="btn btn-save"
                                            onClick={() => {
                                              const dateRange =
                                                order.created_range;
                                              const dates =
                                                dateRange.split(" to ");
                                              const startDate__ = dates[0];
                                              const endDate__ = dates[1];
                                              const payoutBody = {
                                                order_ids: order.orders,
                                                payout_method: "BANK TRANSFER",
                                                week_start_date: startDate__,
                                                week_end_date: endDate__,
                                                name: name,
                                                total_sale:
                                                  order.total_sale_weekly,
                                                admin_fee: order.admin_fee,
                                                payout_amount:
                                                  order.payout_amount,
                                                bank_name: bankName,
                                                bank_account_name:
                                                  bankAccountName,
                                                bank_account_number:
                                                  bankAccountNumber,
                                              };
                                              payoutFunc(payoutBody);
                                            }}
                                          >
                                            {t("btn5")}
                                          </button>
                                          <button className="btn btn-cancel">
                                            {t("btn6")}
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>
                        ) : (
                          <>
                            <td className="text-center">
                              <span className="badge badgeInit rounded-pill text-dark">
                                {order.payout_status}*
                              </span>
                            </td>
                            <td className="text-center">
                              <span
                                className="badge badgeInit rounded-pill text-dark"
                                style={{ cursor: "default" }}
                              >
                                {t("status1")}
                              </span>
                            </td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>

            <Modal
              show={afterModel}
              onHide={() => setAfterModel(false)}
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              className="ps-0"
            >
              <Modal.Body className="p-lg-5 p-md-5 p-3 ">
                <div style={{ textAlign: "right" }}>
                  <CloseButton
                    onClick={() => setAfterModel(false)}
                    className="btn-close"
                  />
                </div>
                <div
                  style={{
                    fontSize: "25px",
                    margin: "auto",
                    width: "80%",
                    textAlign: "center",
                  }}
                >
                  {text}
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <p>{t("p1")}</p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SellerTotalSales;
