import React from 'react'
import Styles from './newsletter.module.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import SectionContainer from '..';

const Newsletter = () => {
  return (
    <SectionContainer className={Styles.container}>
        <div className={Styles.stayInTouchContainer}>
            <div className={Styles.stayInTouchImageContainer}>
                <img alt="stayintouchimage" src='https://images.unsplash.com/photo-1640520366057-8e48506e803d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjAwfHxzZXh5fGVufDB8fDB8fHww' />
            </div>
            <div className={Styles.stayInTouchFormContainer}>
                <div className={Styles.title}>Stay in <span>touch</span></div>
                <div className={Styles.subTitle}>Subscribe to  our newsletter and get 10% off your first order</div>
                <InputGroup className="mb-3">
                    <Form.Control className={Styles.formInput} placeholder='Enter your email'/>
                    <Button className={Styles.formButton} variant="dark" id="button-addon1" >
                        <i className="fa-solid fa-chevron-right"></i>
                    </Button>
                </InputGroup>
                <p className={Styles.description}>
                    As part of your registration, you are consenting to the receipt of regular personalised product recommendations by e-mail.
                    Information about personalisation, the use of your data and unsubscribing options can be found in the detailed newsletter consent
                    information and the data protection information.
                </p>
            </div>
        </div>
    </SectionContainer>
  )
}

export default Newsletter