import SVG from 'react-inlinesvg';
import IconLine from '../../shared/assets/icons/line.svg';
import BaseIcon from './BaseIcon';

const Line = ({ color, iconProps={height: 24, width: 24}, ...props }) => {
    return (
        <BaseIcon {...props}>
            <SVG src={IconLine} {...iconProps} style={{ color: color}}/>
        </BaseIcon>
    )
}

export default Line;
