import { useContext, useEffect, useState } from 'react'
import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import image from "../../../assets/images/png/demo-img.png"
import "./SellerProfile.css"
import Cookies from "universal-cookie";
import { GlobalContext } from '../../../store/GlobalContext'
import axios from '../../../services/api/axios'
import { AddToCart } from '../../../utils/cart/cartUtils'
import { AddToFavorite } from '../../../utils/favorite/favorite'
import { useParams } from 'react-router-dom'
import SearchAndGenderSection from '../../../components/SearchAndGenderSection/SearchAndGenderSection'
const cookies = new Cookies();
const SellerProfile = () => {
    const { state, dispatch } = useContext(GlobalContext);
    const [value, setValue] = useState('product');
    const [seller, setSeller] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [products, setProducts] = useState(null);
    const isVerified = seller?.seller_verification === "Approved" ? true : false
    const userName = useParams()
    useEffect(() => {
        const getSellerProfile = async () => {
            await axios.get(`buyer/seller-profile/details/?username=${userName.seller}&filter_type=${value}`,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }).then(response => {
                    if (response.data.reviews) {
                        setSeller(response.data.seller);
                        setReviews(response.data.reviews.reviews);
                    }
                    if (response.data.products) {
                        setSeller(response.data.seller);
                        setProducts(response.data.products.products);
                    }
                }).catch(err => {
                    console.log(err)
                });
        }
        getSellerProfile()
    }, [state.user, value])
    return (
        <>
            {/* <Header /> */}
            <SearchAndGenderSection />
            <section id='sellerProfile'>
                <div className='row px-lg-5 mx-lg-3'>
                    <div className='card p-5'>
                        {seller && isVerified && <div className="ribbon-2 text-uppercase py-3">Verified Seller</div>}
                        <div className='row mb-4'>
                            <div className='col-lg-4 p-3'>
                                <img className='img-fluid' src={seller && seller.seller_profile || ''} alt="" />
                            </div>
                            <div className='col-lg-8 p-3'>
                                <div className='px-lg-5'>
                                    <div className='d-flex align-items-center'>
                                        <h2 className='mb-0'>{seller && seller.userName || ''}</h2>
                                        <span style={isVerified ? {color: "#f47274"} : {color: "#000000"}} ><i className="fa-solid fa-circle-check fa-lg p-3"></i></span>
                                    </div>
                                    <div className='d-flex align-items-center mb-5'>
                                        <div className="stars my-2 me-3">
                                            {seller?.rating > 0 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {seller?.rating > 1 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {seller?.rating > 2 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {seller?.rating > 3 && <i className="fa-solid fa-star fa-lg"></i>}
                                            {seller?.rating > 4 && <i className="fa-solid fa-star fa-lg"></i>}
                                        </div>
                                        <p className='mb-0'>{seller && seller.rating || ''}</p>
                                    </div>
                                    <p>{seller && seller.sellerDescription || ''}</p>
                                    {/* <div className='box'> */}
                                    <div className="row mt-5 seller-description">
                                        <div className="col-sm-2 border-right outer-block">
                                            <div className="description-block">
                                                <p className="description-text mb-2">Age</p>
                                                <h5 className="description-header">{seller && seller.age || ''}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 border-right  outer-block">
                                            <div className="description-block">
                                                <p className="description-text mb-2">Gender</p>
                                                <h5 className="description-header">{seller && seller.gender}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 border-right  outer-block">
                                            <div className="description-block">
                                                <p className="description-text mb-2">Body Type</p>
                                                <h5 className="description-header">{seller && seller.bodyType}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 border-right outer-block">
                                            <div className="description-block">
                                                <p className="description-text mb-2">Location</p>
                                                <h5 className="description-header">{seller && seller.country}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 outer-block">
                                            <div className="description-block">
                                                <p className="description-text mb-2">Race</p>
                                                <h5 className="description-header">{seller && seller.race}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}

                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                <li className="nav-item me-2" role="presentation">
                                    <button className="nav-link active text-uppercase" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setValue('product')}>Products</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link text-uppercase" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={() => setValue('review')}>Reviews</button>
                                </li>
                            </ul>
                        </div>
                        <div className='card card-body'>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className='row'>
                                        {
                                            products && products.map((product) => (
                                                <div className="col-lg-3 col-md-6 d-flex align-items-stretch product-cards">
                                                    <div className="member" data-aos="fade-up" data-aos-delay="100">
                                                        <div className="member-img">
                                                            <img src={product.image} className="img-fluid image-item" alt="" />
                                                        </div>
                                                        <div className="member-info">
                                                            <h5 className='mb-0'>{product.name}</h5>
                                                            <h4>S${product.price}</h4>
                                                            <p>{product.description.substring(0, 100)}...</p>
                                                            <div className='d-flex align-items-center mb-2'>
                                                                {product?.quantity > 0 ? <AddToCart id={product?.id} /> : <button className='addToCart' disabled>OUT OF STOCK</button>}
                                                                <AddToFavorite id={product?.id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className='row mb-3'>
                                        {
                                            reviews && reviews.map((review) => (
                                                <div className='col-lg-4 p-0'>
                                                    <div className='card p-4'>
                                                        <div className='d-flex justify-content-between mb-2'>
                                                            <div className='d-flex align-items-center'>
                                                                <h5 className='mb-0'>From: <span className='p-2' style={{ color: "#f47274" }}>{review.reviewer}</span></h5>
                                                                <i className="fa-solid fa-circle-check fa-lg"></i>
                                                            </div>
                                                            <div className='date'>{review.created_at}</div>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-4'>
                                                            <div className="stars my-2 me-3">
                                                                {review?.rating > 0 && <i className="fa-solid fa-star fa-lg"></i>}
                                                                {review?.rating > 1 && <i className="fa-solid fa-star fa-lg"></i>}
                                                                {review?.rating > 2 && <i className="fa-solid fa-star fa-lg"></i>}
                                                                {review?.rating > 3 && <i className="fa-solid fa-star fa-lg"></i>}
                                                                {review?.rating > 4 && <i className="fa-solid fa-star fa-lg"></i>}
                                                            </div>
                                                        </div>
                                                        <p>{review.review}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    )
}

export default SellerProfile