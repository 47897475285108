import React from 'react'
import ContactSection from '../../../components/Sections/Contact'
import Styles from "./contactUs.module.scss"

const ContactUs = () => {
  return (
    <main className='pageBody'>
        <ContactSection />
    </main>
  )
}

export default ContactUs