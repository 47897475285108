import Header from '../../../layout/Header/Header'
import Footer from '../../../layout/Footer/Footer'
import addImage from "../../../assets/images/png/add_image.png"
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from '../../../services/api/axios';
import optionsData from '../../../utils/constants/options'
import Form from 'react-bootstrap/Form';
import Loader from '../../../components/Loaders/Loader/loader';
import MyAlert from '../../../components/Alerts/Alert/alert';
import "./AddNewProduct.css"
import { GlobalContext } from '../../../store/GlobalContext';

const cookies = new Cookies();

const AddNewProduct = () => {
    const { state } = useContext(GlobalContext);
    const [text, setText] = useState();
    const [quantity, setQuantity] = useState();
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const [productName, setProductName] = useState("");
    const [bodyType, setBodyType] = useState();
    const [category, setCategory] = useState();
    const [country, setCountry] = useState();
    const [race, setRace] = useState();
    const [gender, setGender] = useState();
    const [price, setPrice] = useState("");
    const [age, setAge] = useState();
    const [description, setDescription] = useState();
    const [ageOptions] = useState(optionsData.age);
    const [raceOptions] = useState(optionsData.race);
    const [sizeOptions] = useState(optionsData.size);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [selectedImage3, setSelectedImage3] = useState(null);
    const [selectedImage4, setSelectedImage4] = useState(null);
    const [imageList, setImageList] = useState([]);
    const [extraImage1, setExtraImage1] = useState([])
    const [extraImage2, setExtraImage2] = useState([])
    const [extraImage3, setExtraImage3] = useState([])
    const [extraImage4, setExtraImage4] = useState([])
    const [errors, setErrors] = useState({});

    const countryOptions = state?.countryList?.map((obj) => {
        return {
            label: obj.country_name,
            value: obj.country_name
        }
    });
    const categoryOptions = state?.categoryList?.map((obj) => {
        return {
            label: obj.name,
            value: obj.name
        }
    });
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImageList(file)
        if (file) {
            // You can use FileReader to read the selected image and set it in the state
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleImageChange1 = (event) => {
        const file1 = event.target.files[0];
        setExtraImage1(file1)
        if (file1) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage1(reader.result);
            };
            reader.readAsDataURL(file1);
        }
    };
    const handleImageChange2 = (event) => {
        const file2 = event.target.files[0];
        setExtraImage2(file2)
        if (file2) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage2(reader.result);
            };
            reader.readAsDataURL(file2);
        }
    };
    const handleImageChange3 = (event) => {
        const file3 = event.target.files[0];
        setExtraImage3(file3)
        if (file3) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage3(reader.result);
            };
            reader.readAsDataURL(file3);
        }
    };
    const handleImageChange4 = (event) => {
        const file4 = event.target.files[0];
        setExtraImage4(file4)
        if (file4) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage4(reader.result);
            };
            reader.readAsDataURL(file4);
        }
    };
    const validateForm = () => {
        const errors = {};

        if (!productName) {
            errors.productName = "Product Name is required";
        }
        if (!bodyType) {
            errors.bodyType = "Body Type is required";
        }
        if (!race) {
            errors.race = "Race is required";
        }
        if (!age) {
            errors.age = "Age is required";
        }
        if (!gender) {
            errors.gender = "Gender is required";
        }
        if (!country) {
            errors.country = "Product Country is required";
        }
        if (!category) {
            errors.category = "Category is required";
        }
        if (!price) {
            errors.price = "Price is required";
        }
        if (!quantity) {
            errors.quantity = "Quantity is required";
        }
        if (!description || description.length < 60) {
            errors.description = "Description should be greater than 60 words";
        }
        if (!selectedImage || !selectedImage2 || !selectedImage1 || !selectedImage3 || !selectedImage4) {
            errors.images = "At least 5 images required";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const HandleSubmit = () => {
        const isValid = validateForm();
        if (isValid) {
            setLoader(true)
            axios.post('seller/add_new_product/',
                {
                    image: imageList,
                    category: category,
                    country: country,
                    race: race?.toUpperCase() || '',
                    bodyType: bodyType,
                    // status: "PUBLISHED",
                    age: age,
                    gender: gender,
                    name: productName,
                    price: parseFloat(price).toFixed(2),
                    description: description,
                    quantity: quantity,
                    extra_image1: extraImage1,
                    extra_image2: extraImage2,
                    extra_image3: extraImage3,
                    extra_image4: extraImage4,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${cookies.get('access')}`
                    },
                    withCredentials: true
                }
            ).then(res => {
                setLoader(false)
                navigate("/products-list")
            }).catch(err => {
                setLoader(false)
                if (err.response.status === 400) {
                    console.log('ERR---', err)
                } else {
                    setText("Server Error")
                }

            })
        }
    }

    return (
        <>
            {/* <Header /> */}
            <section id="add_product">
                <div className="row justify-content-between px-5 mx-3 my-5">
                    <div className='card'>
                        <MyAlert text={text} setText={setText} />
                        <h2 className='mb-4'>ADD NEW PRODUCT</h2>
                        <div className='row'>
                            <div className="col-lg-4 mt-3 pe-5">
                                <div className='add_image mb-4'>
                                    <label htmlFor="fileInput">
                                        <img className='img-fluid w-100' src={selectedImage || addImage} alt="" style={{ cursor: "pointer" }} />
                                    </label>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                        accept="image/*"
                                    />
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-lg-3'>
                                        <label htmlFor="fileInput1">
                                            <img className='img-fluid w-100' src={selectedImage1 || addImage} alt="" style={{ cursor: "pointer" }} />
                                        </label>
                                        <input
                                            id="fileInput1"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange1}
                                            accept="image/*"
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <label htmlFor="fileInput2">
                                            <img className='img-fluid w-100' src={selectedImage2 || addImage} alt="" style={{ cursor: "pointer" }} />
                                        </label>
                                        <input
                                            id="fileInput2"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange2}
                                            accept="image/*"
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <label htmlFor="fileInput3">
                                            <img className='img-fluid w-100' src={selectedImage3 || addImage} alt="" style={{ cursor: "pointer" }} />
                                        </label>
                                        <input
                                            id="fileInput3"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange3}
                                            accept="image/*"
                                        />
                                    </div>
                                    <div className='col-lg-3'>
                                        <label htmlFor="fileInput4">
                                            <img className='img-fluid w-100' src={selectedImage4 || addImage} alt="" style={{ cursor: "pointer" }} />
                                        </label>
                                        <input
                                            id="fileInput4"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange4}
                                            accept="image/*"
                                        />
                                    </div>

                                </div>
                                <p>Please ensure that you upload a minimum of 5 product photos, and verify that each image complies with our <Link to='/terms-and-conditions' target='blank'>Terms of Service</Link></p>
                                {errors.images && <p className="error text-danger">{errors.images}!</p>}

                            </div>
                            <div className="col-lg-8 ps-5">
                                <form className="row g-3">
                                    <div className="col-12 mb-2">
                                        <label htmlFor="inputEmail4" className="form-label"><strong>Product Name</strong></label>
                                        <input
                                            type="text"
                                            className="form-control form_clr"
                                            id="inputEmail4"
                                            value={productName ? productName : ""}
                                            name={productName}
                                            onChange={(e) => setProductName(e.target.value)} />
                                        {errors.productName && <p className="error text-danger">{errors.productName}!</p>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Body Type</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select category-field mt-1"
                                            value={bodyType ? bodyType : ""}
                                            name={bodyType}
                                            onChange={(e) => setBodyType(e.target.value)}
                                        >
                                            {sizeOptions.map(
                                                (obj, index) => (
                                                    <option key={index} value={obj.value} className="dropdown-list">{obj.label}
                                                    </option>
                                                )
                                            )}

                                        </select>
                                        {errors.bodyType && <p className="error text-danger">{errors.bodyType}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Race</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select form_clr category-field mt-1"
                                            value={race ? race : ""}
                                            name={race}
                                            onChange={(e) => setRace(e.target.value)}
                                        >
                                            {raceOptions.map(
                                                (obj, index) => (
                                                    <option key={index} value={obj.value} className="dropdown-list" >{obj.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {errors.race && <p className="error text-danger">{errors.race}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Age</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select form_clr category-field mt-1"
                                            value={age ? age : ""}
                                            name={age}
                                            onChange={(e) => setAge(e.target.value)}
                                        >
                                            {ageOptions.map(
                                                (obj, index) => (
                                                    <option key={index} value={obj.value} className="dropdown-list" >{obj.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {errors.age && <p className="error text-danger">{errors.age}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Gender</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select form_clr category-field mt-1"
                                            value={gender ? gender : ""}
                                            name={gender}
                                            onChange={(e) => setGender(e.target.value)}
                                        >
                                            <option >Choose...</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                        </select>
                                        {errors.gender && <p className="error text-danger">{errors.gender}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Product Country</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select form_clr category-field mt-1"
                                            value={country ? country : ""}
                                            name={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                        >
                                            <option value="" className="dropdown-list" >Country
                                            </option>
                                            {countryOptions.map(
                                                (obj, index) => (
                                                    <option key={index} value={obj.value} className="dropdown-list" >{obj.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {errors.country && <p className="error text-danger">{errors.country}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Category</strong></label>
                                        <select
                                            id="inputState"
                                            className="form-select form_clr category-field mt-1"
                                            value={category ? category : ""}
                                            name={category}
                                            onChange={(e) => setCategory(e.target.value)}

                                        >
                                            <option value="" className="dropdown-list" >Category
                                            </option>
                                            {categoryOptions.map(
                                                (obj, index) => (
                                                    <option key={index} value={obj.value} className="dropdown-list" >{obj.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {errors.category && <p className="error text-danger">{errors.category}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputEmail4" className="form-label"><strong>Price (SGD)</strong></label>
                                        <input
                                            type="text"
                                            className="form-control form_clr"
                                            id="inputBox"
                                            value={price ? price : ""}
                                            name={price}
                                            onChange={(e) => setPrice(e.target.value.replace(/[^0-9]/g, ""))} />
                                        {errors.price && <p className="error text-danger">{errors.price}!</p>}

                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="inputState" className="form-label"><strong>Quantity</strong></label>
                                        <input
                                            type="text"
                                            id="inputState"
                                            className="form-control form_clr category-field"
                                            value={quantity ? quantity : ""}
                                            name={quantity}
                                            onChange={(e) => setQuantity(e.target.value.replace(/[^0-9]/g, ""))}
                                        />
                                        {errors.quantity && <p className="error text-danger">{errors.quantity}!</p>}

                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label"><strong>Description</strong></label>
                                        <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1"
                                            id="message"
                                            required>

                                            <Form.Control onChange={(e) => setDescription(e.target.value)} value={description ? description : ""} as="textarea" rows={3} placeholder="Write product description here..." />
                                        </Form.Group>
                                        {errors.description && <p className="error text-danger">{errors.description}!</p>}

                                    </div>
                                    <div className="form-footer mb-3">
                                        <button type="button" className="btn btn-light text-uppercase me-3" onClick={() => navigate(-1)}>Cancel</button>
                                        <button type="button" className="btn btn-submit text-uppercase" onClick={HandleSubmit}>{loader ? <Loader className="mx-5" /> : 'Ask for Approval'}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Footer /> */}
        </>
    )
}

export default AddNewProduct